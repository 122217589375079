import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Table, Row, Col } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { GROUP_TYPES } from "../../constant";

const StartProgramModal = ({ successMessage, handleClose, assignProgramErrorMsg , assignProgram ,appContext ,msg}) => {
  console.log("program Modal", successMessage,assignProgramErrorMsg )
  const [users, setUsers] = useState([]);
  // useEffect(() => {
  //   if (employees.length > 0) {
  //     const data = employees.reduce((obj, employee) => {
  //       obj[employee.groupType] = (obj[employee.groupType] || 0) + 1;
  //       return obj;
  //     }, {});
  //     setUsers(data);
  //   }
  // }, []);

  const TableRow = ({ user, error }) => {
    return (
      <tr>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.phoneNumber}</td>
        <td className= "text-danger">{assignProgram ? "Not Assigned Program"  : "Not Started Program" }</td>
        <td  className="ellipsis" title={msg}>{msg}</td>
      </tr>
    );
  };
  if(assignProgramErrorMsg && assignProgramErrorMsg.length > 0){
  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {assignProgram ? "Assign Program": "Start Program"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0">
        <div style={{ flex: 1 }}>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Row>
                    <Card.Title
                      as="h4"
                      style={{
                        marginBottom: 10,
                        fontWeight: "bold",
                        color: "#565656",
                      }}
                    >
                      <div>{successMessage}</div>
                   </Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive" style={{ overflow: "hidden" }}>
                      <Table className="table">
                        <thead>
                          <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone Number</th>
                            <th>Status</th>
                            <th>Reason</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assignProgramErrorMsg.map((user, index) => <TableRow user={user} key={index}/>)}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button  variant="secondary"
            className="modalButtons headerButton btn-fill" onClick={() => handleClose()}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}else{
return(
  <SweetAlert
              show={true}
              success
              title="Success"
              onConfirm={()=> handleClose()}
            >
           {successMessage}
        </SweetAlert>
) 
}
};

export default StartProgramModal;
