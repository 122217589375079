import React, { useRef } from "react";
import Select from "react-select";
import {MESSAGE_SEND_TYPE} from "constant";

const TEXT_FILED_OPTIONS = [
  { label: "First Name", value: "{firstName}" },
  { label: "Last Name", value: "{lastName}" },
  { label: "Test Type", value: "{testType}" },
];

const TextAreaMessageInput = (props) => {
  const {  message, name, handleChange, label } = props;
  const textAreaRef = useRef();

  const insertAtCursor = (dropDownValue) => {
    textAreaRef.current.focus;
    if (textAreaRef.current.selectionStart || textAreaRef.current.selectionStart === "0") {
      let startPos = textAreaRef.current.selectionStart;
      let endPos = textAreaRef.current.selectionEnd;
      textAreaRef.current.value =
        textAreaRef.current.value.substring(0, startPos) +
        dropDownValue +
        textAreaRef.current.value.substring(endPos, textAreaRef.current.value.length);
        const newPos = endPos + dropDownValue.length;
        textAreaRef.current.setSelectionRange(newPos,newPos);
      textAreaRef.current.focus();
      handleChange( textAreaRef.current.value, name);
    } else {
      handleChange( textAreaRef.current.value, name);
    }
  };
  return (
    <div className="row textAreaMessageInput">
      <div className="col-12 mb-3">
        <div className="d-flex align-items-center">
        <Select
          value={{ label: "Insert field", value: "none" }}
          options={TEXT_FILED_OPTIONS}
          blurInputOnSelect={true}
          menuPlacement="auto"
          className="w-30 ml-auto inputFieldMessage"
          placeholder="Insert Field"
          onChange={(e) => insertAtCursor(e.value)}
        />
        </div>
        <textarea
          placeholder={label}
          className="MessageInput"
          ref={textAreaRef}
          value={message}
          onChange={(e) => handleChange( e.target.value, name)}
        />
      </div>
    </div>
  );
};
export default TextAreaMessageInput;
