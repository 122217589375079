import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import {
  Employee,
  Question,
  Schedule,
  AppSetting,
  TestTypes,
  Department,
  Locations,
  Regions,
  Programs,
  EmployeeQuestion,
  EmployeeDailyLog,
} from "./models";
import { Auth, API, Storage, graphqlOperation } from "aws-amplify";
import * as queries from "./graphql/queries";
import { toTitleCase, capitalizeLetter } from "utils";
import { TEST_STATUS, SCHEDULE_CATEGORY, DAILY_QA_STATUS, TEST_TYPE_VALUE } from "constant";
import axios from "axios";
import AWS from "aws-sdk";
import awsconfig from "./aws-exports";
import { jsonToCSV } from "react-papaparse";
import moment from "moment";
import { userCompanyID } from "utils";
import FileSaver from "file-saver";
import { v4 as uuidv4 } from "uuid";
import { AWS_ENDPOINT } from "constants/endpoints";
import { STATUS } from "constant";

const MAX_PAGE_LIMIT = 1000;
const timezones = {
  "Eastern Standard Time": -240,
  "Eastern Daylight Time": -300,
  "Central Standard Time": -300,
  "Central Daylight Time": -360,
  "Mountain Standard Time": -360,
  "Mountain Daylight Time": -420,
  "Pacific Standard Time": -420,
  "Pacific Daylight Time": -480,
  "Pakistan Standard Time": +300,
};
const getTestGraphQL = async () => {
  console.log("getTestGraphQL ");
  try {
    const graphqlData = await API.graphql(
      graphqlOperation(queries.getEmployee, { id: "57b4f60a-acec-4150-8224-49eb3d2e5c8e" })
    );
    console.log("getTestGraphQL ", graphqlData);
  } catch (err) {
    console.log("Error ", err);
  }
};

const getCurrentUser = async () => {
  try {
    const response = await Auth.currentAuthenticatedUser({ bypassCache: true });

    console.log("getCurrentUser ", response);
    const compnayID = userCompanyID.get();
    // userCompanyID.save(compnayID);
    return {
      ...response.attributes,
      name: `${response.attributes["custom:firstName"]} ${response.attributes["custom:lastName"]}`,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isLab: function () {
        return this.roles.some((role) => role === "Labs");
      },
      isUser: function () {
        return this.roles.length === 0;
      },
      isSite: function () {
        return this.roles.some((role) => role === "Employers");
      },
      companyID: compnayID,
    };
  } catch (err) {
    console.log(err);
    localStorage.clear();
    return null;
  }
};

const getAllUsers = async () => {
  const models = await DataStore.query(Employee);
  return models;
};

const signIn = async (username, password) => {
  try {
    const response = await Auth.signIn(username, password);
    // console.log(response);
    return {
      ...response.attributes,
      roles: response.signInUserSession.accessToken.payload["cognito:groups"] || [],
      isAdmin: function () {
        return this.roles.some((role) => role === "Admins");
      },
      isUser: function () {
        return this.roles.length === 0;
      },
    };
  } catch (err) {
    return null;
  }
};

const createEmployee = async (demographics = {}, clientID, phoneNumber) => {
  const models = await DataStore.query(Employee, (emp) =>
    emp
      .clientID("eq", clientID)
      .first("eq", demographics.firstName)
      .last("eq", demographics.lastName)
      .dob("eq", buildAWSDate(demographics.dob))
  );
  if (models.length > 0) {
    return models[0];
  }
  //Must be new. Create it
  const employee = await DataStore.save(
    new Employee({
      first: demographics.firstName,
      last: demographics.lastName,
      dob: buildAWSDate(demographics.dob),
      id_number: demographics.idNumber,
      clientID: clientID,
      phone_number: phoneNumber,
      street: demographics.street,
      street2: demographics.street2,
      city: demographics.city,
      state: demographics.state,
      zip: demographics.zip,
      companyID: userCompanyID.get(),
    })
  );
  return employee;
};

const apiName = "mfaAdminApi";

const fetchUsersInGroup = async (groupName) => {
  let path = "/listUsersInGroup";
  let myInit = {
    queryStringParameters: {
      groupname: groupName,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const models = await API.get(apiName, path, myInit);
    console.log(`Fetching ${groupName}`, models.Users);
    return models.Users;
  } catch (e) {
    console.log("Error getting users:", e);
  }
};
const fetchAllUsers = async (filter, NextToken) => {
  console.log("filter", filter);
  let path = "/listUsers";
  let params = {
    queryStringParameters: {
      limit: 60,
      filter: filter,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, params);
  console.log("allTest", models);
  return models;
};

const changeUserRole = async (prevRole, newRole, username) => {
  console.log(prevRole, newRole, username);
  let path = "/removeUserFromGroup";
  let prevRoleAlias;
  if (prevRole === "Crew") {
    prevRoleAlias = "Employee";
  } else if (prevRole === "Health & Safety") {
    prevRoleAlias = "Employer";
  } else {
    prevRoleAlias = prevRole;
  }
  let params = {
    body: {
      username: username,
      groupname: prevRoleAlias + "s",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const roleRemoval = await API.post(apiName, path, params);
    path = "/addUserToGroup";
    params.body["groupname"] = newRole + "s";
    const roleChange = await API.post(apiName, path, params);
    // console.log("Changing role", roleRemoval, roleChange);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to change user role");
  }
};

const confirmUserSignUp = async (username) => {
  console.log(username);
  const path = "/confirmUserSignUp";
  try {
    const params = {
      body: {
        username: username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    const done = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Fail to confirm user");
  }
};
const updateUserInfo = async (user, clientName) => {
  console.log("[update_info]", user);
  const path = "/updateUserInfo";

  const params = {
    body: {
      username: user.username,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      note: clientName,
      clientID: user.clientID,
      mainContact: user.mainContact,
      clients: user.clients,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[update_info]", infoUpdated);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user info");
  }
};

const addUserClients = async (username, clients) => {
  console.log("[addUserClients]", username);
  const path = "/addUserClients";

  const params = {
    body: {
      username: username,
      clients: clients,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[addUserClients]", infoUpdated);
  } catch (e) {
    console.log("Error changing addUserClients:", e);
  }
};

const udpatePersonalization = async (user, data) => {
  console.log(user, JSON.stringify(data));
  const path = "/updatePersonalisationInfo";
  let note = "";

  const params = {
    body: {
      data: JSON.stringify(data),
      username: user, // user.phone_number,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const updateAssociatedInfo = async (username, note) => {
  console.log("[update_info]", username, note);
  const path = "/updateAssociatedInfo";

  const params = {
    body: {
      username: username,
      note: note,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
  }
};

const updateUserPassword = async (username, password) => {
  console.log("[update_user_password_info]", username, password);
  const path = "/updateUserPassword";
  const params = {
    body: {
      username: username,
      password: password,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[update_user_password_info]", infoUpdated);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user password");
  }
};

const deleteUserLinkFromEmployee = async (val) => {
  const users = await DataStore.query(Employee, (e) => e.subID("eq", val));
  users.forEach(async (user) => {
    await DataStore.save(
      Employee.copyOf(user, (updated) => {
        updated.subID = null;
      })
    );
  });
};

const deleteUser = async (username) => {
  console.log("[delete_user_password_info]", username);
  const path = "/deleteUser";
  const params = {
    body: {
      username: username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    await API.post(apiName, path, params);
  } catch (e) {
    console.log("Error changing user role:", e);
    alert("Unable to update user password");
  }
};

const sendSMSNotification = async (phoneNumber, message) => {
  try {
    const notification = await axios.post(AWS_ENDPOINT + "/notification", {
      phone_number: phoneNumber,
      message: message,
    });
    return notification;
  } catch (error) {
    console.log(error);
  }
};

const parseMessage = (msg, emp, companyData, testType) => {
  let message = msg;
  message = message.replace(/{firstName}/g, toTitleCase(emp.firstName));
  message = message.replace(/{lastName}/g, toTitleCase(emp.lastName));
  message = message.replace(/{testType}/g, testType);
  return message;
};

const parseMessageCallTime = (msg, firstName, lastName, testType, callTime) => {
  let message = msg;
  message = message.replace(/{firstName}/g, toTitleCase(firstName));
  message = message.replace(/{lastName}/g, toTitleCase(lastName));
  message = message.replace(/{testType}/g, testType);
  message = message.replace(/{callTime}/g, callTime);
  return message;
};

export const formatDateMDY = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");
const buildAWSDate = (dobFromID) => {
  const awsdob = [];
  awsdob.push(dobFromID.substring(4));
  awsdob.push(dobFromID.substring(0, 2));
  awsdob.push(dobFromID.substring(2, 4));
  return awsdob.join("-");
};

const sendEmail = async (data) => {
  console.log("[sendEmail]", data);
  const path = "/sendInvitationEmail";
  // const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[SEND_EMAIL]", infoUpdated);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};

const sendEmailToCrew = async (data) => {
  console.log("[sendEmailToCrew]", data);
  // const path = "/sendEmailToCrew";
  const path = "/sendEmailAttachement";

  const params = {
    body: {
      data: data,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[SEND_EMAIL]", infoUpdated);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};

const fetchAllEmployees = async () => {
  const models = await DataStore.query(Employee);
  return models.length;
};

const getEmployees = async () => {
  const models = await DataStore.query(Employee, (e) => e.companyID("eq", userCompanyID.get()));
  return models;
};

const saveEmployeeLogs = async () => {
  const models = await DataStore.query(Employee, (e) => e.companyID("eq", userCompanyID.get()));
  let logs = [];
  for (let i = 0; i < models.length; i++) {
    const l = await DataStore.query(EmployeeQuestion, (e) => e.employeeID("eq", models[i].id), {
      sort: (emp) => emp.createdAt(SortDirection.DESCENDING),
    });
    const emp = models[i];
    for (let j = 0; j < l.length; j++) {
      const ans = l[j];
      logs.push({
        firstName: j == 0 ? emp.firstName : "",
        lastName: j == 0 ? emp.lastName : "",
        email: j == 0 ? emp.email : "",
        phoneNumber: j == 0 ? emp.phoneNumber : "",
        qus: ans.question,
        ans: ans.answer,
        date: moment(ans.createdAt).format("MM-DD-YYYY"),
      });
    }
  }
  try {
    const content = jsonToCSV(logs);
    const fileContent = new Blob([content], { type: "csv" });
    await FileSaver.saveAs(fileContent, "EmployeeLog.csv");
  } catch (err) {
    console.log("Error", err);
  }
  console.log("Employee Numbers ", logs);

  return models;
};

const updateCompnayEmployeeNo = async (number) => {
  const setting = await getCompanySetting();
  console.log("setting", setting);
  await DataStore.save(
    AppSetting.copyOf(setting, (updated) => {
      updated.employeeCounter = number;
    })
  );
};

const newEmployee = async (emp, num) => {
  console.log("num", num);
  let seqNumber = num;
  let isUpdateCounter = false;
  const setting = await getCompanySetting();
  console.log("EmpSettings", setting);
  if (!seqNumber) {
    seqNumber = setting.employeeCounter;
    isUpdateCounter = true;
    seqNumber++;
  }
  const employeeID = `SCHR-${setting.companyCode}-${seqNumber.toString().padStart(6, 0)}`;
  const isNewSignUp = await DataStore.query(Employee, (e) => e.phoneNumber("eq", emp.phoneNumber).isNew("eq", true));

  if (isNewSignUp.length > 0) {
    const notAssociatedWithCompany = isNewSignUp.filter((f) => !f.companyID);
    console.log("isNewSignUp", notAssociatedWithCompany);
    if (notAssociatedWithCompany.length > 0) {
      const dbEmp = notAssociatedWithCompany[0];
      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.schrID = employeeID;
          updated.jobTitle = emp.jobTitle;
          updated.region = emp.region;
          updated.location = emp.location;
          updated.department = emp.department;
          updated.empTZ = emp.empTZ;
          updated.unionNo = emp.unionNo;
          updated.localNo = emp.localNo;
          updated.isNew = false;
          (updated.companyID = userCompanyID.get()),
            (updated.programName = emp.programName),
            (updated.callTime = emp.callTime || null);
        })
      );
      if (isUpdateCounter) {
        await updateCompnayEmployeeNo(seqNumber);
      }
      return dbEmp.id;
    }
  }
  const employeeObject = {
    firstName: emp.firstName,
    lastName: emp.lastName,
    schrID: employeeID,
    email: emp.email,
    idNumber: emp.idNumber || `M${moment().valueOf().toString()}`,
    phoneNumber: emp.phoneNumber,
    jobTitle: emp.jobTitle,
    countryCode: emp.countryCode,
    region: emp.region,
    location: emp.location,
    street: toTitleCase(emp.street),
    sex: emp.gender || emp.sex,
    isSchedule: 0,
    isVaccinated: emp.isVaccinated || false,
    department: emp.department,
    country: toTitleCase(emp.country),
    state: capitalizeLetter(emp.state),
    city: toTitleCase(emp.city),
    callTime: emp.callTime || null,
    localNo: emp.localNo,
    unionNo: emp.unionNo,
    zip: emp.zip,
    isNew: false,
    empTZ: emp.empTZ,
    companyID: userCompanyID.get(),
    programName: emp.programName,
  };

  if (emp.dob) {
    Object.assign(employeeObject, { dob: emp.dob });
  }

  const res = await DataStore.save(new Employee(employeeObject));
  if (isUpdateCounter) {
    console.log("seqNumber", seqNumber);
    await updateCompnayEmployeeNo(seqNumber);
  }
  return res.id;
};

const updateEmployee = async (emp) => {
  console.log("apiemp", emp);
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
  const dbEmp = models[0];
  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.firstName = emp.firstName;
      updated.lastName = emp.lastName;
      updated.dob = emp.dob;
      updated.schrID = emp.schrID;
      updated.email = emp.email;
      updated.phoneNumber = emp.phoneNumber;
      updated.jobTitle = emp.jobTitle;
      updated.countryCode = emp.countryCode;
      updated.street = toTitleCase(emp.street);
      updated.region = emp.region;
      updated.location = emp.location;
      updated.sex = emp.gender;
      updated.isVaccinated = emp.isVaccinated;
      updated.department = emp.department;
      updated.idNumber = emp.idNumber || `M${moment().valueOf().toString()}`;
      updated.country = toTitleCase(emp.country);
      updated.city = toTitleCase(emp.city);
      updated.zip = emp.zip;
      updated.empTZ = emp.empTZ;
      updated.unionNo = emp.unionNo;
      updated.localNo = emp.localNo;
      updated.callTime = emp.callTime || null;
      updated.state = capitalizeLetter(emp.state);
      updated.picture = emp.profileImage instanceof Object ? `${emp.id}.jpeg` : emp.picture;
    })
  );
  try {
    if (emp.profileImage instanceof Object) {
      console.log("Before", emp.profileImage);
      await Storage.put(`profile/${emp.id}.jpeg`, emp.profileImage, { bucket: "medflow-images" });
      console.log("aFter", emp.profileImage);
    }
  } catch (err) {
    console.log("Error", err.message);
  }
};
const fetchAllCrewMembers = async (groupName, NextToken) => {
  const path = "/listUsersInGroup";
  let users = [];
  const myInit = {
    queryStringParameters: {
      groupname: groupName,
      limit: 60,
      token: NextToken,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  const models = await API.get(apiName, path, myInit);
  return models;
};

const isEmployeeScheduled = (schedule, deptTimeZone) => {
  try {
    let isNotify = false;
    const currentDate = moment().utc().add(timezones[deptTimeZone], "minutes");
    const startDate = moment.utc(schedule.startDate, "YYYY-MM-DD").startOf("day");
    const endDate = moment.utc(schedule.endDate, "YYYY-MM-DD").endOf("day");
    const currentDay = currentDate.day();

    console.log(
      "Start Date ",
      schedule,
      currentDate.toISOString(),
      startDate.toISOString(),
      endDate.toISOString(),
      currentDay,
      currentDate.isSameOrAfter(startDate)
    );

    if (schedule.scheduleType === "untilcancel") {
      isNotify = schedule.days.includes(currentDay) && currentDate.isSameOrAfter(startDate);
    } else if (schedule.scheduleType === "everyday") {
      isNotify = currentDate.isBetween(startDate, endDate);
    } else if (schedule.scheduleType === "selecteddays") {
      isNotify = schedule.days.includes(currentDay) && currentDate.isBetween(startDate, endDate);
    }

    return isNotify;
  } catch (e) {
    console.log("Error while validating schedule");
    console.log(e);
  }
};

const getScheduleTask = (departments, schedules, dbEmp, scheduleLinked) => {
  let logDate = null;
  let dailyTask = [];
  let testOne = [];
  let testTwo = [];
  const dept = departments.find((d) => d.id === dbEmp.department);

  if (scheduleLinked && scheduleLinked.length > 0) {
    for (let i = 0; i < scheduleLinked.length; i++) {
      const schID = scheduleLinked[i];
      const empSchedule = schedules.find((s) => s.id === schID);
      if (empSchedule && isEmployeeScheduled(empSchedule, dept.deptTimeZone)) {
        // set user timezone
        const userDateTime = moment().utc().add(timezones[dept.deptTimeZone], "minutes");
        logDate = `${userDateTime.format("YYYY-MM-DD")}T00:00:00Z`;
        const day = userDateTime.day();
        const daySchedule = empSchedule.dayOptions.filter((d) => d.id === day);

        if (daySchedule.length > 0) {
          const scheduleRecord = daySchedule[0];
          if (empSchedule.scheduleCategory === SCHEDULE_CATEGORY.TESTING) {
            testOne = scheduleRecord.testType
              .filter((d) => d.location.toLowerCase() === "at home")
              .map((d) => {
                return { ...d, date: userDateTime.format("YYYY-MM-DD"), id: uuidv4(), lucira: d.value === "Lucira" };
              });
            testTwo = scheduleRecord.testType
              .filter((d) => d.location.toLowerCase() === "on location")
              .map((d) => {
                return { ...d, date: userDateTime.format("YYYY-MM-DD"), id: uuidv4(), lucira: d.value === "Lucira" };
              });
          } else {
            dailyTask.push({
              scheduleRecord,
              isDone: false,
              scheduleCategory: "Question",
            });
          }
        }
      }
    }
  }

  return [logDate, dailyTask, testOne, testTwo];
};

const updateEmployeeLogDate = async (params, schedules, departments) => {
  let logDate = null;
  let testOne = null;
  let testTwo = null;
  let dailyTask = [];
  const models = await DataStore.query(Employee, (e) => e.id("eq", params.id));
  if (models.length === 0) return;
  const dbEmp = models[0];
  if (params.isSchedule === 1) {
    [logDate, dailyTask, testOne, testTwo] = getScheduleTask(departments, schedules, dbEmp, params.scheduleLinked);
  }
  console.log("dbEmp", dbEmp, params);
  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.questionID = params.groupname ? params.groupname.value : null;
      updated.groupType = params.assigngrouptype.value;
      updated.groupName = params.assigngrouptype.label;
      updated.scheduleID = params.scheduleID;
      updated.programName = params.programName;
      updated.isSchedule = params.isSchedule;
      updated.scheduleLinked = params.scheduleLinked;
      updated.logDate = logDate;
      updated.testOne = testOne;
      updated.testTwo = testTwo;
      updated.dailyTask = dailyTask.length === 0 ? null : dailyTask;
    })
  );
};

const updateEmployeeProgramState = async (ids, programStatus, params, userName) => {
  if (ids.length === 0) return;
  const [schedules, departments] = await Promise.all([getSchedules(), getDepartments()]);
  console.log("employees", ids);
  const ttl = ids.length;
  const setting = await getCompanySetting();
  const smsData = [];
  const logData = [];
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", ids[i].id));
    if (models.length === 0) continue;
    const dbEmp = models[0];
    let logDate = null;
    let dailyTask = [];
    let testOne = null;
    let testTwo = null;
    if (programStatus === 1 && schedules) {
      [logDate, dailyTask, testOne, testTwo] = getScheduleTask(
        departments,
        schedules,
        dbEmp,
        params ? params.scheduleLinked : dbEmp.scheduleLinked
      );
    }
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        if (params) {
          updated.questionID = params.groupname ? params.groupname.value : null;
          updated.groupType = params.assigngrouptype.value;
          updated.groupName = params.assigngrouptype.label;
          updated.scheduleID = params.scheduleID;
          updated.programName = params.programName;
          updated.programID = params.programID;
          updated.scheduleLinked = params.scheduleLinked;
        }
        updated.isSchedule = programStatus;
        updated.logDate = logDate;
        updated.dailyTask = dailyTask.length === 0 ? null : dailyTask;
        updated.isScreeningDone = false;
        updated.qaDone = false;
        updated.testDone = false;
        updated.onBoardingTesting = null;
        updated.testOne = testOne;
        updated.testTwo = testTwo;
        updated.checkIn = null;
        updated.manualCheckIn = false;
        updated.manualCheckInReason = "";
        updated.manualCheckAdmin = "";
      })
    );
    const phone = dbEmp.phoneNumber;

    const email = dbEmp.email;

    if (programStatus === 1 && phone) {
      let message = "";
      if (setting && setting.isStartProgramVerbiage && setting.startProgramVerbiage) {
        let tests = [];
        if (testOne && testOne.length > 0) {
          tests = tests.concat(testOne.filter((t) => !t.isDone));
        }
        if (testTwo && testTwo.length > 0) {
          tests = tests.concat(testTwo.filter((t) => !t.isDone));
        }
        let reminderTest = "";
        reminderTest = tests.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
        message = parseMessage(setting.startProgramVerbiage, dbEmp, "", reminderTest);
        smsData.push({ phoneNumber: phone, email, message, subject: "Program Started" });
      }
    }
    if (userName) {
      logData.push({
        id: dbEmp.id,
        slug: "StartProgram",
        userName: userName,
        companyID: dbEmp.companyID,
        programName: params ? params.programName : dbEmp.programName,
        phone_number: dbEmp.phoneNumber,
      });
    }
  }

  await addAuditLogs(logData);
  console.log("smsData", smsData);
  const type = setting.startProgramType === "M" ? "sms" : "email";
  console.log("type", type);
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_calltime_${type}.csv`, jsonToCSV(smsData), {
    bucket: "hr-update-records",
  });
};

const endDuplicateProgram = async (emp) => {
  const ttl = emp.length;
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp[i].id));
    if (models.length === 0) continue;

    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.isSchedule = 2;
        updated.logDate = null;
        updated.dailyTask = [];
        updated.testOne = null;
        updated.testTwo = null;
        updated.onBoardingTesting = null;
        updated.manualCheckIn = false;
        updated.manualCheckInReason = "";
        updated.manualCheckAdmin = "";
        updated.qaDone = false;
        updated.testDone = false;
      })
    );
  }
};

const endProgram = async (paramIds) => {
  if (paramIds.length === 0) return;
  console.log("employees", paramIds);
  const testNowResult = paramIds.filter((f) => f.testNow);
  let ids = paramIds;
  const duplicateVal = [];
  if (testNowResult.length > 0) {
    testNowResult.forEach((f) => {
      const findVal = paramIds.find((i) => i.id === f.id.replace("%testNow", ""));
      if (findVal) {
        ids = ids.filter((f) => f.id.replace("%testNow", "") !== findVal.id);
        duplicateVal.push(findVal);
      }
    });
  }
  if (duplicateVal.length > 0) {
    endDuplicateProgram(duplicateVal);
  }
  console.log("ids", ids, duplicateVal);
  const ttl = ids.length;
  const setting = await getCompanySetting();
  for (let i = 0; i < ttl; i++) {
    const empData = ids[i];
    const isTestNowEnd = ids[i].id.includes("testNow");
    const idNo = ids[i].id.replace("%testNow", "");
    const models = await DataStore.query(Employee, (e) => e.id("eq", idNo));

    if (models.length === 0) continue;

    const dbEmp = models[0];
    const isQADone = DAILY_QA_STATUS[empData.qaDone];
    const isTestDone = DAILY_QA_STATUS[empData.testDone];
    const logDate = empData.logDate;

    if (empData.logDate?.substring(0, 10) === moment().format("YYYY-MM-DD") && (isQADone || isTestDone)) {
      console.log("Save Employee Logs");
      let logObject = { employeeID: dbEmp.id, logDate: empData.logDate };
      if (isTestNowEnd) {
        Object.assign(logObject, {
          qaDone: null,
          testDone: null,
          checkIn: empData.checkIn === "1" ? logDate : null,
          dailyTask: null,
          testOne: null,
          testTwo: null,
          onBoardingTesting: empData.onBoardingTesting,
        });
      } else {
        Object.assign(logObject, {
          qaDone: isQADone,
          testDone: isTestDone,
          checkIn: empData.checkIn === "1" ? logDate : null,
          dailyTask: empData.dailyTask,
          testOne: empData.testOne,
          testTwo: empData.testTwo,
          onBoardingTesting: empData.onBoardingTesting,
        });
      }
      if (empData.manualCheckIn) {
        Object.assign(logObject, {
          manualCheckIn: empData.manualCheckIn,
          manualCheckInReason: empData.manualCheckInReason,
          manualCheckAdmin: empData.manualCheckAdmin,
        });
      }
      await DataStore.save(new EmployeeDailyLog(logObject));
    }
    let testOne = dbEmp.testOne || [];
    let testTwo = dbEmp.testTwo || [];

    if (isTestNowEnd) {
      if (dbEmp.isSchedule === 1) {
        testOne = testOne.filter((t) => !t.testNow);
        testTwo = testTwo.filter((t) => !t.testNow);
      } else {
        testOne = [];
        testTwo = [];
      }
      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.onBoardingTesting = null;
          updated.testOne = testOne;
          updated.testTwo = testTwo;
        })
      );
    } else {
      let logDate = dbEmp.logDate;
      if (dbEmp.onBoardingTesting) {
        testOne = testOne.filter((t) => t.testNow);
        testTwo = testTwo.filter((t) => t.testNow);
      } else {
        logDate = null;
        testOne = [];
        testTwo = [];
      }
      await DataStore.save(
        Employee.copyOf(dbEmp, (updated) => {
          updated.isSchedule = 2;
          updated.logDate = logDate || null;
          updated.dailyTask = [];
          updated.testOne = testOne;
          updated.testTwo = testTwo;
          updated.manualCheckIn = false;
          updated.manualCheckInReason = "";
          updated.manualCheckAdmin = "";
          updated.qaDone = false;
          updated.testDone = false;
        })
      );
    }
  }
};

const updateEmployeeGroup = async (emps, params) => {
  console.log("assign program", emps, params);
  for (let i = 0; i < emps.length; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", emps[i]));
    if (models.length === 0) continue;
    const dbEmp = models[0];

    const d = await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.questionID = params.groupname ? params.groupname.value : null;
        updated.groupType = params.assigngrouptype.value;
        updated.groupName = params.assigngrouptype.label;
        updated.scheduleID = params.scheduleID;
        updated.programName = params.programName;
        updated.programID = params.programID;
        updated.isSchedule = 2;
        updated.scheduleLinked = params.scheduleLinked;
        updated.logDate = null;
        updated.testOne = null;
        updated.testTwo = null;
        updated.dailyTask = null;
      })
    );
    console.log("Employee to Update", d);
  }
};
const updateEmployeeGroupState = async (emps, params, departments, schedules, notification) => {
  console.log("assign program", emps, params);
  const setting = await getCompanySetting();
  for (let i = 0; i < emps.length; i++) {
    let logDate = null;
    let testOne = null;
    let testTwo = null;
    let dailyTask = [];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emps[i]));
    if (models.length === 0) return;
    const dbEmp = models[0];
    [logDate, dailyTask, testOne, testTwo] = getScheduleTask(departments, schedules, dbEmp, params.scheduleLinked);
    console.log("dbEmp", dbEmp, params);
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.questionID = params.groupname ? params.groupname.value : null;
        updated.groupType = params.assigngrouptype.value;
        updated.groupName = params.assigngrouptype.label;
        updated.scheduleID = params.scheduleID;
        updated.programName = params.programName;
        updated.isSchedule = 1;
        updated.scheduleLinked = params.scheduleLinked;
        updated.logDate = logDate;
        updated.testOne = testOne;
        updated.testTwo = testTwo;
        updated.dailyTask = dailyTask.length === 0 ? null : dailyTask;
      })
    );
    if (notification) {
      console.log("notification notification notification");
      try {
        const phone = dbEmp.phoneNumber;
        const email = dbEmp.email;
        let message = "";
        if (setting && setting.isStartProgramVerbiage && setting.startProgramVerbiage) {
          let tests = [];
          if (testOne && testOne.length > 0) {
            tests = tests.concat(testOne.filter((t) => !t.isDone));
          }
          if (testTwo && testTwo.length > 0) {
            tests = tests.concat(testTwo.filter((t) => !t.isDone));
          }
          let reminderTest = "";
          reminderTest = tests.map((t) => t.value).join(",");
          if (reminderTest) {
            reminderTest = reminderTest.replace(/Other/g, "Rapid PCR");
          }
          message = parseMessage(setting.startProgramVerbiage, dbEmp, "", reminderTest);
          if (setting.startProgramType === "M") {
            await sendSMSNotification(phone, message);
          } else {
            await sendEmail([{ email: email, subject: "SafeCamp Notifcation", msg: message }]);
          }
        }
      } catch (err) {
        console.log("Error Message", err);
      }
    }
  }
};

const removeEmployeeGroup = async (emps, isZoneLevel, userName) => {
  const logData = [];
  for (let i = 0; i < emps.length; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", emps[i]));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.questionID = null;
        updated.groupType = null;
        updated.groupName = null;
        updated.scheduleID = null;
        updated.programName = null;
        updated.programID = null;
        updated.isSchedule = STATUS.removeProgram;
        updated.scheduleLinked = null;
        updated.logDate = null;
        updated.dailyTask = [];
        updated.testOne = null;
        updated.testTwo = null;
        updated.checkIn = null;
        updated.manualCheckIn = false;
        updated.manualCheckInReason = "";
        updated.manualCheckAdmin = "";
        updated.qaDone = false;
        updated.testDone = false;
        updated.onBoardingTesting = null;
      })
    );
    if (isZoneLevel) {
      logData.push({
        id: dbEmp.id,
        slug: "RemoveProgram",
        userName: userName,
        companyID: dbEmp.companyID,
        programName: dbEmp.programName,
        phone_number: dbEmp.phoneNumber,
      });
    }
  }
  await addAuditLogs(logData);
};
const updateEmployeeTest = async (empIds, arrData) => {
  console.log("updateEmployeeTest", arrData);
  const arr = arrData.map((data) => {
    const obj = { ...data };
    Object.assign(obj, { id: uuidv4(), lucira: false });
    if (obj.value === "Lucira") {
      Object.assign(obj, { lucira: true });
    }
    return obj;
  });

  for (let i = 0; i < empIds.length; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i]));

    if (models.length === 0) continue;

    const dbEmp = models[0];
    let testOne = dbEmp.testOne || [];
    let testTwo = dbEmp.testTwo || [];

    if (arr[0].overWrite === "Yes") {
      testOne = arr.filter((f) => f.location === "At Home");
      testTwo = arr.filter((f) => f.location === "On Location");
    } else {
      testOne = testOne.filter((f) => !f.testNow).concat(arr.filter((f) => f.location === "At Home"));
      testTwo = testTwo.filter((f) => !f.testNow).concat(arr.filter((f) => f.location === "On Location"));
    }

    const d = await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.onBoardingTesting = JSON.stringify(arr);
        if (arr[0].date === moment().format("YYYY-MM-DD")) {
          updated.logDate = dbEmp.logDate || `${arr[0].date}T00:00:00Z`;
        }
        updated.testOne = testOne;
        updated.testTwo = testTwo;
        updated.testDone = false;
      })
    );
    const setting = await getCompanySetting();
    if (arr[0].testNowVerbiage) {
      let reminderTest = arr.map((t) => t.label).join(",");
      let message = parseMessage(arr[0].testNowVerbiage, dbEmp, "", reminderTest);
      if (setting.testNowType === "M") {
        await sendSMSNotification(dbEmp.phoneNumber, message);
      } else {
        await sendEmail([{ email: dbEmp.email, subject: "SafeCamp Notifcation", msg: message }]);
      }
    }
    // if (obj.testNowVerbiage) {
    //   let message = parseMessage(obj.testNowVerbiage, dbEmp, "", obj.label);
    //   await sendSMSNotification(dbEmp.phoneNumber, message);
    // }
  }
};
const addDefaultQuestions = async (emp) => {
  const defaultQuestions = [
    {
      groupName: "default",
      question: "Have you or anyone you have been near tested positive for COVID-19 in the past 72 hours",
    },
    {
      groupName: "default",
      question: "Have you felt any flu like systems such as a cough or running nose in the past 72 hours?",
    },
    {
      groupName: "default",
      question: "Are you currently awaiting results of a COVID-19 test?",
    },
    {
      groupName: "default",
      question:
        "Have you been told that you are suspected of having COVID-19 from a healthcare provider in the last 10 days?",
    },
  ];
  console.log("Data", defaultQuestions);
  defaultQuestions.forEach(async (qs) => {
    await DataStore.save(
      new Question({
        groupName: qs.groupName,
        question: qs.question,
      })
    );
  });
};

const createSchedule = async (obj) => {
  await DataStore.save(
    new Schedule({
      scheduleName: obj.scheduleName,
      scheduleType: obj.scheduleType,
      startDate: obj.startDate,
      endDate: obj.endDate,
      scheduleCategory: obj.scheduleCategory,
      dayOptions: obj.dayOptions,
      days: obj.days,
      companyID: userCompanyID.get(),
    })
  );
};

const updateSchedule = async (obj) => {
  const models = await DataStore.query(Schedule, (e) => e.id("eq", obj.id));
  const dbEmp = models[0];
  await DataStore.save(
    Schedule.copyOf(dbEmp, (updated) => {
      updated.scheduleName = obj.scheduleName;
      updated.scheduleType = obj.scheduleType;
      updated.startDate = obj.startDate;
      updated.endDate = obj.endDate;
      updated.scheduleCategory = obj.scheduleCategory;
      updated.dayOptions = obj.dayOptions;
      updated.days = obj.days;
    })
  );
};

const deleteSchedule = async (id) => {
  await DataStore.delete(Schedule, (obj) => obj.id("eq", id));
};

const deleteEmployee = async (id) => {
  await DataStore.delete(Employee, (obj) => obj.id("eq", id));
};

const getClients = async () => {
  const models = await DataStore.query(AppSetting);
  return models;
};

const addClient = async () => {
  await DataStore.save(
    new AppSetting({
      companyName: "Yara Shahidi Project",
      contactName: "Donnie Bruno",
      contactNumber: "+18189031049",
      qusRetryAttempts: 2,
    })
  );
};

const updateMainContact = async (name, num, cid) => {
  const models = await DataStore.query(AppSetting, (o) => o.id("eq", cid));
  const dbEmp = models[0];
  await DataStore.save(
    AppSetting.copyOf(dbEmp, (updated) => {
      updated.contactName = name;
      updated.contactNumber = num;
    })
  );
};

const updateTestingCord = async (name, num, cid) => {
  const models = await DataStore.query(AppSetting, (o) => o.id("eq", cid));
  const dbEmp = models[0];
  await DataStore.save(
    AppSetting.copyOf(dbEmp, (updated) => {
      updated.testingCordName = name;
      updated.testingCordNumber = num;
    })
  );
};

const updateGeneralDptTime = async (data) => {
  const models = await DataStore.query(AppSetting, (o) => o.id("eq", data.id));
  const dbEmp = models[0];
  await DataStore.save(
    AppSetting.copyOf(dbEmp, (updated) => {
      updated.defaultCallTime = data.defaultCallTime;
      updated.defaultTimeZone = data.defaultTimeZone;
    })
  );
};
const updateAllDeptTime = async (departments, data) => {
  const ttl = departments.length;
  for (let i = 0; i < ttl; i++) {
    const dpt = departments[i];
    const models = await DataStore.query(Department, (e) => e.id("eq", dpt));
    const dbEmp = models[0];
    await DataStore.save(
      Department.copyOf(dbEmp, (updated) => {
        updated.callTime = data.defaultCallTime;
        updated.deptTimeZone = data.defaultTimeZone;
      })
    );
  }
};
const updateAllEmployeesCallTime = async (Emps, data) => {
  console.log("Employees", Emps);
  const ttl = Emps.length;
  for (let i = 0; i < ttl; i++) {
    const emp = Emps[i];
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.callTime = data.defaultCallTime;
      })
    );
  }
  const smsData = Emps.map((em) => {
    return {
      phoneNumber: em.phoneNumber,
      email: em.email,
      message: parseMessageCallTime(data.callTimeVerbiage, em.firstName, em.lastName, "", data.defaultCallTime),
      subject: "Call Time Update",
    };
  });
  console.log("smsData", smsData);
  const type = data.callTimeType === "M" ? "sms" : "email";
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_calltime_${type}.csv`, jsonToCSV(smsData), {
    bucket: "hr-update-records",
  });
};

const getCompanySetting = async () => {
  const models = await DataStore.query(AppSetting, (o) => o.id("eq", userCompanyID.get()));
  return models[0];
};

const getSchedules = async () => {
  const models = await DataStore.query(Schedule, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};

const getQuestions = async () => {
  const models = await DataStore.query(Question, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};

const createQuestionnaire = async (obj) => {
  await DataStore.save(
    new Question({
      groupName: obj.groupName,
      question: obj.question,
      companyID: userCompanyID.get(),
    })
  );
};

const updateQuestionnaire = async (obj) => {
  const models = await DataStore.query(Question, (e) => e.id("eq", obj.id));
  const dbGrp = models[0];
  await DataStore.save(
    Question.copyOf(dbGrp, (updated) => {
      updated.groupName = obj.groupName;
      updated.question = obj.question;
    })
  );
};

const deleteQuestionnaire = async (id) => {
  await DataStore.delete(Question, (obj) => obj.id("eq", id));
};

const updateTestTypes = async (obj) => {
  const models = await DataStore.query(TestTypes, (e) => e.id("eq", obj.id));
  const dbTestType = models[0];
  await DataStore.save(
    TestTypes.copyOf(dbTestType, (updated) => {
      updated.totalTime = obj.totalTime;
      updated.firstAlert = obj.firstAlert;
      updated.secondAlert = obj.secondAlert;
    })
  );
};

const getTestTypes = async () => {
  const models = await DataStore.query(TestTypes);
  return models;
};
const newAppSettings = async (data) => {
  const models = await DataStore.query(AppSetting, (v) => v.id("eq", data.id));
  if (models.length === 0) return;
  await DataStore.save(
    AppSetting.copyOf(models[0], (updated) => {
      updated.isOnBoardVerbiage = data.isOnBoardVerbiage;
      updated.onBoardVerbiage = data.onBoardVerbiage;
      updated.isCreateEmployeeVerbiage = data.isCreateEmployeeVerbiage;
      updated.createEmplyeeVerbiage = data.createEmplyeeVerbiage;
      updated.isStartProgramVerbiage = data.isStartProgramVerbiage;
      updated.startProgramVerbiage = data.startProgramVerbiage;
      updated.isDailyTextVerbiage = data.isDailyTextVerbiage;
      updated.DailyTextVerbiage = data.DailyTextVerbiage;
      updated.isDailyReminderTwoVerbiage = data.isDailyReminderTwoVerbiage;
      updated.dailyReminderTwoVerbiage = data.dailyReminderTwoVerbiage;
      updated.isTestingReminderOneVerbiage = data.isTestingReminderOneVerbiage;
      updated.testingReminderOneVerbiage = data.testingReminderOneVerbiage;
      updated.isTestingReminderTwoVerbiage = data.isTestingReminderTwoVerbiage;
      updated.testingReminderTwoVerbiage = data.testingReminderTwoVerbiage;
      updated.isTestNowVerbiage = data.isTestNowVerbiage;
      updated.testNowVerbiage = data.testNowVerbiage;
      updated.isCallTimeVerbige = data.isCallTimeVerbige;
      updated.callTimeVerbiage = data.callTimeVerbiage;
      updated.onBoardType = data.onBoardType;
      updated.startProgramType = data.startProgramType;
      updated.dailyTextType = data.dailyTextType;
      updated.dailyReminderTwoType = data.dailyReminderTwoType;
      updated.testingReminderOneType = data.testingReminderOneType;
      updated.testingReminderTwoType = data.testingReminderTwoType;
      updated.callTimeType = data.callTimeType;
      updated.testNowType = data.testNowType;
    })
  );
};
const getDepartments = async () => {
  const models = await DataStore.query(Department, (d) => d.companyID("eq", userCompanyID.get()), {
    sort: (emp) => emp.name(SortDirection.ASCENDING),
  });
  return models;
};

const getRegions = async () => {
  const models = await DataStore.query(Regions, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};

const getLocations = async () => {
  const models = await DataStore.query(Locations, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};
const getProgramSettings = async () => {
  const models = await DataStore.query(Programs, (d) => d.companyID("eq", userCompanyID.get()));
  return models;
};
const newLocations = async (loc) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Locations, (d) => d.name("eq", loc).companyID("eq", compnayID));
  if (models.length > 0) return false;
  await DataStore.save(
    new Locations({
      name: loc,
      companyID: compnayID,
    })
  );
  return true;
};
const newProgramSettings = async (prog) => {
  console.log("NewPRogram", prog);
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Programs, (d) => d.name("eq", prog.name).companyID("eq", compnayID));
  if (models.length > 0) return false;
  const res = await DataStore.save(
    new Programs({
      name: prog.name,
      color: prog.color,
      testValidity: prog.testValidity || null,
      companyID: compnayID,
    })
  );
  return res;
};
const newRegions = async (name) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Regions, (d) => d.name("eq", name).companyID("eq", compnayID));
  if (models.length > 0) return false;
  const res = await DataStore.save(
    new Regions({
      name: name,
      companyID: compnayID,
    })
  );

  return true;
};
const newDepartment = async (dpt) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Department, (d) => d.name("eq", dpt.name).companyID("eq", compnayID));
  if (models.length > 0) return false;
  await DataStore.save(
    new Department({
      name: dpt.name,
      callTime: dpt.callTime,
      reminderOne: dpt.reminderOne,
      reminderTwo: dpt.reminderTwo,
      deptTimeZone: dpt.deptTimeZone,
      companyID: compnayID,
    })
  );

  return true;
};

const updateDepartmentData = async (dpt) => {
  const models = await DataStore.query(Department, (e) => e.id("eq", dpt.id));
  const dbEmp = models[0];
  if (models.length === 0) return false;
  await DataStore.save(
    Department.copyOf(dbEmp, (updated) => {
      updated.name = dpt.name;
      updated.callTime = dpt.callTime;
      updated.reminderOne = dpt.reminderOne;
      updated.reminderTwo = dpt.reminderTwo;
      updated.deptTimeZone = dpt.deptTimeZone;
    })
  );
  return true;
};

const addDepartment = async (dpt) => {
  const compnayID = userCompanyID.get();
  const models = await DataStore.query(Department, (d) => d.name("eq", dpt.name).companyID("eq", compnayID));
  if (models.length > 0) return models[0].id;
  const data = await DataStore.save(
    new Department({
      name: dpt.name,
      callTime: dpt.callTime || null,
      deptTimeZone: dpt.deptTimeZone,
      companyID: compnayID,
    })
  );
  return data.id;
};

const isDepartmentExists = async (name) => {
  const models = await DataStore.query(Department, (d) => d.name("eq", name).companyID("eq", userCompanyID.get()));

  return models.length > 1;
};

const updateDepartment = async (dpt) => {
  const models = await DataStore.query(Department, (e) => e.id("eq", dpt.id));
  const dbEmp = models[0];
  const prevCallTime = dbEmp.callTime;
  const setting = await getCompanySetting();

  await DataStore.save(
    Department.copyOf(dbEmp, (updated) => {
      updated.name = dpt.name;
      updated.callTime = dpt.callTime;
      updated.reminderOne = dpt.reminderOne;
      updated.reminderTwo = dpt.reminderTwo;
      updated.deptTimeZone = dpt.deptTimeZone;
    })
  );
  let employees = [];
  let inConsistentCallTimes = [];
  if (dpt.updateOption) {
    switch (dpt.updateOption) {
      case "active":
        employees = await DataStore.query(Employee, (e) => e.department("eq", dpt.id).isSchedule("eq", 1));
        break;
      case "inactive":
        employees = await DataStore.query(Employee, (e) => e.department("eq", dpt.id).isSchedule("ne", 1));
        break;
      default:
        employees = await DataStore.query(Employee, (e) => e.department("eq", dpt.id));
    }
  }
  const ttl = employees.length;

  for (let i = 0; i < ttl; i++) {
    let diff = 0;
    const emp = employees[i];

    if (emp.callTime && prevCallTime && emp.callTime !== prevCallTime && emp.callTime !== dpt.callTime) {
      const deptTime = moment(`${moment().format("YYYY-MM-DD")} ${prevCallTime}:00`);
      const arr = emp.callTime.split(":");
      const empTime = moment().set({ hours: arr[0], minutes: arr[1], seconds: 0 });
      diff = Math.floor(moment.duration(empTime.diff(deptTime)).asMinutes());
      inConsistentCallTimes.push({ ...emp, diff: diff });
      continue;
    } else if (!prevCallTime) {
      inConsistentCallTimes.push({ ...emp, diff: 0 });
      continue;
    }

    await DataStore.save(
      Employee.copyOf(employees[i], (updated) => {
        updated.callTime = dpt.callTime;
      })
    );

    if (setting && setting.isCallTimeVerbige && setting.callTimeVerbiage) {
      const message = parseMessageCallTime(setting.callTimeVerbiage, emp.firstName, emp.lastName, "", dpt.callTime);
      if (setting.callTimeType === "M") {
        console.log("sendSMSupdateDepartment6");
        await sendSMSNotification(emp.phoneNumber, message);
      } else {
        await sendEmail([{ email: emp.email, subject: "SafeCamp Notifcation", msg: message }]);
      }
    }
  }
  return {
    employees: inConsistentCallTimes,
    callTime: `${moment().format("YYYY-MM-DD")} ${prevCallTime}`,
    newCallTime: dpt.callTime,
  };
};

const updateEmployeeCallTime = async (empIds) => {
  const ttl = empIds.length;
  const setting = await getCompanySetting();
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i].id));
    const dbEmp = models[0];
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.callTime = empIds[i].callTime;
      })
    );
    if (setting && setting.isCallTimeVerbige && setting.callTimeVerbiage) {
      const message = parseMessageCallTime(
        setting.callTimeVerbiage,
        dbEmp.firstName,
        dbEmp.lastName,
        "",
        empIds[i].callTime
      );
      if (setting.callTimeType === "M") {
        console.log("sendSMSupdateDepartment6");
        await sendSMSNotification(dbEmp.phoneNumber, message);
      } else {
        await sendEmail([{ email: dbEmp.email, subject: "SafeCamp Notifcation", msg: message }]);
      }
    }
  }
  return true;
};

const manualClearedEmployee = async (empIds, reason, userName) => {
  const ttl = empIds.length;
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", empIds[i]));
    const dbEmp = models[0];
    console.log("Ids", dbEmp);
    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.isScreeningDone = dbEmp.testDone || false;
        updated.qaDone = true;
        updated.manualCheckIn = true;
        updated.manualCheckInReason = reason;
        updated.manualCheckAdmin = userName;
      })
    );
  }

  return true;
};

const externalTestState = async (emp) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id.replace("%testNow", "")));
  const dbEmp = models[0];
  console.log("Ids", dbEmp);
  await DataStore.save(
    Employee.copyOf(dbEmp, (updated) => {
      updated.onBoardingTesting = JSON.stringify(emp.onBoardingTesting);
      updated.testOne = JSON.stringify(emp.testOne);
      updated.testTwo = JSON.stringify(emp.testTwo);
      updated.externalTestFile = emp.externalTestFile;

      if ([...emp.testOne, ...emp.testTwo]?.every((e) => e.isDone)) {
        updated.testDone = true;
      }
    })
  );
  try {
    if (emp.externalTestImage instanceof Object) {
      console.log("Before", emp.externalTestImage);
      await Storage.put(emp.externalTestFile, emp.externalTestImage, { bucket: "employee-external-tests" });
      console.log("after", emp.externalTestImage);
    }
  } catch (err) {
    console.log("Error", err);
  }
  return true;
};

const zoneLevelExternalTest = async (emp) => {
  try {
    const models = await DataStore.query(Employee, (e) => e.id("eq", emp.id));
    const dbEmp = models[0];
    console.log("[zoneLevelExternalTest]Ids", dbEmp);

    const prevTestHistory = [...(emp.testHistory || [])];
    const newTestList = emp.testTwo.filter((t) => t.isNewResult);
    if (newTestList.length === 0) return false;
    const newTest = newTestList[0];
    const testPrevIndex = prevTestHistory.findIndex((t) => t.value === newTest.value);
    if (testPrevIndex !== -1) {
      prevTestHistory.splice(testPrevIndex, 1);
    }
    Object.assign(newTest, { date: moment().utc().toISOString() });
    prevTestHistory.push(newTest);

    await DataStore.save(
      Employee.copyOf(dbEmp, (updated) => {
        updated.testHistory = prevTestHistory;
      })
    );
    if (emp.externalTestImage instanceof Object) {
      console.log("Before", emp.externalTestImage);
      await Storage.put(emp.externalTestFile, emp.externalTestImage, { bucket: "employee-external-tests" });
      console.log("after", emp.externalTestImage);
    }
  } catch (err) {
    console.log("Api Error", err);
  }
  return true;
};

const addAuditLogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_program.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};
const addlogs = async (data) => {
  await Storage.put(`${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_audit_logs.csv`, jsonToCSV(data), {
    bucket: "hr-update-records",
  });
};
const updateLocation = async (loc) => {
  const models = await DataStore.query(Locations, (e) => e.id("eq", loc.id));
  const dbLoc = models[0];
  await DataStore.save(
    Locations.copyOf(dbLoc, (updated) => {
      updated.name = loc.name;
    })
  );
};
const updateProgramSettings = async (prog) => {
  const models = await DataStore.query(Programs, (e) => e.id("eq", prog.id));
  const dbProgram = models[0];
  await DataStore.save(
    Programs.copyOf(dbProgram, (updated) => {
      updated.name = prog.name;
      updated.color = prog.color;
      updated.testValidity = prog.testValidity || null;
    })
  );
};
const updateRegion = async (reg) => {
  const models = await DataStore.query(Regions, (e) => e.id("eq", reg.id));
  const dbReg = models[0];
  await DataStore.save(
    Locations.copyOf(dbReg, (updated) => {
      updated.name = reg.name;
    })
  );
};

const deleteDepartment = async (id) => {
  await DataStore.delete(Department, (obj) => obj.id("eq", id));
};
const deleteLocation = async (id) => {
  await DataStore.delete(Locations, (obj) => obj.id("eq", id));
};
const deleteProgramSettings = async (id) => {
  await DataStore.delete(Programs, (obj) => obj.id("eq", id));
};
const deleteRegion = async (id) => {
  await DataStore.delete(Regions, (obj) => obj.id("eq", id));
};

const getEmployeeDailyLogs = async (id) => {
  return await DataStore.query(EmployeeDailyLog, (obj) => obj.employeeID("eq", id), {
    sort: (emp) => emp.logDate(SortDirection.DESCENDING),
  });
};

const getAllEmployeeDailyLogs = async () => {
  return await DataStore.query(EmployeeDailyLog, (e) => e.companyID("eq", userCompanyID.get()), {
    sort: (emp) => emp.logDate(SortDirection.DESCENDING),
  });
};

const getEmployeeQuestion = async (id, logDate) => {
  console.log("getEmployeeQuestion", id, logDate);
  return (await DataStore.query(EmployeeQuestion)).filter(
    (f) =>
      f.employeeID === id &&
      moment(f.createdAt).startOf("day").format("YYYY-MM-DD") === moment(logDate).startOf("day").format("YYYY-MM-DD")
  );
};

const deleteDailyLog = async (id) => {
  await DataStore.delete(EmployeeDailyLog, (obj) => obj.id("eq", id));
};

const getEmployeeTestFromMD = async () => {
  console.log("[employeeTestFromMD]");
  const path = "/employeeTestFromMD";

  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      phone_number: "7657921234",
    },
  };

  const counterData = await API.post(apiName, path, params);

  console.log("Counter Api", counterData);
};

const getPrevEnvData = async (data) => {
  console.log("[datacall]", data);
  // const path = "/sendEmailToCrew";
  const path = "/getTableData";

  const params = {
    body: {
      tablename: "Department-bhxmvj6obzdkncqyydtxv3pu7a-staging",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  try {
    const infoUpdated = await API.post(apiName, path, params);
    console.log("[datacall]", infoUpdated);
  } catch (e) {
    console.log("Error Email Send:", e);
  }
};
const getPreRegisterData = async (data, env) => {
  console.log("[getPreRegisterRecordNew]");
  const path = "/getPreRegisterRecordNew";
  console.log("data", data, env);
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      // phone_number: "26951b33-c456-42de-9afb-33431695d56e",
      env: env,
      phone_number: data,
    },
  };

  const counterData = await API.post(apiName, path, params);
  console.log("counterApi", counterData);
  return counterData.Items;
};
const getRegisterData = async (data) => {
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/getPreFill";
  console.log("data", data);
  const counterData = await axios.post(path, data);
  console.log("counterApi", counterData);
  return counterData.data.data;
};

const updateEmployeeDefault = async (id, name) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", id));
  const emp = models[0];
  console.log("Employee", emp.firstName, emp.lastName, name);
  await DataStore.save(
    Employee.copyOf(emp, (updated) => {
      updated.programName = name;
    })
  );
  return true;
};
const updateEmpProgramName = async (ids, prog, zoneAssign, userName) => {
  if (!ids || ids.length === 0) return;
  const ttl = ids.length;
  const logData = [];
  for (let i = 0; i < ttl; i++) {
    const models = await DataStore.query(Employee, (e) => e.id("eq", ids[i]));
    const emp = models[0];
    await DataStore.save(
      Employee.copyOf(emp, (updated) => {
        updated.programName = prog.name;
        updated.programID = prog.id;
        updated.isSchedule = zoneAssign;
      })
    );
    logData.push({
      id: emp.id,
      slug: "AssignProgram",
      userName: userName,
      companyID: emp.companyID,
      programName: emp.programName,
      phone_number: emp.phoneNumber,
    });
  }
  await addAuditLogs(logData);
};

const updateEmployeeSub = async (id, name) => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", id));
  const emp = models[0];
  console.log("Employee", emp.firstName, emp.lastName, name);
  await DataStore.save(
    Employee.copyOf(emp, (updated) => {
      updated.subID = name;
    })
  );
  return true;
};

const deleteSandboxData = async (id, name) => {
  const models = await DataStore.query(Employee, (e) => e.companyID("eq", "d7cbdc82-9d29-48bd-945b-a7dd3b192c2b"));
  models.forEach(async (e) => {
    await DataStore.delete(Employee, (obj) => obj.id("eq", e.id));
  });
  return true;
};

const updateEmployeeAddress = async () => {
  const models = await DataStore.query(Employee, (e) => e.id("eq", "57b4f60a-acec-4150-8224-49eb3d2e5c8e"));
  const emp = models[0];
  console.log("Employee", emp.firstName, emp.lastName);
  await DataStore.save(
    Employee.copyOf(emp, (updated) => {
      updated.street = "";
      updated.street2 = "";
      updated.city = "";
      updated.state = "";
      updated.zip = "";
      (updated.isVaccinated = false), (updated.idNumber = "");
    })
  );
  return true;
};

const updateEmployeeLogs = async (id, testDone, testTwo) => {
  const models = await DataStore.query(EmployeeDailyLog, (e) => e.id("eq", id));
  const emp = models[0];
  await DataStore.save(
    EmployeeDailyLog.copyOf(emp, (updated) => {
      (updated.testDone = testDone), (updated.testTwo = testTwo);
    })
  );
  return true;
};

const getEmployeeClients = async (username) => {
  console.log("[getEmployeeClients]");
  const path = "/getEmployeeClients";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      username: username,
    },
  };

  const counterData = await API.post(apiName, path, params);
  console.log("getEmployeeClients", counterData);
  return counterData.Items.length > 0 ? counterData.Items[0].clients : null;
};

const getCompnayList = async () => {
  console.log("[getCompnayList]");
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/hrdefaults";
  const companies = await axios.post(path, { id: "87933844" });

  return companies.data.data;
};

const employeeAuditTrail = async (id) => {
  console.log("[employeeAuditTrail]");
  const path = "/employeeAuditTrail";
  const params = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      id: id,
    },
  };

  const counterData = await API.post(apiName, path, params);
  console.log("employeeAuditTrail", counterData);
  return counterData.tests;
};

const createHrEmployee = async (data) => {
  const path = "https://9dpj6yddw2.execute-api.eu-west-1.amazonaws.com/default/preregistration";
  const employeeData = await axios.put(path, data);
  console.log("employeeData", employeeData);
  return employeeData.data;
};

export default {
  updateCompnayEmployeeNo,
  deleteSandboxData,
  addAuditLogs,
  getEmployeeClients,
  updateEmployeeLogs,
  createEmployee,
  deleteEmployee,
  getCurrentUser,
  getAllUsers,
  signIn,
  fetchUsersInGroup,
  fetchAllUsers,
  getProgramSettings,
  newProgramSettings,
  updateProgramSettings,
  changeUserRole,
  updateUserInfo,
  addUserClients,
  deleteUser,
  updateUserPassword,
  sendSMSNotification,
  sendEmail,
  sendEmailToCrew,
  deleteProgramSettings,
  deleteUserLinkFromEmployee,
  getEmployees,
  getEmployeeQuestion,
  newEmployee,
  updateEmployee,
  updateEmployeeProgramState,
  updateEmployeeLogDate,
  updateEmployeeGroup,
  removeEmployeeGroup,
  addDefaultQuestions,
  getSchedules,
  getQuestions,
  createSchedule,
  updateRegion,
  updateSchedule,
  deleteSchedule,
  updateTestTypes,
  updateLocation,
  createQuestionnaire,
  fetchAllEmployees,
  confirmUserSignUp,
  updateQuestionnaire,
  deleteQuestionnaire,
  deleteLocation,
  deleteRegion,
  endProgram,
  getTestTypes,
  getClients,
  newLocations,
  newRegions,
  getDepartments,
  newDepartment,
  getPreRegisterData,
  updateDepartmentData,
  updateGeneralDptTime,
  updateAllDeptTime,
  updateAllEmployeesCallTime,
  getLocations,
  newAppSettings,
  updateDepartment,
  deleteDepartment,
  isDepartmentExists,
  getEmployeeDailyLogs,
  getAllEmployeeDailyLogs,
  getCompanySetting,
  udpatePersonalization,
  getRegions,
  externalTestState,
  zoneLevelExternalTest,
  fetchAllCrewMembers,
  addDepartment,
  updateEmployeeTest,
  getEmployeeTestFromMD,
  getPrevEnvData,
  updateEmployeeCallTime,
  addClient,
  addlogs,
  updateMainContact,
  updateTestingCord,
  manualClearedEmployee,
  deleteDailyLog,
  saveEmployeeLogs,
  getRegisterData,
  updateEmployeeDefault,
  updateEmpProgramName,
  updateEmployeeAddress,
  updateEmployeeSub,
  getCompnayList,
  employeeAuditTrail,
  createHrEmployee,
};
