import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button, Overlay, Popover } from "react-bootstrap";
import API from "api";
import { Link } from "react-router-dom";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth, removeSpaceIns, getValidReg } from "utils";
import ProgramNameInput from "components/Modal/ProgramNameInput";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import Loader from "components/Loader/Loader";
import DepartmentImportModal from "components/Modal/DepartmentImportModal";
import { COLOR_VALUE, TEST_TYPE_VALUE } from "constant";
import HeaderItem from "components/Table/HeaderItem";

const programSettings = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [programSettings, setProgramSettings] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  const [openImport, setOpenImport] = useState(false);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 50, 2);
  const formatProgram = () => {
    const data = appContext.employees
      .filter((f) => f.programName)
      .reduce((obj, employee) => {
        obj[employee.programName] = (obj[employee.programName] || 0) + 1;
        return obj;
      }, {});

    setProgramSettings(
      appContext.programs.map((t) => {
        const testTypes = t.testValidity?.map((m) => TEST_TYPE_VALUE[m.value]) || [];
        let ttlEmployees = data[t.name] || 0;
        let tests = "";
        if (testTypes.length === 1) tests = testTypes[0];
        if (testTypes.length > 1) tests = "Multi";
        return { ...t, tests, totalEmployees: ttlEmployees };
      })
    );
  };
  useEffect(() => {
    formatProgram();
    setLoading(false);
  }, [appContext.programs]);

  const sortPrograms = (departments, sortParam) => {
    if (sortDescending) {
      return [...departments].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...departments].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    if (programSettings.length > 0) setProgramSettings(sortPrograms(programSettings, sortBy));
  }, [sortBy, sortDescending]);
  console.log("program", programSettings);
  const handleConfirmDelete = async (isConfirm) => {
    console.log("[DELETE USER]", itemToDelete);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    setItemToDelete(null);
    const filterData = appContext.employees.filter((emp) => emp.programName === itemToDelete.name);
    console.log("filterData", filterData);
    if (filterData.length > 0) {
      appContext.showErrorMessage("This Program is Assign to Employees");
      return;
    }
    setLoading(true);
    try {
      await API.deleteProgramSettings(itemToDelete.id);
      appContext.resetPrograms();
      appContext.showSuccessMessage("program deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };
  const OnHandleProgramSettings = async (prog) => {
    setOpenCreateModal(false);
    try {
      setLoading(true);
      if (prog.isNew) {
        (await API.newProgramSettings(prog))
          ? appContext.showSuccessMessage("Program Created Successfully")
          : appContext.showErrorMessage("programSettings already exist");
      } else {
        await API.updateProgramSettings(prog);
        appContext.showSuccessMessage("Program Updated Successfully");
      }
      appContext.resetPrograms();
      setSelectItem(null);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };
  const handleEdit = (item) => {
    setSelectItem(item);
    setOpenCreateModal(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleImport = async (importData) => {
    console.log("depData", importData);
    if (importData.length === 0) {
      setOpenImport(false);
      return;
    }

    if (Object.keys(importData[0].data)[0] !== "name") {
      appContext.showErrorMessage("First column Header must be name");
      setOpenImport(false);
      return;
    }
    if (Object.keys(importData[0].data)[1] !== "color") {
      appContext.showErrorMessage("Second column Header must be color");
      setOpenImport(false);
      return;
    }
    setLoading(true);
    const ttlLength = importData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = importData[index];
      if (Object.values(data).every((c) => c.length == 0)) continue;
      const obj = {
        name: getValidReg(data.name),
        color: data.color ? COLOR_VALUE[removeSpaceIns(data.color)] : "Black",
      };
      await API.newProgramSettings(obj);
    }
    setOpenImport(false);
    setLoading(false);
    appContext.resetPrograms();
    appContext.showSuccessMessage("Import Successfully");
  };

  const TableCollapse = ({ data, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{TEST_TYPE_VALUE[data.value]}</td>
        <td>{`${data.days} ${data.days > 1 ? "days" : "day"}`}</td>
      </tr>
    );
  };

  const TableRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const childNode = useRef();
    let setTimeoutConst = null;
    const handleMouseEnter = () => {
      setTimeoutConst = setTimeout(() => {
        setShowPopover(true);
      }, 1000);
    };
    const handleMouseLeave = () => {
      clearTimeout(setTimeoutConst);
      setShowPopover(false);
    };
    return (
      <>
        <tr>
          <td className="icon">
            <img
              src={editIcon}
              alt="edit icon"
              width="18"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={() => handleEdit(item)}
            />
          </td>
          <td style={{ textAlign: "left", textOverflow: "visible" }}>{item.name}</td>
          <td style={{ textAlign: "center", textOverflow: "visible" }}>
            <div
              className="programColor"
              style={{
                width: "10px",
                height: "10px",
                marginRight: "5px",
                backgroundColor: `${removeSpaceIns(item.color)}`,
              }}
            />
          </td>
          {appContext.company?.programLevel === "Zone" && (
            <td style={{ textAlign: "center" }}>
              {item.testValidity && item.testValidity.length > 0 && (
                <div
                  ref={childNode}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => setShowPopover(true)}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  aria-hidden="true"
                >
                  {item.tests}
                </div>
              )}
            </td>
          )}
          <td style={{ textAlign: "center", textOverflow: "visible" }} title={item.totalEmployees}>
            {item.totalEmployees > 0 ? (
              <Link
                style={{ color: "#42cef5" }}
                to={{
                  pathname: "/admin/employees",
                  state: { name: item.name, term: "programName" },
                }}
              >
                {item.totalEmployees}
              </Link>
            ) : (
              item.totalEmployees
            )}
          </td>
          <td style={{ textAlign: "center", textOverflow: "visible" }}>
            <img
              src={trashIcon}
              alt="trash icon"
              width="15"
              style={{ marginLeft: "10px" }}
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={(e) => setItemToDelete(item)}
            />
          </td>
        </tr>
        {item.testValidity && item.testValidity.length > 0 && showPopover && (
          <Overlay show={showPopover} placement="auto" flip target={childNode.current}>
            <Popover
              onMouseEnter={() => {
                setShowPopover(true);
              }}
              onMouseLeave={handleMouseLeave}
              id="popover"
              className="popOverSchedule"
            >
              <Popover.Header className="fw-bold">{item.name}</Popover.Header>
              <Card className="striped-table-card programPopOverCard">
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive overFlow-y-hidden">
                      <Table className="table">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>SR#</th>
                            <th>TEST TYPE</th>
                            <th>DURATION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.testValidity.map((data, index) => (
                            <TableCollapse data={data} key={index} index={index} />
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Popover>
          </Overlay>
        )}
      </>
    );
  };
  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <div className="buttonHeader">
                  <Button
                    className="headerButton btn-fill"
                    variant="secondary"
                    onClick={() => setOpenCreateModal(true)}
                  >
                    Create Program
                  </Button>
                  <Button
                    className="headerButton btn-fill"
                    variant="secondary"
                    onClick={() => {
                      setOpenImport(true);
                    }}
                  >
                    Import Program
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    programSettings.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="name"
                          title="Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="color"
                          title="Zone Color"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        {appContext.company?.programLevel === "Zone" && (
                          <HeaderItem
                            width={tdWidth}
                            ItemKey="tests"
                            title="Test Validity"
                            flipSort={flipSort}
                            sortBy={sortBy}
                            sortDescending={sortDescending}
                            aligned="centered"
                          />
                        )}
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="totalEmployees"
                          title="Total Employees"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {programSettings.map((item, i) => {
                        return <TableRow key={i} item={item} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete programSettings"
              message="Are you sure, you want to remove program on settings?"
              handleConfirm={handleConfirmDelete}
            />
          )}
          {openCreateModal && (
            <ProgramNameInput
              programSetting={true}
              handleClose={() => {
                setSelectItem(null), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleProgramSettings}
              programs={appContext.programs}
            />
          )}
          {openImport && <DepartmentImportModal handleClose={() => setOpenImport(false)} handleImport={handleImport} />}
        </Col>
      </Row>
    </div>
  );
};

export default programSettings;
