import React, { useRef } from "react";
import Select from "react-select";
import {MESSAGE_SEND_TYPE} from "constant";

const TEXT_FILED_OPTIONS = [
  { label: "First Name", value: "{firstName}" },
  { label: "Last Name", value: "{lastName}" },
  { label: "Test Type", value: "{testType}" },
  { label: "Call Time", value: "{callTime}" }
];

const TextAreaEditor = (props) => {
  const { isSelected, message, name, handleChange, label, type, optionTestType } = props;
  const textAreaRef = useRef();
  

  const insertAtCursor = (dropDownValue) => {
    textAreaRef.current.focus;
    if (textAreaRef.current.selectionStart || textAreaRef.current.selectionStart === "0") {
      let startPos = textAreaRef.current.selectionStart;
      let endPos = textAreaRef.current.selectionEnd;
      textAreaRef.current.value =
        textAreaRef.current.value.substring(0, startPos) +
        dropDownValue +
        textAreaRef.current.value.substring(endPos, textAreaRef.current.value.length);
        const newPos = endPos + dropDownValue.length;
        textAreaRef.current.setSelectionRange(newPos,newPos);
      textAreaRef.current.focus();
      handleChange(isSelected, textAreaRef.current.value, name, type);
    } else {
      handleChange(isSelected, textAreaRef.current.value, name, type);
    }
  };
  return (
    <div className="row">
      <div className="col-lg-2 col-12 mb-3">
        <label className="modalLineHeaders mt-0 d-inline-flex align-items-start message-label">
          <input
              className="position-relative"
              style={{top: '3px'}}
            checked={isSelected}
            type="checkBox"
            onChange={(e) => handleChange(e.target.checked, message, name)}
          ></input>
          <span className="showDisclaimerText">{`${label}:`}</span>
        </label>
      </div>
      <div className="col-lg-5 col-12 mb-3">
        <div className="d-flex align-items-center">
        <Select
          options={MESSAGE_SEND_TYPE}
          isDisabled={!isSelected}
          blurInputOnSelect={true}
          className="w-75"
          menuPlacement="auto"
          placeholder="Select message Type"
          value={MESSAGE_SEND_TYPE.find((f)=> f.value === type)}
          onChange={(e)=>handleChange(isSelected, message, name, e.value)}
          />
        <Select
          value={{ label: "Insert field", value: "none" }}
          options={TEXT_FILED_OPTIONS}
          blurInputOnSelect={true}
          isDisabled={!isSelected}
          menuPlacement="auto"
          className="w-30 ml-auto inputFieldMessage"
          placeholder="Insert Field"
          onChange={(e) => insertAtCursor(e.value)}
        />
        </div>
        <textarea
          placeholder={label}
          className="MessageInput"
          readOnly={!isSelected}
          ref={textAreaRef}
          value={message}
          onChange={(e) => handleChange(isSelected, e.target.value, name, type)}
        />
      </div>
    </div>
  );
};
export default TextAreaEditor;
