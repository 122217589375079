import React, { useState, useContext, useRef } from "react";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import { AppContext } from "context/app-context";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import { Link } from "react-router-dom";
import DepartmentImportModal from "components/Modal/DepartmentImportModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import DepartmentModal from "components/Modal/DepartmentModal";
import API from "api";
import { Bounce } from "react-activity";
import HeaderItem from "components/Table/HeaderItem";
import {
  isValidCallTime,
  getCallTime,
  calculateTdWidth,
  getValidReg,
  checkValidity,
  formatTimeZone,
  downloadDataAsCSV,
} from "utils";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import InconsistentCallTimeModal from "components/Department/InconsistentCallTimeModal";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";
import { TIMEZONE_VALUE, CONFIRMATION_TYPE } from "constant";
import GeneralDeptTime from "components/Modal/GeneralDeptTime";
import DepartmentOptionsModal from "components/Modal/DepartmentOptionsModal";

const Departments = (props) => {
  const { setDepartment } = props;
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [inConsistentCallTimes, setInConsistentCallTimes] = useState({ employees: [] });
  const [showGeneralTime, setShowGeneralTime] = useState(false);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 50, 4);

  const [loading, setLoading] = useState();
  const [openImportDep, setOpenImportDep] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [title, setTitle] = useState("");
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [client, setClient] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [filterDepartments, setFilterDepartments] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const appContext = useContext(AppContext);

  useEffect(() => {
    getDepartments();
    getClient();
  }, []);
  const getClient = async () => {
    const Client = await API.getCompanySetting();
    setClient(Client);
  };
  const sortDepartments = (departments, sortParam) => {
    if (sortDescending) {
      return [...departments].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...departments].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    if (departments.length > 0) setFilterDepartments(sortDepartments(filterDepartments, sortBy));
  }, [sortBy, sortDescending]);
  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartments(data);
    setDepartment(data);
  };

  const handleDepartment = () => setOpenDepartmentModal(true);

  const formatDepartments = () => {
    const data = appContext.employees
      .filter((f) => f.department)
      .reduce((obj, employee) => {
        let dptId = employee.department;
        obj[dptId] = (obj[dptId] || 0) + 1;
        return obj;
      }, {});
    console.log("data", data);
    const dptData = departments.map((dpt) => {
      let ttlEmployees = data[dpt.id] || 0;
      return {
        ...dpt,
        totalEmployees: ttlEmployees,
      };
    });
    setFilterDepartments(dptData);
  };
  useEffect(() => {
    formatDepartments();
    setLoading(false);
  }, [departments]);

  const createDepartment = async (dpt) => {
    const dept = { ...dpt };
    if (!dept.callTime) {
      dept.callTime = client.defaultCallTime;
    }
    if (!dept.deptTimeZone) {
      dept.deptTimeZone = client.defaultTimeZone;
    }
    try {
      setOpenDepartmentModal(false);
      setLoading(true);
      if (dept.isNew) {
        (await API.newDepartment(dept, appContext.company))
          ? appContext.showSuccessMessage("Department created successfully")
          : appContext.showErrorMessage("Department Name Already Exist");
      } else {
        const res = await API.updateDepartment(dept);
        if (res.employees.length === 0) {
          appContext.showSuccessMessage("Department updated successfully");
        } else {
          setInConsistentCallTimes(res);
        }
      }
      getDepartments();
      setSelectedDepartment(null);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err on create department", err.message);
    }
  };
  const updateGeneralTime = async (data) => {
    setLoading(true);
    console.log("Time", data);
    setShowGeneralTime(false);
    try {
      await API.updateGeneralDptTime(data);
      getClient();
      setOpenConfirmation({
        isShow: true,
        actionType: CONFIRMATION_TYPE.UPDATE_TIME,
        title: "Update Time",
        message: "Would you like to Update All Departments Call Time and Time Zone?",
      });
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };
  const handleImportDepartment = async (depData) => {
    console.log("depData", depData);
    if (depData.length === 0) return;
    const arr = [];
    const arrdata = [];
    const departmentList = [...departments];
    if (Object.keys(depData[0].data)[0] !== "name") {
      appContext.showErrorMessage("First column Header must be name");
      setOpenImportDep(false);
      return;
    }
    if (Object.keys(depData[0].data)[1] !== "callTime") {
      appContext.showErrorMessage("Second column Header must be callTime");
      setOpenImportDep(false);
      return;
    }
    setLoading(true);
    const ttlLength = depData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = depData[index];
      const dep = { ...data };
      if (Object.values(data).every((c) => c.length == 0)) continue;
      if (!dep.callTime) {
        dep.callTime = client.defaultCallTime;
      }
      if (!dep.deptTimeZone) {
        dep.deptTimeZone = formatTimeZone(client.defaultTimeZone);
      }
      dep.callTime = getCallTime(dep.callTime);
      if (!isValidCallTime(dep.callTime)) {
        arr.push({ message: `Call Time ${dep.callTime} must be 24 hours format (00:00) on row ${index + 2}` });
        continue;
      }
      if (dep.deptTimeZone) {
        const tz = TIMEZONE_VALUE[dep.deptTimeZone.toLowerCase().trim()];
        dep.deptTimeZone = tz;
      }

      if (dep.name) {
        dep.name = getValidReg(dep.name);
        const findVal = departmentList.find((f) => checkValidity(f.name) == checkValidity(dep.name));
        if (findVal) {
          console.log("Already Exist", findVal);
          Object.assign(dep, { id: findVal.id });
          (await API.updateDepartmentData(dep)) && arrdata.push(dep.name);
        } else {
          departmentList.push({ name: dep.name });
          (await API.newDepartment(dep)) && arrdata.push(dep.name);
        }
      } else {
        arr.push({ message: `Fill Department name please on row ${index + 2}` });
      }
    }
    setOpenImportDep(false);
    getDepartments();
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      console.log("arr", arr);
      setErrorData(arr);
      setTitle("Department");
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleEdit = (item) => {
    setSelectedDepartment({ ...item, isNew: false });
    setOpenDepartmentModal(true);
  };
  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const onDeleteConfirm = async () => {
    console.log("[DELETE USER]", itemToDelete);
    const filterdata = appContext.employees.filter((emp) => emp.department === itemToDelete.id);
    if (filterdata.length > 0) {
      appContext.showErrorMessage("This Department is Assign to Employees");
      return;
    }
    try {
      setLoading(true);
      await API.deleteDepartment(itemToDelete.id);
      setItemToDelete(null);
      appContext.showSuccessMessage("Department deleted successfully");
      getDepartments();
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const UpdateAllDptTime = async () => {
    setLoading(true);
    const Emps = appContext.employees.filter((f) => f.department);
    const DepartmentIds = departments.map((d) => d.id);
    try {
      await API.updateAllDeptTime(DepartmentIds, client);
      if (Emps.length > 0) await API.updateAllEmployeesCallTime(Emps, client);
      await getDepartments();
      await appContext.resetEmployees();
      appContext.showSuccessMessage("Update All Departments Call Time with General Time");
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };

  const onHandleConfirmation = async (isConfirm, type) => {
    console.log("[DELETE USER]", itemToDelete);
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      setItemToDelete(null);
      switch (type) {
        case CONFIRMATION_TYPE.UPDATE_TIME:
          setShowOptions(true);
          // appContext.showSuccessMessage("Update General Time Successfully");
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        onDeleteConfirm();
        break;
      case CONFIRMATION_TYPE.UPDATE_TIME:
        UpdateAllDptTime();
        break;
    }
  };

  const showHoursFormat = (value) => {
    if (!value) return "-";
    const val = parseInt(value);
    if (val === 1) return "1 hr";
    return `${val} hrs`;
  };

  const handleDownloadDepartment = async () => {
    await downloadDataAsCSV(
      departments.map((d) => {
        return {
          name: d.name,
          callTime: d.callTime,
          deptTimeZone: formatTimeZone(d.deptTimeZone),
          reminderOne: d.reminderOne,
          reminderTwo: d.reminderTwo,
        };
      }),
      "departments"
    );
  };

  const updateSelectedCallTime = async (selectedDpt) => {
    setShowOptions(false);
    if (selectedDpt.length === 0) return;
    setLoading(true);
    const Emps = appContext.employees.filter((f) => selectedDpt.includes(f.department));
    try {
      await API.updateAllDeptTime(selectedDpt, client);
      if (Emps.length > 0) await API.updateAllEmployeesCallTime(Emps, client);
      await getDepartments();
      await appContext.resetEmployees();
      appContext.showSuccessMessage(`Update ${selectedDpt.length} Departments Call Time with General Time`);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };

  const openDeleteConfirmation = (user) => {
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: "Delete User",
      message: "Are you sure, you want to remove Employee?",
    });
    if (user) setItemToDelete(user);
  };
  console.log("departments", departments);
  const TableRow = ({ item }) => {
    return (
      <tr>
        <td className="icon">
          <img
            src={editIcon}
            alt="edit icon"
            width="18"
            className="fas fa-edit"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => handleEdit(item)}
          />
        </td>
        <td className="ellipsis ">{item.name}</td>
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {item.callTime}
        </td>
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {formatTimeZone(item.deptTimeZone)}
        </td>
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {showHoursFormat(item.reminderOne)}
        </td>
        <td className="ellipsis centered" style={{ textAlign: "center", textOverflow: "visible" }}>
          {showHoursFormat(item.reminderTwo)}
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }} title={item.totalEmployees}>
          {item.totalEmployees > 0 ? (
            <Link
              style={{ color: "#42cef5" }}
              to={{
                pathname: "/admin/employees",
                state: { name: item.id, term: "department" },
              }}
            >
              {item.totalEmployees}
            </Link>
          ) : (
            item.totalEmployees
          )}
        </td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          <img
            src={trashIcon}
            alt="trash icon"
            width="15"
            style={{ marginLeft: "10px" }}
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={(e) => {
              openDeleteConfirmation(item);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Button className="headerButton btn-fill" variant="secondary" onClick={handleDepartment}>
              Create Department
            </Button>
            <Button
              className="headerButton btn-fill"
              variant="secondary"
              onClick={() => {
                setOpenImportDep(true);
              }}
            >
              Import Departments
            </Button>
            <Button className="headerButton btn-fill" variant="secondary" onClick={handleDownloadDepartment}>
              Download Departments
            </Button>
            <Button className="headerButton btn-fill" variant="secondary" onClick={() => setShowGeneralTime(true)}>
              General Time
            </Button>
          </Card.Header>

          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                departments.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead ref={componentRef}>
                  <tr>
                    <th></th>
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="name"
                      title="Name"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="callTime"
                      title="Call Time"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="deptTimeZone"
                      title="Time Zone"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="reminderOne"
                      title="Reminder One"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="reminderTwo"
                      title="Reminder Two"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <HeaderItem
                      width={tdWidth}
                      ItemKey="totalEmployees"
                      title="Total Employees"
                      flipSort={flipSort}
                      sortBy={sortBy}
                      sortDescending={sortDescending}
                      aligned="centered"
                    />
                    <th className={`border-0 centered ellipsis`} title="Action">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filterDepartments.map((item, i) => {
                    return <TableRow key={i} item={item} />;
                  })}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Bounce
          style={{
            position: "fixed",
            left: "50%",
            right: "50%",
            top: "50%",
          }}
          size={35}
          color={"#A82632"}
        />
      )}

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
        />
      )}

      {openImportDep && (
        <DepartmentImportModal handleClose={() => setOpenImportDep(false)} handleImport={handleImportDepartment} />
      )}
      {openErrModal && (
        <ImportErrorModal
          title={title}
          errData={errorData}
          successData={successData}
          handleClose={() => {
            setOpenErrModal(false);
            setErrorData([]);
            setsuccessData([]);
            setTitle("");
          }}
        />
      )}
      {openDepartmentModal && (
        <DepartmentModal
          handleSubmit={createDepartment}
          show={openDepartmentModal}
          handleClose={() => {
            setOpenDepartmentModal(false);
            setSelectedDepartment(null);
          }}
          selectedDepartment={selectedDepartment}
          departments={departments}
        />
      )}
      {inConsistentCallTimes.employees.length > 0 && (
        <InconsistentCallTimeModal
          data={inConsistentCallTimes}
          handleClose={(action) => {
            if (action) {
              appContext.resetEmployees();
              appContext.showSuccessMessage("Department call time updated successfully");
            }
            setInConsistentCallTimes({ employees: [] });
          }}
        />
      )}
      {showOptions && (
        <DepartmentOptionsModal
          departments={departments}
          handleSave={updateSelectedCallTime}
          handleClose={() => setShowOptions(false)}
        />
      )}
      {showGeneralTime && (
        <GeneralDeptTime handleSave={updateGeneralTime} handleClose={() => setShowGeneralTime(false)} client={client} />
      )}
    </div>
  );
};

export default Departments;
