import React, { useState, useContext, useRef } from "react";
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { Link } from "react-router-dom";
import { AppContext } from "../context/app-context";
import Filter from "../components/Filter";
import HeaderItem from "components/Table/HeaderItem";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import {
  DAYS_LABEL,
  EmptyUserObj,
  PAGE_LIMIT,
  SCHEDULE_TYPES,
} from "../constant";
import { formatDateMDY, setPhoneNo, calculateTdWidth } from "../utils";
import MFPagination from "components/Pagination/MFPagination";
import Loader from "components/Loader/Loader";
import "../../src/tableStyling.css";
import ScheduleModal from "components/Modal/ScheduleModal";
import editIcon from '../assets/img/edit-icon.png';
import trashIcon from '../assets/img/trash-icon.png';

const QuestionSchedules = (props) => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 900, 6);

  const [schedules, setSchedules] = useState([]);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [newItem, setNewItem] = useState({ scheduleName: "" });
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [activeProgEmp, setActiveProgEmp]= useState([]);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [fullscreen, setFullscreen] = useState(true);
  const searchTerms = ["Schedule Name", "Schedule Type", "Days of Weeks", "Start Date", "End Date"];
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [departments, setDepartments] = useState([]);

  const sortData = (schs, sortParam) => {
    if (sortDescending) {
      return [...schs].sort((a, b) =>
        a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0
      );
    } else {
      return [...schs].sort((a, b) =>
        b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0
      );
    }
  };
  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartments(data);
  };
  useEffect(()=>{
    getDepartments();
  },[])
 
  const filterDays = (val) => {
    if (!val) return "-";
    const arr = val.split(",");
    return arr;
  };
  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if(key === "days"){
          return obj.days && filters[key].every((e)=> filterDays(obj.days).includes(e))
        }
        if(key === "startDate"){
          return new Date(obj.startDate) > filters[key].startDate._d && new Date(obj.startDate) < filters[key].endDate._d;
        }
        if(key === "endDate"){
          return new Date(obj.endDate) > filters[key].startDate._d && new Date(obj.endDate) < filters[key].endDate._d;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const createSchedule = async (obj) => {
    try {
      setLoading(true);
      if (obj.isNew) {
        await api.createSchedule(obj);
        appContext.showSuccessMessage("Schedule created successfully");
      } else {
        const filterVal = appContext.employees.filter((f)=> f.isSchedule=== 1 && !f.testDone && !f.qaDone && f.scheduleLinked && f.scheduleLinked.includes(obj.id))
        const scheduledEmployees = appContext.employees.filter((f)=> f.scheduleLinked && f.scheduleLinked.includes(obj.id))
        await api.updateSchedule(obj);
        if(filterVal.length ===0){
          appContext.showSuccessMessage("Schedule updated successfully")
        }else{
          await api.updateEmployeeProgramState(filterVal, 1)
          const emps = scheduledEmployees.length > 1 ? 'employees' : 'employee';
            appContext.showSuccessMessage(`${scheduledEmployees.length} ${emps} assigned to this schedule have been updated`);
        }
        await appContext.resetEmployees();
      }
      await appContext.resetEmployees();
      await appContext.resetSchedules();
    } catch (error) {
      console.log("ERROR:Schedules-", error);
      appContext.showErrorMessage(error.message);
      setLoading(false);
      setOpenCreator(true);
    }
  };
  const handleConfirmUpdateSch = async (isConfirm)=>{
    setOpenPrompt(false);
    if(!isConfirm){
      appContext.showSuccessMessage("Update Schedule Successfully");
      return
    }
    try{
    await api.updateEmployeeProgramState(activeProgEmp, 1)
    appContext.showSuccessMessage("Successfully Updated")
    appContext.resetEmployees();
    }catch(err){
      appContext.showErrorMessage(err.message)
    }
  }

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const currentItems = (schs) => {
    const indexOfLast = currentPage * PAGE_LIMIT;
    const indexOfFirst = indexOfLast - PAGE_LIMIT;
    return schs.slice(indexOfFirst, indexOfLast);
  };

  let itemsToMap = currentItems(filteredSchedules);

  const getPageNumbers = (items) => {
    const pageNumbers =
      items.length > PAGE_LIMIT ? Math.ceil(items.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };
  const formatSchedule = () => {
    const data = appContext.employees
      .filter((f) => f.scheduleID)
      .reduce((obj, employee) => {
        let schIds = [employee.scheduleID];
        if(employee.scheduleLinked && employee.scheduleLinked.length > 0)
        {
          schIds = employee.scheduleLinked;
        }
        for(let index =0; index< schIds.length ; index++){
          obj[schIds[index]] = (obj[schIds[index]] || 0) + 1;
        }
        return obj;
      }, {});
    const schdata = appContext.schedules.map((sch) => {
      let ttlEmployees = data[sch.id] || 0;
      return {
        ...sch,
        schType: SCHEDULE_TYPES[sch.scheduleType],
        totalEmployees: ttlEmployees,
      };
    });
    const schfilter = schdata.filter((f)=> f.scheduleCategory==="Question")
    setSchedules(schfilter);
    setFilteredSchedules(schfilter);
  };
  useEffect(() => {
    formatSchedule();
    setLoading(false);
  }, [appContext.schedules]);

  useEffect(() => {
    if (filteredSchedules.length > 0)
      setFilteredSchedules(sortData(filteredSchedules, sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (schedules.length > 0) {
      setFilteredSchedules(nestedFilter(schedules, filter));
      setCurrentPage(1);
    }
  }, [filter]);

  let pageNumbers = getPageNumbers(schedules);
  if (filteredSchedules.length > 0) {
    pageNumbers = getPageNumbers(filteredSchedules);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleEdit = (item) => {
    const obj = { ...item, isNew: false};
    setNewItem(obj);
    setOpenCreator(true);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    console.log("[DELETE USER]", itemToDelete);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    const filterdata = appContext.employees.filter(
      (emp) => emp.scheduleID === itemToDelete.id
    );
    if (filterdata.length > 0) {
      appContext.showErrorMessage("This schedule is Assign to Employees");
      return;
    }
    try {
      setLoading(true);
      await api.deleteSchedule(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetSchedules();
      appContext.showSuccessMessage("Schedule deleted duccessfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const parseDays = (val) => {
    if (!val) return "-";
    const arr = val.split(",");
    return arr.map((x) => DAYS_LABEL[x]).join(",");
  };

  const TableRow = ({ item }) => {
    return (
      <tr key={item.username}>
        <td
          className="icon"
          style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}
        >
          <img src={editIcon} alt="edit icon" width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={item.id}
            onClick={(e) => handleEdit(item)}
          />
        </td>
        <td
          className="ellipsis"
          style={{ textAlign: "left" }}
          title={item.scheduleName}
        >
          {item.scheduleName}
        </td>

        <td
          style={{ textAlign: "left", textOverflow: "visible" }}
          title={item.schType}
        >
          {item.schType}
        </td>
        <td
          style={{ textAlign: "center", textOverflow: "visible" }}
          title={(item.startDate && formatDateMDY(item.startDate)) || "-"}
        >
          {(item.startDate && formatDateMDY(item.startDate)) || "-"}
        </td>
        <td
          style={{ textAlign: "center", textOverflow: "visible" }}
          title={(item.endDate && formatDateMDY(item.endDate)) || "-"}
        >
          {(item.endDate && formatDateMDY(item.endDate)) || "-"}
        </td>
        <td
          style={{ textAlign: "center", textOverflow: "visible" }}
          title={item.scheduleType == "everyday"? "Every":parseDays(item.days)}
        >
          {item.scheduleType == "everyday"? "Every":parseDays(item.days)}
        </td>
        <td
          style={{ textAlign: "center", textOverflow: "visible" }}
          title={item.totalEmployees}
        >
          {item.totalEmployees > 0 ? (
            <Link
              style={{ color: "#42cef5" }}
              to={{
                pathname: "/admin/employees",
                state: { name: item.id, term: "scheduleID" },
              }}
            >
              {item.totalEmployees}
            </Link>
          ) : (
            item.totalEmployees
          )}
        </td>
        <td
          className="icon"
          style={{ textAlign: "center", textOverflow: "visible" }}
        >
          <img src={trashIcon} alt="trash icon" width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={item.id}
            onClick={(e) => {
              setOpenDeleteConfirmation(true);
              setItemToDelete(item);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <div className="buttonHeader">
                  <Button
                    className="headerButton btn-fill" variant="primary"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    Filter
                  </Button>
                  <Button
                    className="headerButton btn-fill" variant="secondary"
                    onClick={() => {
                      setNewItem({ scheduleName: "", isNew: true });
                      setOpenCreator(true);
                    }}
                  >
                    Create a Schedule
                  </Button>
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div className={filteredSchedules.length> 0? "table-responsive pendingReleaseTable":"table-responsive pendingReleaseTable overFlow-y-hidden"}>
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="scheduleName"
                          title="Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="schType"
                          title="Type"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="startDate"
                          title="Start Date"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="endDate"
                          title="End Date"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="days"
                          title="Days of week"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                         width={tdWidth}
                          ItemKey="totalEmployees"
                          title="Total Employees"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th
                          style={{ textAlign: "center",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsToMap &&
                        itemsToMap.map((item, i) => {
                          return <TableRow key={i} item={item} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {openCreator && (
        <ScheduleModal
          item={newItem}
          handleClose={handleClose}
          handleSave={createSchedule}
          scheduleCategory="Question"
          schedules={appContext.schedules}
        />
      )}
      {openPrompt && (
        <ConfirmationModal
          show={openPrompt}
          title="Update Schedule"
          handleClose={()=>handleConfirmUpdateSch(false)}
          closeBtn={true}
          message={`${activeProgEmp.length} ${activeProgEmp.length >1 ? "Employees have": "Employee has"} active program against this schedule, would you like to update their schedule?`}
          handleConfirm={handleConfirmUpdateSch}
        />
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Schedule"
          message="Are you sure, you want to remove Schedule?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default QuestionSchedules;
