import moment from "moment";
import React, { useState, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { onBoardingTest } from "utils";
import SchedulesDetails from "./SchedulesDetails";
const PopoverStick = (props) => {
  const { item, user, itemKey } = props;
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef();
  let setTimeoutConst = null;
  let day = "";
  let test = [];
  let days = [0, 1, 2, 3, 4, 5, 6];
  const questionOption = user.schedulesOptions?.questionOpt || [];
  const testOption = user.schedulesOptions?.testOpt || [];
  const handleMouseEnter = () => {
    if (user.scheduleName) {
      setTimeoutConst = setTimeout(() => {
        setShowPopover(true);
      }, 1000);
    }
  };
  if (itemKey === "tests" && user.testNow && user.onBoardingTesting) {
    const testData = onBoardingTest(user.onBoardingTesting);
    days = user.tests ? [parseInt(moment(testData[0].date).format("d"))] : [];
  } else if (itemKey === "tests") {
    days = user.tests ? [parseInt(moment().format("d"))] : [];
  }
  
  const userTests = [...user.testOne || [], ...user.testTwo || []];
  if (user.testNow) {
    test = userTests.filter((t) => t.testNow);
    const testData = onBoardingTest(user.onBoardingTesting);
    day = moment(testData[0].date).format("ddd").toUpperCase();
  }else{
    test = userTests.filter((t) => !t.testNow);
  }

  if (user.testDone === "1" || user.testDone === "X") {
    day = moment(user.logDate?.substring(0, 10)).format("ddd").toUpperCase();
  }

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const formatTitle = (testSchHeader) => {
    return testSchHeader ? testSchHeader : "Expired";
  };

  const popOverHeaderFunc = () => {
    if (Object.keys(user.schedulesOptions).length > 0) {
      const { questionSch, testOptSch } = user.schedulesOptions;
      let testSchHeader = "";
      let questSchHeader = "";
      if (questionSch && moment().startOf("day").isSameOrBefore(questionSch.endDate)) {
        questSchHeader = ` ends on ${moment(questionSch.endDate).format("MMM DD, YYYY")}`;
      }
      if (testOptSch && moment().startOf("day").isSameOrBefore(testOptSch.endDate)) {
        testSchHeader = ` ends on ${moment(testOptSch.endDate).format("MMM DD, YYYY")}`;
      }
      return (
        <>
          {testOptSch && <div>{`${testOptSch.scheduleName}  ${formatTitle(testSchHeader)}`}</div>}
          {questionSch && <div>{`${questionSch.scheduleName}  ${questSchHeader ? questSchHeader : "Expired"} `}</div>}
        </>
      );
    } else {
      return <div>{user.scheduleName}</div>;
    }
  };
  return (
    <>
      <td
        className="ellipsis"
        ref={childNode}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setShowPopover(true)}
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
          cursor: "pointer",
        }}
      >
        {itemKey === "tests" ? user.tests : user.scheduleName}
      </td>
      <Overlay show={showPopover} placement="auto" flip target={childNode.current}>
        <Popover
          onMouseEnter={() => {
            setShowPopover(true);
          }}
          onMouseLeave={handleMouseLeave}
          id="popover"
          className="popOverSchedule"
        >
          <Popover.Header className="fw-bold">
            {" "}
            {user.testNow
              ? `Test Now ${moment(onBoardingTest(user.onBoardingTesting)[0].date).format("MMM DD, YYYY")}`
              : popOverHeaderFunc()}
          </Popover.Header>
          {(questionOption.length > 0 || testOption.length > 0 ||(userTests.length > 0 && itemKey === "tests")) && (
            <SchedulesDetails
              questionOption={questionOption}
              testOption={testOption}
              day={day}
              days={days}
              test={test}
            />
          )}
        </Popover>
      </Overlay>
    </>
  );
};

export default PopoverStick;
