import React, { useEffect, useState } from "react";
import { Button, Modal, Card, Table, Row, Col, Form } from "react-bootstrap";
import API from "api";
import moment from "moment";
import ErrorMessage from "components/Message/ErrorMessage";

const InconsistentCallTimeModal = ({ data, handleClose }) => {
  const [users, setUsers] = useState(data.employees);
  const [callTime, setCallTime] = useState(data.callTime);
  const [checkboxes, setCheckboxes] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setUsers(data.employees);
    setCallTime(data.callTime);
  }, [data.employees]);

  const toggleCheckboxes = (val) => setCheckboxes(val ? users.map((t) => t.id) : []);

  const handleCheckboxChange = (e, id) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e.target.checked) {
      filteredList.push(id);
    }
    setCheckboxes(filteredList);
  };

  const onChangeType = (e) => setSelectedOption(e.target.value);

  const handleSaveChange = async () => {
    if (!selectedOption) {
      setError("Please select the option");
      return;
    }
    if (checkboxes.length === 0) {
      setError("Please select the employee");
      return;
    }
    const newList = users.filter((test) => !checkboxes.includes(test.id));
    if (selectedOption === "new") {
      const empIds = users
        .filter((test) => checkboxes.includes(test.id))
        .map((e) => {
          return { id: e.id, callTime: data.newCallTime };
        });
      await API.updateEmployeeCallTime(empIds);
    } else if (selectedOption === "relative") {
      const empIds = users
        .filter((test) => checkboxes.includes(test.id))
        .map((e) => {
          const deptTime = moment(`${moment().format("YYYY-MM-DD")} ${data.newCallTime}`);
          return { id: e.id, callTime: deptTime.add(e.diff, "minutes").format("HH:mm") };
        });
      await API.updateEmployeeCallTime(empIds);
    }
    if (newList.length === 0) {
      handleClose(true);
      return;
    }
    setUsers(newList);
    setCheckboxes([]);
  };

  const getTimeOffset = (user) => {
    const deptTime = moment(callTime);
    if (user.callTime && user.diff !== 0) {
      const diff = user.diff;
      const hours = diff > 0 ? Math.floor(diff / 60) :Math.ceil(diff / 60);
      const mins = `${diff > 0 ? "+" : ""}${diff % 60} mins`;
      let response = "";
      if (hours === 0) {
        response = mins;
      } else if (hours > 0) {
        response = hours === 1 ? `+${hours} hour ${mins}` : `+${hours} hours ${mins}`;
      } else {
        response = hours === -1 ? `${hours} hour` : `${hours} hours ${mins}`;
      }

      return <span class={diff < 0 ? "colorRed" : "colorGreen"}>{response}</span>;
    }
    return "-";
  };

  const TableRow = ({ user, index }) => {
    return (
      <tr key={index}>
        <td style={{ width: "10%" }}>
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              value={user.id}
              checked={checkboxes.indexOf(user.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, user.id)}
            ></Form.Check.Input>
          </Form.Check>
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.firstName}>
          {user.firstName}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.lastName}>
          {user.lastName}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.callTime}>
          {user.callTime}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.callTime}>
          {getTimeOffset(user)}
        </td>
      </tr>
    );
  };
  return (
    <Modal show animation={true} onHide={() => handleClose(false)} centered size={"xl"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Employee Call Time Change
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0">
        <div style={{ flex: 1 }}>
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Row>
                    <Card.Title
                      as="h4"
                      style={{
                        marginBottom: 10,
                        fontWeight: "bold",
                        color: "#565656",
                      }}
                    >
                      We have found the below employees have unique calls times that are not consistent with the
                      department setting. What would you like to do?
                    </Card.Title>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <div class="inconsistant-radio-wrapper">
                    <div className="form-group-wrapper">
                      <Form.Check>
                        <Form.Check.Input
                          id="new"
                          value="new"
                          name="resultGroupList"
                          type="radio"
                          checked={selectedOption === "new"}
                          onChange={onChangeType}
                        />
                        <Form.Check.Label htmlFor="new" className="px-2 scheduleLabel">
                          Update with New Time
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="form-group-wrapper">
                      <Form.Check>
                        <Form.Check.Input
                          id="current"
                          value="current"
                          name="resultGroupList"
                          type="radio"
                          checked={selectedOption === "current"}
                          onChange={onChangeType}
                        />
                        <Form.Check.Label htmlFor="current" className="px-2 scheduleLabel">
                          Keep Current Times
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="form-group-wrapper">
                      <Form.Check>
                        <Form.Check.Input
                          id="relative"
                          value="relative"
                          name="resultGroupList"
                          type="radio"
                          checked={selectedOption === "relative"}
                          onChange={onChangeType}
                        />
                        <Form.Check.Label htmlFor="relative" className="px-2 scheduleLabel">
                          Adjust Relative to Offset
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  </div>

                  <div className="buttonHeader">
                    <Button
                      className="headerButton"
                      onClick={() => {
                        if (users && users.length > 0) {
                          setAllSelected(!allSelected);
                          toggleCheckboxes(!allSelected);
                        }
                      }}
                    >
                      {!allSelected ? "Select All" : "Deselect All"}
                    </Button>
                  </div>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive" style={{ overflow: "hidden" }}>
                      <Table className="table-hover table-striped">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>#</th>
                            <th className="ellipsis centered" style={{ textAlign: "center" }}>
                              First Name
                            </th>
                            <th className="ellipsis centered" style={{ textAlign: "center" }}>
                              Last Name
                            </th>
                            <th className="ellipsis centered" style={{ textAlign: "center" }}>
                              Call Time
                            </th>
                            <th className="ellipsis centered" style={{ textAlign: "center" }}>
                              Time Offset
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.length > 0 &&
                            users.map((user, index) => <TableRow user={user} index={index} key={index} />)}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={() => handleClose(false)}>
          Cancel
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={handleSaveChange}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InconsistentCallTimeModal;
