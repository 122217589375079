import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth } from "utils";
import { DAYS } from "constant";
import moment from "moment";
import { removeSpaceIns, formatDateMatrix } from "utils";
import Loader from "components/Loader/Loader";
import MatrixView from "components/MatrixView";

const ZoneMatrix = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [programData, setProgramData] = useState({});
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [dateList, setDateList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  // useEffect(() => {
  //   setWidth(componentRef.current.offsetWidth);
  // }, [componentRef]);

  // const handleResize = () => {
  //   if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  // };
  // useEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // });
  // useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = 50;

  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartments(data);
  };

  useEffect(() => {
    setLoading(true);
    setDateList(getDaysArray(moment().startOf("day"), moment().add(13, "days").format("YYYY-MM-DD")));
    getDepartments();
  }, []);

  // useEffect(async () => {
  //   setLoading(true);
  //   if (!showData) {
  //     setDateList(getDaysArray(moment().startOf("day"), moment().add(13, "days").format("YYYY-MM-DD")));
  //     await uniquePrograms();
  //   } else {
  //     setDateList(getDaysArray(moment().subtract(14, "days"), moment().format("YYYY-MM-DD")).reverse());
  //     await getLogData();
  //   }
  //   setLoading(false);
  // }, [appContext.employees, appContext.schedules, showData]);
  const getLogData = async () => {
    let rows = [];
    let dataLogs = [];
    try {
      dataLogs = await API.getAllEmployeeDailyLogs();
    } catch (err) {
      console.log("Error:- dailyLogs", err.message);
    }
    console.log("dataLogs", dataLogs);
    const empTtl = appContext.employees?.length;
    const logsTtl = dataLogs.length;
    const startDate = moment().subtract(14, "days").startOf("day").toDate();
    const endDate = moment().endOf("day").toDate();
    for (let i = 0; i < empTtl; i++) {
      const emp = appContext.employees[i];
      let dataFilterlist = [];
      for (let j = 0; j < logsTtl; j++) {
        const f = dataLogs[j];
        const isDateRange =
          new Date(f.logDate?.substring(0, 10)) > startDate && new Date(f.logDate?.substring(0, 10)) < endDate;
        const testType = [...(f.testOne || []), ...(f.testTwo || [])];
        if (f.employeeID === emp.id && isDateRange && testType.length > 0) {
          dataFilterlist.push({ ...f, date: f.logDate?.substring(0, 10), testType });
        }
      }
      console.count("dataFilter", dataFilterlist);
      if (dataFilterlist.length > 0) {
        rows.push({ ...emp, scheduleData: dataFilterlist });
      }
    }
    console.log("rows", rows);
    const prevListData = rows.reduce((obj, user) => {
      if (obj[user.department] && user) {
        obj[user.department].push(user);
      } else if (user) {
        obj[user.department] = [user];
      }
      return obj;
    }, {});
    console.log("prevListData", prevListData);
    setProgramData(prevListData);
    setFilteredPrograms(Object.keys(prevListData));
  };

  const getDaysArray = (startDate, endDate) => {
    const now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.add(1, "days");
    }
    return dates;
  };
  const getEmployeeProgramDetails = (emp) => {
    const clone = { ...emp };
    if (appContext.programs && clone.programID) {
      const empProgram = appContext.programs.find((s) => s.id === clone.programID);
      if (empProgram && empProgram.testValidity) {
        console.log("empSch", empProgram);

        const DateList = getDaysArray(
          moment(empProgram.updatedAt?.substring(0, 10)).startOf("day"),
          moment().add(13, "days").format("YYYY-MM-DD")
        );
        const dataList = [];
        DateList.forEach((d) => {
          empProgram.testValidity.forEach((t) => {
            const isTest = dataList.findLast((f) => f.value === t.value);
            if (dataList.length > 0 && isTest) {
              const newDate = moment(isTest.date).add(parseInt(t.days), "days").format("YYYY-MM-DD");
              dataList.push({ ...t, date: newDate });
            } else {
              dataList.push({ ...t, date: empProgram.updatedAt?.substring(0, 10) });
            }
          });
        });
        console.log("schTestTypes", dataList);
        Object.assign(clone, {
          scheduleData: dataList,
        });
        return clone;
      }
    }
  };

  const uniquePrograms = useMemo(() => {
    return appContext.employees
      .filter((f) => f.programID)
      .reduce((obj, user) => {
        const userObj = getEmployeeProgramDetails(user);
        if (obj[user.department] && obj[user.department][user?.programName] && userObj) {
          console.log("LOGs Future");
          obj[user.department][user?.programName].push(userObj);
        } else if (obj[user.department] && user?.programName && userObj) {
          Object.assign(obj[user.department], { ...obj[user.department], [user.programName]: [userObj] });
        } else if (userObj) {
          obj[user.department] = { [user.programName]: [userObj] };
        }
        return obj;
      }, {});
  }, [appContext.employees, appContext.programs]);

  useEffect(() => {
    setFilteredPrograms(Object.keys(uniquePrograms));
    setProgramData(uniquePrograms);
    if (uniquePrograms) setLoading(false);
  }, [uniquePrograms]);
  console.log("programData", programData);

  const handleConfirmDelete = async (isConfirm) => {
    console.log("[DELETE USER]", itemToDelete);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await API.deleteProgramSettings(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetPrograms();
      appContext.showSuccessMessage("program deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };
  const TableRow = ({ item }) => {
    const list = programData[item];
    const dptName = departments.find((f) => f.id === item)?.name;
    const [open, setOpen] = useState(false);
    const programLists = { ...list };
    const programGroups = Object.keys(list);
    const handleOpenDpt = () => {
      setOpen(!open);
    };
    return (
      <>
        <tr className="tcMatrixRows">
          <td
            colSpan={"21"}
            className={`ellipsis ${open ? "activeDepartment" : ""}`}
            style={{
              textAlign: "left",
              textOverflow: "visible",
              width: tdWidth,
              maxWidth: tdWidth,
              paddingLeft: "8px",
            }}
          >
            <div className="d-flex align-items-center">
              <i
                onClick={() => handleOpenDpt()}
                onMouseLeave={(e) => e}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                className={`tcMatrixPlusIcon ${open ? "fas fa-minus me-3 activeProgram" : "fas fa-plus me-2"}`}
              />
              <div className="ms-4">
                <div>
                  <strong className="me-2 departmentName">Department Name</strong>
                </div>
                <span className="fw-bolder">{dptName}</span>
              </div>
              <div className="ms-auto px-3">
                <div>
                  <span className="px-3 text-muted">Program</span>
                </div>
                <strong className="text-danger ps-3">{programGroups?.length || ""}</strong>
              </div>
            </div>
          </td>
        </tr>
        {open && programLists && (
          <tr className="matrixListData">
            <td colSpan={"21"} className="expended-table-wrapper">
              <div className="form-group-wrapper d-block">
                <div className="table-responsive overFlow-y-hidden"></div>
                <Table>
                  <tbody>
                    {programGroups.length > 0 &&
                      programGroups.map((item, i) => {
                        return (
                          <MatrixView
                            key={i}
                            index={i}
                            prog={item}
                            programData={programLists}
                            dateList={dateList}
                            tdWidth={tdWidth}
                            showData={showData}
                          />
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </td>
          </tr>
        )}
        {/* <tr>
          <td
            className="ellipsis"
            style={{
              textAlign: "left",
              textOverflow: "visible",
              width: tdWidth,
              maxWidth: tdWidth,
              paddingLeft: "8px",
            }}
            title={dptName}
          >
            {dptName}
          </td>
          <td colSpan={"19"}>
            {" "}
            {list.map((emp) => {
              const zoneColor = appContext.programs.find((f) => f.name === emp.programName)?.color;
              return (
                <Table className="ProgramTestTypeTable">
                  <tbody>
                    <tr key={emp.id} className="programSchedules">
                      <td
                        className="ellipsis"
                        style={{
                          width: tdWidth,
                          maxWidth: tdWidth,
                        }}
                        title={emp.programName}
                      >
                        <div
                          className="programColor mx-1"
                          style={{ width: "10px", height: "10px", backgroundColor: `${removeSpaceIns(zoneColor)}` }}
                        ></div>
                        {emp.programName || "-"}
                      </td>
                      <td
                        className="ellipsis"
                        style={{ textAlign: "center", width: tdWidth, maxWidth: tdWidth }}
                        title={emp.firstName}
                      >
                        {emp.firstName}
                      </td>
                      <td
                        className="ellipsis"
                        style={{ textAlign: "center", width: tdWidth, maxWidth: tdWidth }}
                        title={emp.lastName}
                      >
                        {emp.lastName}
                      </td>
                      <td
                        className="ellipsis"
                        style={{
                          textDecoration: "underline",
                          color: "#A82632",
                          textAlign: "center",
                          width: tdWidth,
                          maxWidth: tdWidth,
                        }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.textDecoration = "none";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.textDecoration = "underline";
                        }}
                        onClick={() => emp.email && window.open(`mailto:${emp.email}`)}
                        title={emp.email}
                      >
                        {emp.email}
                      </td>
                      <td
                        className="ellipsis"
                        style={{
                          textDecoration: "underline",
                          color: "#A82632",
                          textAlign: "center",
                          width: tdWidth,
                          maxWidth: tdWidth,
                        }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.textDecoration = "none";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.textDecoration = "underline";
                        }}
                        onClick={() => emp.phoneNumber && window.open(`tel:+${emp.phoneNumber.replace(/\D/g, "")}`)}
                        title={emp.phoneNumber}
                      >
                        {emp.phoneNumber}
                      </td>
                      {dateList.map((d) => {
                        const dayOpt = emp.scheduleData?.filter((f) => f.date === d);
                        const testType = dayOpt.length > 0 ? dayOpt.map((d) => d.label) : [];
                        return (
                          <td
                            className="ellipsis"
                            style={{ textAlign: "center", width: tdWidth, maxWidth: tdWidth }}
                            title={dayOpt ? testType.join() : "-"}
                          >
                            {dayOpt ? testType.join() : "-"}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </Table>
              );
            })}
          </td>
        </tr> */}
      </>
    );
  };
  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  TC Matrix ({filteredPrograms.length})
                </Card.Title>
                <div className="buttonHeader">
                  {/* <Button variant="secondary" className="headerButton btn-fill" onClick={() => setShowData(!showData)}>
                    {!showData ? "Show Previous Date" : "Show Future Date"}
                  </Button> */}
                </div>
              </Card.Header>
              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div
                  className={
                    filteredPrograms.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table programsViewTable">
                    {/* <thead ref={componentRef}>
                      <tr>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                            paddingLeft: "8px",
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Department">Department</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                            textAlign: "center",
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Program">Program</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                        >
                          <div className="centered">
                            <span title="First Name">First Name</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                        >
                          <div className="centered">
                            <span title="Last Name">Last Name</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                        >
                          <div className="centered">
                            <span title="Email">Email</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                            textAlign: "center",
                          }}
                          className="border-0 centered"
                        >
                          <div className="centered">
                            <span title="Phone">Phone</span>
                          </div>
                        </th>
                        {dateList &&
                          dateList.map((d) => {
                            return (
                              <th
                                style={{
                                  color: "grey",
                                  fontWeight: "bold",
                                  width: tdWidth,
                                  maxWidth: tdWidth,
                                }}
                                className="border-0 centered"
                              >
                                <div className="centered">
                                  <span title={`${formatDateMatrix(d)}`}>{`${formatDateMatrix(d)}`}</span>
                                </div>
                              </th>
                            );
                          })}
                      </tr>
                    </thead> */}
                    <tbody>
                      {filteredPrograms.length > 0 &&
                        filteredPrograms.map((item, i) => {
                          return <TableRow key={i} item={item} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete programSettings"
              message="Are you sure, you want to remove program on settings?"
              handleConfirm={handleConfirmDelete}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ZoneMatrix;
