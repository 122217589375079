import React, {useMemo } from "react";
import {Form} from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";
import { TEST_TYPES, SCHEDULE_TYPE_EVERY_DAY } from "constant";
import RadioButton from "../RadioButton/RadioButton";
import CheckBoxButton from "../CheckBoxButton/CheckBoxButton";

const ScheduleItem = (props) => {
  const {  obj, handleSaveChange, scheduleCategory, optionType, SetMessagePopup, setMessage } = props;
  

  const optionTestType = useMemo(() => {
    return TEST_TYPES.filter((fl) => (!obj.testType?.find((f) => f.value === fl.value) ? true : false));
  }, [obj]);

  const handleChangeDays = (e) => {
    handleSaveChange({ ...obj, isChecked: e.target.checked });
  };
  
  const handleChangeTestType = (testTypeData, index) => {
    const testTypeLoc = {...testTypeData};
    if(testTypeLoc.value && testTypeLoc.value !== "Antigen" && testTypeLoc.value !== "Lucira" && testTypeLoc.location && testTypeLoc.location === "At Home"){
      setMessage(`${testTypeLoc.label} only be done on location`)
      SetMessagePopup(true)
      Object.assign(testTypeLoc, {...testTypeLoc, location: "On Location"})
    }
    const customTestType = [...obj.testType];
    customTestType.splice(index, 1, testTypeLoc);
    handleSaveChange({ ...obj, testType: customTestType });
  };
  
  const handleRemove = (index) => {
    const customTestType = [...obj.testType];
    customTestType.splice(index, 1);
    handleSaveChange({ ...obj, testType: customTestType });
  };

  const handleAdd = () => {
    const customTestType = [...obj.testType];
    if (obj.testType.length < TEST_TYPES.length) {
      customTestType.push({ value: "", label: "", location: "" ,  isDone: false, scheduleCategory: "Testing", result:""});
      handleSaveChange({ ...obj, testType: customTestType });
    }
  };

  const TestTypes = (props) => {
    const { testType, handleChange, optionTestType, index } = props;
    const testTypesOpt = { ...testType };
    const name = Math.random();
    return (
      <>
        <div className="col-12 col-sm-4 col-md-4">
          <Select
            options={optionTestType}
            blurInputOnSelect={true}
            value={TEST_TYPES.find((t) => testTypesOpt.value === t.value)}
            menuPlacement="auto"
            placeholder={"Select Test Type"}
            onChange={(e) => {
              handleChange({...testTypesOpt, value: e.value, label: e.label }, index);
            }}
          />
        </div>
        <div className="col-sm-4 col-md-4 radioButtonSec">
          <div className="d-flex align-items-center column-gap-10">
            <RadioButton
                label="On Location"
                id={`${Math.random() * 2}`}
                checked={testTypesOpt.location === "On Location"}
                name={`${testTypesOpt.value}_${name}`}
                handleChange={(e) => {
                  handleChange({...testTypesOpt, location: e.target.checked && "On Location" }, index);
                }}
            />
            <RadioButton
                label="At Home"
                id={`${Math.random() * 3}`}
                checked={testTypesOpt.location === "At Home"}
                name={`${testTypesOpt.value}_${name}`}
                handleChange={(e) => {
                  handleChange({...testTypesOpt, location: e.target.checked && "At Home" }, index);
                }}
            />
          </div>
          {/*<Form.Check.Input*/}
          {/*  type="radio"*/}
          {/*  className="m-2"*/}
          {/*  checked={testTypesOpt.location === "On Location"}*/}
          {/*  name={`${testTypesOpt.value}_${name}`}*/}
          {/*  onChange={(e) => {*/}
          {/*    handleChange({...testTypesOpt, location: e.target.checked && "On Location" }, index);*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<label className="dayLable px-2">On Location</label>*/}

          {/*<Form.Check.Input*/}
          {/*  type="radio"*/}
          {/*  className="m-2"*/}
          {/*  checked={testTypesOpt.location === "At Home"}*/}
          {/*  name={`${testTypesOpt.value}_${name}`}*/}
          {/*  onChange={(e) => {*/}
          {/*    handleChange({...testTypesOpt, location: e.target.checked && "At Home" }, index);*/}
          {/*  }}*/}
          {/*/>*/}
          {/*<label className="dayLable px-2">At Home</label>*/}
        </div>
      </>
    );
  };
  return (
      <>
    <div className="position-relative row flex-column flex-sm-row align-items-start align-items-sm-center flex-nowrap mb-2 px-3 px-sm-0">
      <div className="day col-12 col-sm-3 col-md-3 py-2">
        <div className="ml-left-desktop">
          <CheckBoxButton
              label={obj.name}
              id={obj.id}
              value={obj.id}
              checked={obj.isChecked}
              name={obj.name}
              disabled={optionType === SCHEDULE_TYPE_EVERY_DAY}
              handleChange={handleChangeDays}
          />

          {/*<Form.Check.Input*/}
          {/*    type="checkbox"*/}
          {/*    className="m-2"*/}
          {/*    id={obj.id}*/}
          {/*    value={obj.id}*/}
          {/*    checked={obj.isChecked}*/}
          {/*    name={obj.name}*/}
          {/*    disabled={optionType === SCHEDULE_TYPE_EVERY_DAY}*/}
          {/*    onChange={handleChangeDays}*/}
          {/*/>*/}
          {/*<label htmlFor={obj.id} className="dayLable px-2">*/}
          {/*  {obj.name}*/}
          {/*</label>*/}
        </div>
      </div>

      {obj.isChecked &&
      scheduleCategory === "Testing" &&
          <>
      <TestTypes
          testType={{...obj.testType[0]}}
          index={0}
          handleChange={handleChangeTestType}
          optionTestType={optionTestType}
      />
        <div className="col-md-1 addRemoveSec">
        {obj.testType.length > 1 && <i className="fas fa-trash m-auto testTypeAction" onClick={() => handleRemove(0)} />}
        <i className="fas fa-plus m-auto testTypeAction" onClick={() => handleAdd()} />
        </div>
      </>
      }
    </div>

  {obj.isChecked &&
  scheduleCategory === "Testing" &&
  obj.testType?.map((t, index) => (
      <>
        {index > 0 &&(
            <div className="row px-3 px-sm-0">
              <div className="col-md-12 mb-2">
                <div className="row position-relative align-items-start align-items-sm-center flex-column flex-sm-row flex-nowrap">
                  <div className="day col-md-3 py-2" />
                  <TestTypes
                      testType={{ ...t }}
                      index={index}
                      handleChange={handleChangeTestType}
                      optionTestType={optionTestType}
                  />
                  <div className="col-sm-2 col-md-1 addRemoveSec trashIconTestType">
                    <i className="fas fa-trash m-auto testTypeAction" onClick={() => handleRemove(index)} />
                  </div>
                </div>
              </div>
            </div>
        )}
      </>
  ))}
  </>
  );
};

export default ScheduleItem;
