import React from "react";
import { removeSpaceIns } from "utils";
const ColorLabel = (props) => {
  const { color, label } = props;
  return (
    <div className="d-flex align-items-center justify-content-between">
      {label && <span>{label || color}</span>}
      <div style={{ width: "10px", height: "10px", backgroundColor: `${removeSpaceIns(color)}` }}></div>
    </div>
  );
};

export default ColorLabel;
