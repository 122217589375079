import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "../../context/app-context";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";
import { TEST_TYPES } from "constant";
import moment from "moment";
import ErrorMessage from "components/Message/ErrorMessage";
import TextAreaMessageInput from "components/TextInput/TextAreaMessageInput";
import RadioButton from "../RadioButton/RadioButton";
import { v4 as uuidv4 } from "uuid";

const TestNowModal = (props) => {
  const { handleClose, handleSave, company } = props;
  const [assignTest, setAssignTest] = useState({
    testNowVerbiage: company.isTestNowVerbiage ? company.testNowVerbiage : "",
    location: "At Home",
    date: moment().format("YYYY-MM-DD"),
    overWrite: "No",
  });
  const [error, setError] = useState("");
  console.log("assignTest", assignTest, company);
  const onHandleSave = () => {
    if (!assignTest) {
      setError("empty fields");
      return;
    }
    if (!assignTest.testType || assignTest.testType.length === 0) {
      setError("please select test type");
      return;
    }
    if (!assignTest.date) {
      setError("please select date");
      return;
    }
    if (!moment(assignTest.date, "YYYY-MM-DD", true).isValid()) {
      setError("invalid date");
      return;
    }
    if (!assignTest.time) {
      setError("please select time");
      return;
    }
    const dateTime = `${assignTest.date}T${assignTest.time}`;
    const testNowData = [];
    assignTest.testType.forEach((t, i) => {
      testNowData.push({
        ...assignTest,
        id: uuidv4(),
        testType: t,
        label: assignTest.label[i],
        isDone: false,
        result: "",
        value: t,
        dateTime,
        testNow: true,
        testDate: moment().utc().toISOString(),
      });
    });
    console.log("assigntest", testNowData);
    handleSave(testNowData);
  };

  const onHandleMessageChange = (msg, key) => {
    const obj = { ...assignTest };
    Object.assign(obj, { testNowVerbiage: msg });
    setAssignTest(obj);
  };
  return (
    <>
      <Modal show onHide={handleClose} animation={true} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Test Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="createClientsWrapper flex-wrap">
            <div className="first-last-name-wrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">Select Test Type: </label>
                <Select
                  options={TEST_TYPES}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  isMulti
                  className="assignSchedule"
                  placeholder="Test Type"
                  onChange={(e) =>
                    setAssignTest({ ...assignTest, testType: e.map((t) => t.value), label: e.map((t) => t.label) })
                  }
                />
              </div>
              <div className="form-group-wrapper selectDateTime">
                <label className="modalLineHeaders mt-0">Select Date: </label>
                <input
                  type="date"
                  placeholder="Select Date"
                  className="dateRangeInput"
                  defaultValue=""
                  min={moment().format("YYYY-MM-DD")}
                  value={assignTest.date}
                  onChange={(e) => setAssignTest({ ...assignTest, date: e.target.value })}
                />
              </div>
            </div>
            <div className="first-last-name-wrapper">
              <div className="form-group-wrapper selectDateTime">
                <label className="modalLineHeaders mt-0">Select Time: </label>
                <input
                  type="time"
                  placeholder="Select Time"
                  className="dateRangeInput"
                  defaultValue=""
                  onChange={(e) => setAssignTest({ ...assignTest, time: e.target.value })}
                />
              </div>
              <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Select Location:</label>
                  <div className="d-flex assignSchedule align-items-center justify-content-start justify-content-sm-between column-gap-15">
                    <RadioButton
                      value="On Location"
                      label="On Location"
                      checked={assignTest.location === "On Location"}
                      name="on_location"
                      id="location"
                      handleChange={(e) => setAssignTest({ ...assignTest, location: e.target.value })}
                    />
                    <RadioButton
                      value="At Home"
                      label="At Home"
                      checked={assignTest.location === "At Home"}
                      name="at_home"
                      id="atHome"
                      handleChange={(e) => setAssignTest({ ...assignTest, location: e.target.value })}
                    />
                  </div>
                </div>
            </div>
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Replace Scheduled Tests: </label>
                  <div className="d-flex assignSchedule align-items-center column-gap-15 justify-content-sm-between justify-content-start">
                    <RadioButton
                      label="Yes"
                      checked={assignTest.overWrite === "Yes"}
                      name="overWrite"
                      id="writing_yes"
                      handleChange={(e) => {
                        setAssignTest({ ...assignTest, overWrite: e.target.checked ? "Yes" : "" });
                      }}
                    />
                    <RadioButton
                      label="No"
                      checked={assignTest.overWrite === "No"}
                      name="overWrite"
                      id="writing_no"
                      handleChange={(e) => {
                        setAssignTest({ ...assignTest, overWrite: e.target.checked ? "No" : "" });
                      }}
                    />
                  </div>
                </div>
              </div>

            </div>
            <div className="w-100">
              <TextAreaMessageInput
                message={assignTest.testNowVerbiage}
                name="testNowVerbiage"
                label="Test Completed Verbiage"
                handleChange={onHandleMessageChange}
              />
              <div style={{ minHeight: "3vh" }}>
                {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TestNowModal;
