import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage";

const UpdateConfirmation = (props) => {
  const { handleClose, handleSave , settings } = props;
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const onChangeType = (e) => setSelectedOption(e.target.value);

  const handleSaveChange = () => {
    if (!selectedOption) {
      setError("Please select the option");
      return;
    }
    handleSave(selectedOption);
    handleClose();
  };

  return (
    <Modal className="updateConfirmation" show animation={true} onHide={() => handleClose()} centered size={"md"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Department Update Selection
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper">
          <Form.Check>
            <Form.Check.Input
              id="all"
              value="all"
              name="resultGroupList"
              type="radio"
              checked={selectedOption === "all"}
              onChange={onChangeType}
            />
            <Form.Check.Label htmlFor="all" className="px-2 scheduleLabel">
              Update All Employees
            </Form.Check.Label>
          </Form.Check>
        </div>
        {settings?.programLevel !== "Zone" &&
          <>
        <div className="form-group-wrapper">
          <Form.Check>
            <Form.Check.Input
              id="active"
              value="active"
              name="resultGroupList"
              type="radio"
              checked={selectedOption === "active"}
              onChange={onChangeType}
            />
            <Form.Check.Label htmlFor="active" className="px-2 scheduleLabel">
              Update Active Employees Only
            </Form.Check.Label>
          </Form.Check>
        </div>
        <div className="form-group-wrapper">
          <Form.Check>
            <Form.Check.Input
              id="inactive"
              value="inactive"
              name="resultGroupList"
              type="radio"
              checked={selectedOption === "inactive"}
              onChange={onChangeType}
            />
            <Form.Check.Label htmlFor="inactive" className="px-2 scheduleLabel">
              Update Inactive Employees Only
            </Form.Check.Label>
          </Form.Check>
        </div>
        </>
      }
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={handleSaveChange}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateConfirmation;
