import React, { useState } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse } from "react-bootstrap";
import { formatDateMDY, formatDateMDYTime, toTitleCase } from "utils";
import api from "api";
import { Audit_Types, Zone_Audit_Types } from "constant";
const AuditTrailEntries = (props) => {
  const { item, auditTrails, user, zoneLevel } = props;
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  console.log("auditTrails", auditTrails);
  const handleExpand = () => {
    const sortList = auditTrails.sort((a, b) => (b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0));
    let logs = [];
    if (zoneLevel) {
      logs = sortList.filter((f) => Zone_Audit_Types[f.auditType] === item);
    } else {
      logs = sortList.filter((f) => Audit_Types[f.auditType] === item);
    }
    setItems(logs);
    setShow(!show);
  };
  const TableCollapse = ({ item, index }) => {
    return (
      <tr className="expended-row">
        <td>{index + 1}</td>
        <td>{item.question}</td>
        <td style={{ textAlign: "center" }}>{item.answer}</td>
      </tr>
    );
  };
  const TableRow = ({ log, index }) => {
    const [quesAns, setQuesAns] = useState([]);
    const [open, setOpen] = useState(false);
    const handleEmplQues = async (log) => {
      const models = await api.getEmployeeQuestion(user.id.replace("%testNow", ""), log.createdAt);
      console.log("QuestionAns", models);
      setQuesAns(models);
      setOpen(!open);
    };
    return (
      <>
        <tr>
          <td>
            {log.action === "Done" && (
              <i
                onClick={() => handleEmplQues(log)}
                onMouseLeave={(e) => (e.target.style.color = "black")}
                onMouseOver={(e) => {
                  e.target.style.color = "#8B0000";
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                className={open ? "fas fa-minus" : "fas fa-plus"}
              />
            )}
          </td>
          <td>{index + 1}</td>
          <td>{formatDateMDY(log.createdAt)}</td>
          <td style={{ textAlign: "center" }}>{log.auditType}</td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {log.result || log.action || log.reason}
          </td>
          {/* <td style={{ textAlign: "center", textOverflow: "visible"  }}>{log.detail}</td> */}
          <td style={{ textAlign: "center" }}>{log.userName || "System"}</td>
        </tr>
        {quesAns.length > 0 && open && (
          <tr>
            <td colSpan={"12"} className="expended-table-wrapper">
              <Card className="striped-table-card">
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive overFlow-y-hidden">
                      <Table className="table expandedTable">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>Sr#</th>
                            <th>Question</th>
                            <th className="centered">Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {quesAns.length > 0 &&
                            quesAns.map((item, i) => {
                              return <TableCollapse key={i} index={i} item={item} />;
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </td>
          </tr>
        )}
      </>
    );
  };
  return (
    <>
      <tr
        onClick={handleExpand}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
      >
        <td>
          <i
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.color = "#8B0000";
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            className={show ? "fas fa-minus" : "fas fa-plus"}
          />
        </td>
        <td>{item}</td>
      </tr>
      {items.length > 0 && show && (
        <tr className="subTableRow">
          <td colSpan={"12"} className="expended-table-wrapper">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTable">
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ width: "10%" }}>Sr#</th>
                          <th>Date</th>
                          <th className="border-0 centered">Event</th>
                          <th className="border-0 centered">Action</th>
                          {/* <th className="border-0 centered">Detail</th> */}
                          <th className="border-0 centered">User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length > 0 &&
                          items.map((item, i) => {
                            return <TableRow key={i} index={i} log={item} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default AuditTrailEntries;
