import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Modal, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { HEALTH_AND_SAFTEY, newRoleOptions } from "constant";
import API from "api";
import { AppContext } from "context/app-context";
import { phoneFormatter, validatePhone, toTitleCase, parseBooleanValue } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import PhoneNoInput from "components/Phone/PhoneNoInput";

const UserModal = (props) => {
  const { show, user, handleSave, handleClose, resetCall, clients } = props;
  console.log("user", user);
  const getUserRole = () => newRoleOptions.find((role) => role.value === user.role);
  const getClient = (clientID) => clients.find((c) => clientID === c.value);
  const [newUser, setNewUser] = useState(user);
  const [error, setError] = useState("");
  const [selectedRole, setSelectedRole] = useState(getUserRole());
  const [client, setClient] = useState([]);
  const [mainClient, setMainClient] = useState(!user.isNew ? getClient(user.clientID) : "");
  const [testCordClient, setTestCordClient] = useState(!user.isNew ? getClient(user.clientCordID) : "");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    loadClientData();
    setNewUser(user);
    setSelectedRole(getUserRole());
  }, [user]);

  const loadClientData = async () => {
    if(!user.username) return;
    const clientData = await API.getEmployeeClients(user.username);
    if (clientData) {
      const res = clients.filter((c) => clientData.indexOf(c.value) !== -1);
      setClient(res);
      setNewUser({ ...newUser, clients: res.map((t) => t.value).join(",") });
    }
  };

  const appContext = useContext(AppContext);
  const sites = appContext.sites;
  const labs = appContext.labs;

  const handleRoleChange = (selectedOption) => {
    setSelectedRole(selectedOption);
    console.log(`Option selected:`, selectedOption, newUser);
    setNewUser({ ...newUser, role: selectedOption.value });
  };

  const onHandleSave = () => {
    if (!newUser.firstName) {
      setError("First name is required");
      return;
    }

    if (!newUser.lastName) {
      setError("Last name is required");
      return;
    }

    if (!newUser.email) {
      setError("Email name is required");
      return;
    }

    if (!newUser.phone) {
      setError("Phone name is required");
      return;
    }
    if (phoneError) {
      showErrorMessage("Invalid Phone Number");
      return;
    }
    if (newUser.isNew && !newUser.password) {
      setError("Password is requried");
      return;
    }

    if (!validatePhone(newUser.phone)) {
      setError("Invalid Phone Number");
      return;
    }

    if (!newUser.clients) {
      setError("Client is required");
      return;
    }

    if (newUser.clients.length === 0) {
      setError("Client is required");
      return;
    }
    if (newUser.testingCord && !newUser.clientCordID) {
      setError("Client is required for TestingCord");
      return;
    }
    if (newUser.isNew) {
      console.log(newUser.password);
      const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(newUser.password);

      if (!isValidPassword) {
        setError(
          "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
        );
        return;
      }
    }

    handleSave(newUser);
    handleClose();
  };
  console.log(newUser);

  const getEmployeeClientsList = useMemo(() => {
    if (!newUser.clients) {
      return [];
    }
    return clients.filter((t) => newUser.clients.indexOf(t.value) !== -1);
  }, [newUser.clients]);
  
  return (
    <>
      <Modal
        show={show}
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create User" : "Update User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="row">
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">First Name: </label>
                <input
                  value={newUser.firstName}
                  className="modalInput w-100"
                  placeholder="First Name"
                  onChange={(e) => setNewUser({ ...newUser, firstName: toTitleCase(e.target.value) })}
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Last Name: </label>
                <input
                  value={newUser.lastName}
                  className="modalInput w-100"
                  placeholder="Last Name"
                  onChange={(e) => setNewUser({ ...newUser, lastName: toTitleCase(e.target.value) })}
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Email: </label>
                <input
                  value={newUser.email}
                  className="modalInput w-100"
                  placeholder="Email"
                  onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone Number:</label>
                <PhoneNoInput
                  error={setPhoneError}
                  handleChange={(e) =>
                    setNewUser({
                      ...newUser,
                      phone: e,
                    })
                  }
                  value={newUser.phone}
                />
              </div>
            </div>
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper">
                {newUser.isNew && (
                  <>
                    <label className="modalLineHeaders">Password: </label>

                    <input
                      type="password"
                      className="modalInput"
                      value={newUser.password}
                      placeholder="Password"
                      style={{
                        width: "100%",
                      }}
                      onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                    />
                  </>
                )}
              </div>
            </div>
            {/* <div
            className={`form-group-wrapper ${
              newUser.role === "Employer" || newUser.role === "Lab" ? "activeEmploye" : ""
            }`}
          >
            <label className="modalLineHeaders">Role:</label>
            <input
                  type="text"
                  className="modalInput"
                  value={newUser.role}
                  readOnly
                  placeholder="Role"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                />
          </div> */}
            <div className="col-sm-12 createClientsWrapper">
              <div className="form-group-wrapper activeEmploye">
                <label className="modalLineHeaders">Client:</label>

                <Select
                  className="w-100 "
                  options={clients}
                  value={client}
                  isMulti
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  placeholder="Select a Client"
                  onChange={(e) => {
                    setClient(e);
                    setNewUser({ ...newUser, clients: e.map((t) => t.value).join(",") });
                  }}
                />
              </div>
            </div>

            <div className="checkbox checkBoxWrapper">
              <span className="px-2" style={{ marginRight: "8px" }}>
                Main Contact
              </span>
              <div className="d-flex align-items-center">
                <span className="labelYesNo">{parseBooleanValue(newUser.mainContact)}</span>
                <Form.Check className="mt-1">
                  <Form.Check.Input
                    checked={newUser.mainContact}
                    onChange={(e) => setNewUser({ ...newUser, mainContact: !newUser.mainContact })}
                    type="checkbox"
                  />
                </Form.Check>
              </div>
            </div>
            {newUser.mainContact && (
              <div className="col-sm-12 createClientsWrapper">
                <div className="form-group-wrapper activeEmploye">
                  <label className="modalLineHeaders">Client:</label>

                  <Select
                    className="w-100"
                    options={getEmployeeClientsList}
                    value={mainClient}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    placeholder="Select a Client"
                    onChange={(e) => {
                      setMainClient(e);
                      setNewUser({ ...newUser, clientID: e.value });
                    }}
                  />
                </div>
              </div>
            )}
            <div className="checkbox checkBoxWrapper">
              <span className="px-2" style={{ marginRight: "8px" }}>
                Testing Cord
              </span>
              <div className="d-flex align-items-center">
                <span className="labelYesNo">{parseBooleanValue(newUser.testingCord)}</span>
                <Form.Check className="mt-1">
                  <Form.Check.Input
                    checked={newUser.testingCord}
                    onChange={(e) => setNewUser({ ...newUser, testingCord: !newUser.testingCord })}
                    type="checkbox"
                  />
                </Form.Check>
              </div>
            </div>
            {newUser.testingCord && (
              <div className="col-sm-12 createClientsWrapper">
                <div className="form-group-wrapper activeEmploye">
                  <label className="modalLineHeaders">Client:</label>

                  <Select
                    className="w-100 "
                    options={getEmployeeClientsList}
                    value={testCordClient}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    placeholder="Select a Client"
                    onChange={(e) => {
                      setTestCordClient(e);
                      setNewUser({ ...newUser, clientCordID: e.value });
                    }}
                  />
                </div>
              </div>
            )}
            {!newUser.isNew && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders"> </label>
                <label
                  className="modalLineHeaders linkedText"
                  style={{ minWidth: "50%" }}
                  onClick={() => (handleClose(), resetCall(true))}
                >
                  Reset Password
                </label>
              </div>
            )}
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            {newUser.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserModal;
