import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage"

const TestTypeModal = ({ item, handleSave, handleClose }) => {
  const [newItem, setNewItem] = useState(item);
  const [error, setError] = useState("");
  const validateInput = () => {
    if (!validateTimeInput(newItem.totalTime)) {
      setError("Enter valid total Time");
      return;
    }
    if (!validateTimeInput(newItem.firstAlert)) {
      setError("Enter valid First Alert");
      return;
    }
    if (!validateTimeInput(newItem.secondAlert)) {
      setError("Enter valid Second Alert");
      return;
    }
     handleSave(newItem);
  };

  const validateTimeInput = (val) => /^[0-9][0-9]:[0-5][0-9]$/.test(val);

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Edit Times
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <p className="modalLineHeaders">Test Name</p>
        <h6>{newItem.name}</h6>
        <p className="modalLineHeaders">Total Time</p>
        <input
          className="modalInput"
          value={newItem.totalTime}
          placeholder="Primary Contact"
          style={{ width: "100%" }}
          onChange={(e) => setNewItem({ ...newItem, totalTime: e.target.value })}
        />
        <p className="modalLineHeaders">First Alert</p>
        <input
          className="modalInput"
          value={newItem.firstAlert}
          style={{ width: "100%" }}
          onChange={(e) => setNewItem({ ...newItem, firstAlert: e.target.value })}
        />
        <p className="modalLineHeaders">Second Alert</p>
        <input
          className="modalInput"
          value={newItem.secondAlert}
          style={{ width: "100%" }}
          onChange={(e) => setNewItem({ ...newItem, secondAlert: e.target.value })}
        />
        <div style={{minHeight:"3vh"}}>
        {error && (
          <ErrorMessage error={error} handleChange={()=>setError('')}/>
        )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={validateInput}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestTypeModal;
