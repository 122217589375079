import React, { useState, useMemo, useEffect , useContext} from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { AppContext } from "context/app-context";
import api from "api";
import ErrorMessage from "components/Message/ErrorMessage";
import { getValidReg, checkValidity } from "utils";
import { TIMEZONE_OPT } from "constant";
import UpdateConfirmation from "components/Department/UpdateConfirmation";

const DepartmentModal = (props) => {
  const { show, selectedDepartment, handleClose, handleSubmit, departments } = props;
  let obj = {
    name: "",
    callTime: null,
    reminderOne: "",
    reminderTwo: "",
    deptTimeZone: "",
    isNew: true,
  };

  const [departmentObj, setDepartmentObj] = useState(selectedDepartment ? selectedDepartment : obj);
  const [error, setError] = useState("");
  const [updateConfirmation, setUpdateConfirmation] = useState(false);
  const appContext = useContext(AppContext);
  
  const isExistName = () => {
    const isExist = departments.filter(
      (d) => departmentObj.id !== d.id && checkValidity(d.name) == checkValidity(departmentObj.name)
    );
    if (isExist && isExist.length > 0) return true;
    return false;
  };

  const handleSave = () => {
    if (!departmentObj.name || departmentObj.name === "") {
      setError("Department Name is required");
      return;
    }
    departmentObj.name = getValidReg(departmentObj.name);
    if (!departmentObj.callTime) {
      setError("Call Time Required");
      return;
    }
    if (departmentObj.reminderOne && departmentObj.reminderOne < 1) {
      setError("Reminder must be greater than zero");
      return;
    }
    if (departmentObj.reminderTwo && departmentObj.reminderTwo < 1) {
      setError("Reminder must be greater than zero");
      return;
    }
    if (!departmentObj.deptTimeZone || departmentObj.deptTimeZone === "") {
      setError("Select department time zone");
      return;
    }
    if (isExistName()) {
      setError("Department name already exists");
      return;
    }
    if (departmentObj.isNew) {
      handleSubmit(departmentObj);
      return;
    }
    if (selectedDepartment && selectedDepartment.callTime !== departmentObj.callTime) {
      setUpdateConfirmation(true);
      return;
    }
    handleSubmit(departmentObj);
  };


  const onHandleUpdateConfirmation = (updateOption) => {
    handleSubmit({ ...departmentObj, updateOption });
  };

  return (
    <>
      <Modal show={show} animation={true} onHide={() => handleClose()} centered size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Department
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="row mb-3"></div>
          <div className="row">
            <div className="col-sm-12">
              <div className="createClientsWrapper flex-wrap">
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Name:</label>
                    <input
                        id="name"
                        className="modalInput"
                        placeholder="Enter Name"
                        value={departmentObj.name}
                        onChange={(e) => setDepartmentObj({ ...departmentObj, name: e.target.value })}
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Call Time:</label>
                    <input
                        type="time"
                        id="callTime"
                        step="900"
                        className="modalInput"
                        value={departmentObj.callTime || ""}
                        onChange={(e) => setDepartmentObj({ ...departmentObj, callTime: e.target.value })}
                    />
                  </div>
                </div>
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">First Reminder Time:</label>
                    <input
                        min="1"
                        type="number"
                        placeholder="Hours"
                        id="remainderOne"
                        className="modalInput"
                        value={departmentObj.reminderOne}
                        onChange={(e) =>
                            setDepartmentObj({
                              ...departmentObj,
                              reminderOne: e.target.value,
                            })
                        }
                    />
                  </div>
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Second Reminder Time:</label>
                    <input
                        min="1"
                        type="number"
                        placeholder="Hours"
                        id="remainderTwo"
                        className="modalInput"
                        value={departmentObj.reminderTwo}
                        onChange={(e) =>
                            setDepartmentObj({
                              ...departmentObj,
                              reminderTwo: e.target.value,
                            })
                        }
                    />
                  </div>
                </div>
                <div className="first-last-name-wrapper">
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0">Time Zone:</label>
                    <Select
                        options={TIMEZONE_OPT}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        menuPlacement="auto"
                        placeholder="Select Time zone"
                        value={
                          departmentObj.deptTimeZone
                              ? { label: departmentObj.deptTimeZone, value: departmentObj.deptTimeZone }
                              : null
                        }
                        onChange={(e) =>
                            setDepartmentObj({
                              ...departmentObj,
                              deptTimeZone: e.value,
                            })
                        }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
        <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
            Close
          </Button>
          <Button variant="secondary" className="headerButton btn-fill" onClick={handleSave}>
            {selectedDepartment ? "Update" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
      {updateConfirmation && (
        <>
          <div class="secondModal"></div>
          <UpdateConfirmation
            handleSave={onHandleUpdateConfirmation}
            handleClose={() => {
              setUpdateConfirmation(false);
            }}
            settings={appContext.company}
          />
        </>
      )}
    </>
  );
};

export default DepartmentModal;
