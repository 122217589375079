import React from "react";
import { Row, Col } from "react-bootstrap";

const MFPagination = (props) => {
  const { currentPage, totalPages, handleChangePage, totalSelected, showSelectedRecord, nextPageToken } = props;

  return (
    <Row className="pull-right" style={{ borderWidth: 1 }}>
      <Col>
        <span style={{ fontWeight: "bold" }}>{showSelectedRecord && `Selected Employee : ${totalSelected}`}</span>
      </Col>
      <Col className="text-right" md={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }}>
        Page
        <ul className="createButtons pr-0" style={{ display: "inline" }}>
          <i
            className="fas fa-angle-left"
            style={{ color: "#585858" }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "black";
            }}
            onMouseLeave={(e) => (e.target.style.color = "#585858")}
            onClick={() => {
              currentPage !== 1 && handleChangePage(currentPage - 1);
            }}
          />
          {currentPage} of {totalPages}
          <i
            className="fas fa-angle-right"
            style={{ color: "#585858" }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.color = "black";
            }}
            onMouseLeave={(e) => (e.target.style.color = "#585858")}
            onClick={() => {
              (currentPage !== totalPages || nextPageToken ) && handleChangePage(currentPage + 1);
            }}
          />
        </ul>
      </Col>
    </Row>
  );
};

export default MFPagination;
