import React, { useState, useContext, useRef } from "react";
import { Modal, Form, Button, Row, Card, Col, Table, Overlay, Popover } from "react-bootstrap";
import { getValidReg, removeSpaceIns } from "utils";
import { COLOR_OPT, TEST_TYPES, TEST_TYPE_VALUE, weekDaysOptions, DAYS_LABEL } from "constant";
import api from "api";
import { AppContext } from "context/app-context";
import { SketchPicker } from "react-color";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
const ProgramNameInput = (props) => {
  const { handleConfirm, programSetting, programs, handleClose, selectItem, handleSave } = props;
  const [newProgram, setNewProgram] = useState(selectItem);
  const [addDays, setAddDays] = useState(1);
  const [error, setError] = useState("");
  const [addTestType, setAddtestType] = useState("");
  const [addGroup, setaddGroup] = useState(selectItem?.testValidity || []);
  const appContext = useContext(AppContext);
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef();
  console.log("newProgram", newProgram);
  const isExistName = () => {
    const isExist = programs.filter(
      (d) => d.id !== newProgram?.id && removeSpaceIns(d.name) == removeSpaceIns(newProgram.name)
    );
    console.log("isExist", isExist);
    if (isExist && isExist.length > 0) return true;
    return false;
  };
  const handleSaveChange = async () => {
    let obj = {};
    selectItem
      ? Object.assign(obj, { ...newProgram, isNew: false })
      : Object.assign(obj, { ...newProgram, isNew: true });
    if (!obj.color && obj.isNew) Object.assign(obj, { color: "#000000" });
    if (!obj.name || obj.name === " ") {
      setError("Program be required");
      return;
    }
    if (!obj.color || obj.color === "#ffffff") {
      setError("Please Select Color");
      return;
    }
    if (addGroup.length === 0 && appContext.company?.programLevel === "Zone") {
      setError("Please add at least one test type with Duration");
      return;
    }
    if (isExistName()) {
      setError("Program Name already exist");
      return;
    }
    if (addGroup.length > 0) {
      Object.assign(obj, { ...newProgram, testValidity: JSON.stringify(addGroup) });
    }
    if (!programSetting) {
      let res = null;
      try {
        res = await api.newProgramSettings(obj);
      } catch (err) {
        console.log(err.message);
      }
      console.log("response", res);
      handleConfirm(true, res);
    } else {
      console.log("obj", obj);
      handleSave(obj);
    }
  };

  const handleChange = () => {
    if (!addTestType.length > 0) {
      setError("Select Test Type Please!");
      return;
    }
    if (addDays <= 0) {
      setError("Select Days Positive Value Please!");
      return;
    }
    const findVal = addGroup.findIndex((c) => c.value === addTestType);
    if (findVal !== -1) {
      const groupData = [...addGroup];
      groupData.splice(findVal, 1, {
        value: addTestType,
        days: addDays,
        location: "On Location",
        label: TEST_TYPE_VALUE[addTestType],
      });
      setaddGroup(groupData);
    } else {
      setaddGroup((prevGroup) => [
        ...prevGroup,
        { value: addTestType, days: addDays, location: "On Location", label: TEST_TYPE_VALUE[addTestType] },
      ]);
    }
    setAddtestType("");
    setAddDays(1);
  };

  const handleDelete = (index) => {
    const groupdata = [...addGroup];
    groupdata.splice(index, 1);
    setaddGroup(groupdata);
  };

  const getTestOrderQuantity = () => {
    const options = [];
    for (let i = 1; i <= 14; i++) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  const TableRow = ({ data, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{TEST_TYPE_VALUE[data.value]}</td>
        <td>{`${data.days} ${data.days > 1 ? "days" : "day"}`}</td>
        <td>
          <i className="fas fa-trash" style={{ cursor: "pointer" }} onClick={() => handleDelete(index)} />
        </td>
      </tr>
    );
  };

  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        programSetting ? handleClose() : handleConfirm(false, null);
      }}
      centered
      scrollable
      size={"lg"}
      className="showChildModal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          Program
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="row mb-3"></div>
        <div className="row">
          <div className="col-sm-12 createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders mt-0">Program Name:</label>
              <input
                type="text"
                className="modalInput w-100"
                placeholder="Enter Program Name"
                value={newProgram?.name}
                onChange={(e) => setNewProgram({ ...newProgram, name: getValidReg(e.target.value) })}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders mx-2 mt-1">Zone Color:</label>
              {/* <Select
                className="w-100"
                options={COLOR_OPT}
                placeholder="Select Color"
                value={COLOR_OPT.find((f) => f.value === newProgram?.color)}
                onChange={(e) => setNewProgram({ ...newProgram, color: e.value })}
              /> */}
              <div ref={childNode} onClick={() => setShowPopover(!showPopover)} className="swatch mx-2 mt-1">
                <div
                  style={{
                    width: "36px",
                    height: "14px",
                    borderRadius: "2px",
                    cursor: "pointer",
                    backgroundColor: `${removeSpaceIns(newProgram?.color || "#000")}`,
                  }}
                />
              </div>
              {showPopover && (
                <Overlay
                  show={showPopover}
                  placement="auto"
                  onHide={() => setShowPopover(false)}
                  rootClose={true}
                  transition={true}
                  flip
                  target={childNode.current}
                >
                  <Popover id="popover" className="popOverSchedule">
                    <SketchPicker
                      color={newProgram?.color || "#000"}
                      onChangeComplete={(e) => setNewProgram({ ...newProgram, color: e.hex })}
                    />
                  </Popover>
                </Overlay>
              )}
            </div>
          </div>
          {appContext.company?.programLevel === "Zone" && (
            <div className="createClientsWrapper flex-wrap">
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">Select Type: </label>
                  <Select
                    options={TEST_TYPES}
                    className="assignSchedule"
                    value={addTestType.length > 0 ? { value: addTestType, label: TEST_TYPE_VALUE[addTestType] } : null}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="bottom"
                    placeholder="Select Type"
                    onChange={(e) => setAddtestType(e.value)}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">Duration:</label>
                  <div className="d-flex align-items-center justify-content-between">
                    <Select
                      options={getTestOrderQuantity()}
                      blurInputOnSelect={true}
                      defaultValue={{ value: 1, label: 1 }}
                      menuPlacement="bottom"
                      placeholder="Select Days"
                      className="w-100"
                      value={{ value: addDays, label: addDays }}
                      onChange={(e) => setAddDays(e.value)}
                    />

                    <Button
                      className="modalButtons btn-sm btn-dark btn-fill-dark rounded-0"
                      variant="secondary"
                      onClick={() => handleChange()}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {appContext.company?.programLevel === "Zone" && (
          <Row>
            <Col md="12">
              <Card className="strpied-tabled-with-hover modalHeight">
                <Card.Body>
                  <div className="form-group-wrapper d-block">
                    <div className="table-responsive" style={{ overflow: "hidden" }}>
                      <Table className="table">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>SR#</th>
                            <th>TEST TYPE</th>
                            <th>DURATION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {addGroup.length > 0 &&
                            addGroup.map((data, index) => <TableRow data={data} key={index} index={index} />)}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button
          className="modalButtons headerButton btn-fill"
          variant="primary"
          onClick={() => {
            programSetting ? handleClose() : handleConfirm(false, null);
          }}
        >
          Close
        </Button>
        <Button
          className="modalButtons headerButton btn-fill"
          variant="secondary"
          onClick={() => {
            handleSaveChange();
          }}
        >
          {selectItem ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProgramNameInput;
