import parsePhoneNumber, { AsYouType, isValidNumber } from "libphonenumber-js";
import moment from "moment";
import { AMAZON_CLIENT_RESULT, LOCATION, REGION, NEW_PERSONALIZE, SCHEDULE_CATEGORY, HISTORY_TEST } from "constant";
import { isPossiblePhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import { GROUP_TYPES } from "constant";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import FileSaver from "file-saver";
import { jsonToCSV } from "react-papaparse";
import postalCodes from "postal-codes-js";
import { TEST_TYPE_VALUE } from "constant";
import { v4 as uuidv4 } from "uuid";
import { STATUS } from "constant";

export const downloadDataAsCSV = async (data, fileName) => {
  const content = jsonToCSV(data);
  const fileContent = new Blob([content], { type: "csv" });
  await FileSaver.saveAs(fileContent, `${fileName}.csv`);
};

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    return formatPhoneNumberIntl(phoneNumber);
  }
  return phoneNumber;
};

export const phoneFormatter = (phone) => {
  if (phone) {
    const d = setPhoneNo(phone);
    return new AsYouType("US").input(d);
  }
  return phone;
};
export const isValidEmail = (email) => {
  if (
    new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email)
  ) {
    return true;
  } else return false;
};
export const isValidCallTime = (clt) => {
  console.log("clt", clt);
  if (new RegExp(/^([01]\d|2[0-3])(:)([0-5]\d)?$/g).test(clt)) {
    return true;
  }
  return false;
};
export const checkValidity = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/[^A-Z0-9]/gi, "");
};
export const removeSpaceIns = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/\s/g, "");
};
export const toTitleCase = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.toLowerCase().replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase());
};
export const CapsFirstLetter = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2);
};
export const convertToLower = (val) => {
  const v = val.trim();
  if (v) return v.toLowerCase();
  return val;
};

export const getCallTime = (clt) => {
  if (!clt) return "";
  const time = clt.split(":");
  if (time[0].length == 1) time[0] = "0" + time[0];
  const calltime = [...time];
  if (calltime[1] % 15 != 0) {
    calltime[1] = calltime[1] - (calltime[1] % 15);
    if (calltime[1] == 0) calltime[1] = "00";
    return calltime.join(":");
  }
  return time.join(":");
};
export const getValidDep = (name) => {
  if (!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};
export const getValidReg = (reg) => {
  if (!reg) return "";
  return CapsFirstLetter(reg.replace(/  +/g, " "));
};
export const getValidSpaces = (name) => {
  if (!name) return "";
  return name.replace(/  +/g, " ");
};
export const isValidName = (name) => {
  if (!name) return false;
  if (new RegExp(/^(?!.*([a-z])\1{2})[A-Za-z ]{2,75}$/i).test(name)) {
    return true;
  } else return false;
};
export const isValidPhone = (phone) => isValidNumber(phone, "US");
export const validatePhone = (value) => {
  if (value) return isPossiblePhoneNumber(value);
  return false;
};

export const getPageNumbers = (items) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};
export const isValidDob = (dobs) => {
  if (new RegExp(/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/).test(dobs)) {
    return true;
  }
  return false;
};
export const parseBooleanValue = (val) => (val ? "Yes" : "No");
export const getVaccinated = (vac) => {
  if (!vac) return false;
  const val = vac.toLowerCase();
  if (val) return val === "y" || val === "yes" ? true : false;
  return false;
};
export const capitalizeLetter = (value) => {
  if (!value) return "";
  if (value.length === 2) return value.toUpperCase();
  return `${value.substring(0, 1).toUpperCase()}${value.substring(1).toLowerCase()}`;
};

export const getCrewsName = (empDemos) => {
  // console.log(typeof empDemos);
  if (!empDemos) return "";
  let parsed = typeof empDemos === "string" ? JSON.parse(empDemos) : empDemos;
  let first = parsed.firstName.charAt(0).toUpperCase() + parsed.firstName.slice(1).toLowerCase();
  let last = parsed.lastName.charAt(0).toUpperCase() + parsed.lastName.slice(1).toLowerCase();
  return `${first} ${last}`;
};
export const onBoardingTest = (test) => {
  if (Array.isArray(test)) return [...test];
  if (test instanceof Object) return [{ ...test }];
  return [];
};
export const formatTest = (testData, clients) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;
  let name;
  let firstName;
  let lastName;
  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
  }
  const test = { ...testData };
  test.viewedResult = testData.result;
  if (clients && clients.length > 0) {
    const client = clients.find((c) => c.id === test.clientID);
    if (client && client.resultType === "P/F") {
      test.viewedResult = AMAZON_CLIENT_RESULT[test.result];
    }
  }

  test.status = testData.status.toLowerCase() === "pending" && !testData.result ? "New" : testData.status;
  test.test_type =
    testData.test_type === "Antigen"
      ? "Rapid Antigen"
      : testData.test_type === "Other"
      ? "Rapid Accula"
      : testData.test_type;
  return { ...test, name, firstName, lastName };
};

export const getDropDownValue = (val) => {
  if (val === null || val === undefined) return null;
  if (val) return { value: true, label: "Yes" };
  return { value: false, label: "No" };
};
export const formatQuestion = (ques) => {
  return ques.map((q) => ({ ...q, question: JSON.parse(q.question) }));
};
export const getDemoGraphics = (test) =>
  typeof test.employee_demographics === "string" ? JSON.parse(test.employee_demographics) : test.employee_demographics;

export const formatDateOfBirth = (dob) =>
  dob ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}` : "";

export const getAge = (dob) => {
  const val = formatDateOfBirth(dob);
  return dob ? moment().diff(val, "years") : "";
};
export const getValidGender = (gen) => {
  if (gen.toLowerCase() == "male" || gen.toLowerCase() == "m") return "M";
  if (gen.toLowerCase() == "female" || gen.toLowerCase() == "f") return "F";
  if (gen.toLowerCase() == "x") return "X";
  return "";
};

export const formatDate = (date) => (date ? moment(date).format("MMM DD, YYYY") : "");

export const formatDateMDY = (date) => (date ? moment(date.substring(0, 10)).format("MM/DD/YYYY") : "");

export const formatDateMDYTime = (date) => (date ? moment(date).format("MM/DD/YYYY HH:mm") : "");

export const currentDate = () => moment().format("MM/DD/YYYY");

export const formatPDFName = (demos) => {
  let name = "";
  if (demos.lastName) name = `${demos.lastName.substring(0, 1)}${demos.lastName.substring(1).toLowerCase()}`;
  if (name) name = `${name}, `;
  if (demos.firstName) name = `${name}${demos.firstName.substring(0, 1)}${demos.firstName.substring(1).toLowerCase()}`;
  return name;
};

export const formatZip = (val) => {
  if (!val) return "";
  const input = val.replaceAll("[^0-9]+", "");
  if (input.toString().length > 5) {
    return input.substring(0, 5) + "-" + input.substring(6);
  }
  if (input.toString().length === 5) {
    return input.toString();
  }
  return input;
};
export const changeSort = (
  sortBy,
  sortDescending,
  filteredTests,
  setFilteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  console.log("sortBY", sortBy);
  switch (sortBy) {
    case "employee_demographics":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
                ? -1
                : 0
            )
      );
      break;
    case "clientID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) < getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) < getClientName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) > getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) > getClientName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "siteID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) < getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) > getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "labID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
            )
      );
      break;
    case "result":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
            )
      );
      break;
    case "qaDone":
    case "testDone":
    case "checkIn":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] || "";
              const testerA = a[sortBy] || "";
              return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] || "";
              const testerA = a[sortBy] || "";
              return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
            })
      );
      break;
    case "employeeID":
    case "status":
    case "callTime":
    case "isVaccinated":
    case "programName":
    case "zoneColor":
    case "localNo":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "tester_name":
    case "tests":
    case "onBoardingTesting":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "totalTests":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              b.tests.length < a.tests.length ? 1 : a.tests.length < b.tests.length ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              b.tests.length > a.tests.length ? 1 : a.tests.length > b.tests.length ? -1 : 0
            )
      );
      break;
    default:
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy];
              const testerA = a[sortBy];
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy];
              const testerA = a[sortBy];
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
  }
};

export const sortList = (sortBy, sortDescending, filteredTests) => {
  switch (sortBy) {
    case "employee_demographics":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
              ? -1
              : 0
          );
    case "qaDone":
    case "testDone":
    case "checkIn":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] || "";
            const testerA = a[sortBy] || "";
            return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] || "";
            const testerA = a[sortBy] || "";
            return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
          });
    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "departmentName":
    case "tester_name":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
    case "totalTests":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            b.tests.length < a.tests.length ? 1 : a.tests.length < b.tests.length ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            b.tests.length > a.tests.length ? 1 : a.tests.length > b.tests.length ? -1 : 0
          );
    default:
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
  }
};

export const isValidFile = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files are allowed";
    }

    if (file.size / 1024 / 1020 > 10) {
      return "max. 10MB file size allow";
    }

    return "";
  }
  return "";
};
export const formatDateMatrix = (d) => moment(d).format("ddd MM/DD");

export const importPhoneNoFormat = (phone) => {
  if (!phone) return;
  const formattedPhoneNo = phone.replace(/\D/g, "");
  if (formattedPhoneNo && formattedPhoneNo.length == 10) {
    return `+1${formattedPhoneNo}`;
  }
  return `+${formattedPhoneNo}`;
};
export const searchPhone = (phone) => {
  if (!phone) return null;
  return phone.includes("+") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};
export const setPhoneNo = (phone) => {
  if (!phone) return null;
  return phone.includes("+1") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};
export const getPhoneNumber = (phone) => {
  if (!phone) return "";
  const countryCode = phone.substring(0, 1);
  console.log(countryCode);
  if (countryCode !== "+") {
    const phoneNumber = `+${phone.replace(/\D/g, "")}`;
    return phoneNumber;
  }
  return phone;
};
export const formatZipCode = (id) => {
  if (!id) return id;
  let val = id.split("-").join("");
  return val.match(/.{1,5}/g).join("-");
};

export const isValidLocation = (loc) => {
  if (!loc) return null;
  const val = loc.toLowerCase();
  return LOCATION.includes(val);
};

export const isValidRegion = (reg) => {
  if (!reg) return null;
  const val = reg.toLowerCase();
  return REGION.includes(val);
};

export const medFlowInLocalStorage = {
  save: () => localStorage.setItem("medFlowHR", "medFlow"),
  get: () => localStorage.getItem("medFlowHR") || null,
  clear: () => localStorage.clear(),
};
export const sortingFilterInLC = {
  save: (obj) => localStorage.setItem("medflowhr", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("medflowhr")) {
      return JSON.parse(localStorage.getItem("medflowhr"));
    }
    return {
      employees: {},
      programs: {},
      schedules: {},
      questionnaire: {},
      departments: {},
    };
  },
  clear: () => localStorage.removeItem("medflowhr"),
};
export const userCompanyID = {
  save: (id) => localStorage.setItem("cid", id),
  get: () => localStorage.getItem("cid") || null,
  clear: () => localStorage.removeItem("cid"),
};

export const formatDateOfBirthDOB = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.length === 8) {
    const month = dob.substring(0, 2);
    const day = dob.substring(2, 4);
    const year = dob.substring(4);
    return moment(`${year}${month}${day}`).format("MM/DD/YYYY");
  } else return moment(dob).format("MM/DD/YYYY");
};

export const formatDOB = (dobs) => {
  if (!dobs) return "";
  const dob = dobs.split(/\/|-|,/g);
  if (dob[0].length === 1) {
    dob[0] = "0" + dob[0];
  }
  if (dob[1].length === 1) {
    dob[1] = "0" + dob[1];
  }
  return moment(`${dob[2]}-${dob[0]}-${dob[1]}`).format("YYYY-MM-DD");
};

export const calculateTdWidth = (width, numberOfCol) =>
  screen.width >= "768" && screen.width <= "1024" ? 100 : Math.ceil(width / numberOfCol);
export const personalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, index) => {
    const selectedSetting = arr
      .filter((f) => f.isCheck)
      .map((f) => f.id)
      .join(",");

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    const programKeys = objData.personalize.split(":");
    programKeys[index] = selectedSetting;
    const obj = { personalize: programKeys.join(":") };
    localStorage.setItem("personalisation", JSON.stringify(obj));
    return obj;
  },
  get: (user, index, settings) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else if (user["custom:personalize"]) {
        objData = user["custom:personalize"];
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = objData.personalize.split(":")[index].split(",");
    } catch (err) {}

    return NEW_PERSONALIZE.map((f) => {
      return { ...f, isCheck: arrData.indexOf(f.id.toString()) !== -1 };
    });
  },
  clear: () => localStorage.removeItem("personalisation"),
};

export const tdEmail = (email) => (
  <td
    className=" ellipsis"
    style={{
      textDecoration: "underline",
      color: "#A82632",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => email && window.open(`mailto:${email}`)}
    title={email}
  >
    {email}
  </td>
);

export const tdPhone = (phoneNumber) => (
  <td
    className="ellipsis"
    style={{
      textDecoration: "underline",
      color: "#A82632",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => phoneNumber && window.open(`tel:+${phoneNumber.replace(/\D/g, "")}`)}
    title={formatPhoneNumber(phoneNumber)}
  >
    {formatPhoneNumber(phoneNumber)}
  </td>
);

export const tdCheckBox = (item, user, openExternalTest) => {
  if (item.itemKey === "qaDone" && !user.testNow) {
    return (
      <td style={{ textAlign: "center", textOverflow: "visible" }}>
        {user.qaDone === 1 || user.qaDone === "1" || user.qaDone === true ? (
          <ViewCheckBox id={user.id} bgColor="green" />
        ) : user.qaDone === "M" ? (
          <ViewCheckBox
            id={user.id}
            bgColor="blue"
            title={`${user.manualCheckInReason} \Allowed By: ${user.manualCheckAdmin}`}
          />
        ) : user.qaDone === "X" ? (
          <div style={{ color: "red" }}>X</div>
        ) : (
          "-"
        )}
      </td>
    );
  } else if (item.itemKey === "testDone") {
    const totalTests = [...(user.testTwo || []), ...(user.testOne || [])];
    if (user.testNow) {
      const notTestDone = totalTests.filter((t) => t.testNow && !t.isDone).length > 0;
      const isTestNow = totalTests.filter((t) => t.testNow).length > 0;
      return (
        <td
          style={{ textAlign: "center", textOverflow: "visible" }}
          onClick={() => (notTestDone ? openExternalTest(user, "test") : "")}
        >
          {notTestDone ? (
            <div style={{ color: "red", cursor: "pointer" }}>X</div>
          ) : totalTests.filter((t) => t.testNow).length === 0 ? (
            "-"
          ) : (
            <ViewCheckBox id={user.id} bgColor="blue" />
          )}
        </td>
      );
    }
    return (
      <td
        style={{ textAlign: "center", textOverflow: "visible" }}
        onClick={() => (user.testDone === "X" && openExternalTest ? openExternalTest(user, "test") : "")}
      >
        {user.testDone === 1 || user.testDone === "1" || user.testDone === true ? (
          <ViewCheckBox id={user.id} bgColor="green" />
        ) : user.testDone === "X" ? (
          <div style={{ color: "red", cursor: "pointer" }}>X</div>
        ) : (
          "-"
        )}
      </td>
    );
  } else if (item.itemKey === "checkIn" && !user.testNow) {
    return (
      <td style={{ textAlign: "center", textOverflow: "visible" }}>
        {user.checkIn === "1" ? <ViewCheckBox id={user.id} bgColor="green" /> : "-"}
      </td>
    );
  } else {
    return <td style={{ textAlign: "center", textOverflow: "visible" }}>-</td>;
  }
};

export const tdProgramStatus = (item, user) => {
  return (
    <td
      style={{ textAlign: "center", textOverflow: "visible" }}
      title={
        user.isSchedule === STATUS.startProgram
          ? "Program Started"
          : user.isSchedule === STATUS.assignProgram
          ? "Program Assigned"
          : "Program Unassigned"
      }
    >
      {user.isSchedule === STATUS.startProgram || user.isSchedule === STATUS.zoneAssign ? (
        <ViewCheckBox id={user.id} bgColor="green" />
      ) : user.isSchedule === STATUS.assignProgram ? (
        <ViewCheckBox id={user.id} bgColor="yellow" />
      ) : (
        "-"
      )}
    </td>
  );
};
export const tdZoneColor = (item, user) => {
  return (
    <td
      style={{
        textAlign: item.textAlign,
        textOverflow: item.textOverflow,
      }}
      title={user.zoneColor}
    >
      {user.zoneColor ? (
        <div
          className="m-auto"
          style={{ width: "10px", height: "10px", backgroundColor: `${removeSpaceIns(user.zoneColor)}` }}
        ></div>
      ) : (
        ""
      )}
    </td>
  );
};
export const formatTimeZone = (Tz) => {
  if (!Tz) return "";
  if (Tz === "Pakistan Standard Time") return "PKT";
  return Tz.match(/\b\w/g).join("");
};
export const statusOptions = () => {
  return [
    { value: "0", label: "Empty" },
    {
      value: "1",
      label: <ViewCheckBox id="filter" bgColor="green" />,
    },
    {
      value: "2",
      label: <ViewCheckBox id="filter" bgColor="yellow" />,
    },
  ];
};

export const formatEmployeesData = (employees, schedules, departments, programs, settings) => {
  console.log("programs", programs);
  if (employees.length === 0) return [];
  return employees.map((emp) => {
    const testingGroup = emp.groupType ? GROUP_TYPES[emp.groupType].shortCode : "";

    const empDept = departments.find((f) => f.id === emp.department);
    let timeZone = emp.empTZ || "";
    if (timeZone === "") {
      timeZone = empDept?.deptTimeZone || "";
    }
    let program = "";
    if (programs.length > 0) program = emp.programName ? programs.find((f) => f.name === emp.programName) : "";
    const isZoneLevel = settings?.programLevel === "Zone" ? true : false;
    const obj = {
      ...emp,
      gender: emp.sex,
      departmentName: empDept?.name || "-",
      empTZ: timeZone,
      schedulesOptions: {},
      zoneColor: program?.color || "",
      tests: null,
    };
    //  if(!obj.testHistory) {
    //   obj.testHistory = HISTORY_TEST
    //  }
    const checkInDate = obj.checkIn;
    if (!isZoneLevel) {
      const isTodaySchedule = obj.logDate && obj.logDate.substring(0, 10) === moment().format("YYYY-MM-DD");
      let isTestingSchedule = false;
      if (obj.testOne && obj.testOne.length > 0) {
        isTestingSchedule = true;
      }
      if (obj.testTwo && obj.testTwo.length > 0) {
        isTestingSchedule = true;
      }
      if (isTestingSchedule && !isZoneLevel) {
        const testsTypes = [...obj.testOne, ...obj.testTwo].filter((f) => !f.testNow).map((m) => m.label);
        if (testsTypes.length === 1) {
          obj.tests = testsTypes[0];
        }
        if (testsTypes.length > 1) {
          obj.tests = "Multi";
        }
      }
      if (!obj.qaDone && isTodaySchedule && obj.dailyTask && obj.dailyTask.length > 0) {
        obj.qaDone = "X";
      } else if (obj.qaDone && obj.manualCheckIn) {
        obj.qaDone = "M";
      } else if (obj.qaDone && isTodaySchedule) {
        obj.qaDone = "1";
      } else {
        obj.qaDone = "0";
      }
      if (!obj.testDone && isTodaySchedule && isTestingSchedule) {
        obj.testDone = "X";
      } else if (obj.testDone && isTodaySchedule) {
        obj.testDone = "1";
      } else {
        obj.testDone = "0";
      }
      if (obj.callTime === null) {
        obj.callTime = empDept?.callTime || null;
      }
      if (obj.checkIn) {
        obj.checkIn = "1";
      } else {
        obj.checkIn = "0";
      }
      obj.scheduleName = "";
      if (obj.scheduleLinked && obj.scheduleLinked.length > 0) {
        const filVal = schedules.filter((s) => obj.scheduleLinked.indexOf(s.id) !== -1);
        const questionOpt = filVal.find((f) => f.scheduleCategory === SCHEDULE_CATEGORY.QUESTIONS);
        const testOpt = filVal.find((f) => f.scheduleCategory === SCHEDULE_CATEGORY.TESTING);
        obj.schedulesOptions = {
          questionSch: questionOpt || "",
          testOptSch: testOpt || "",
          questionOpt: questionOpt?.dayOptions || [],
          testOpt: testOpt?.dayOptions || [],
        };
        const sch = filVal.map((m) => m.scheduleName);
        if (sch.length > 0) obj.scheduleName = sch.join(",");
      } else if (obj.scheduleID) {
        const sch = schedules.find((s) => s.id === emp.scheduleID);
        if (sch) {
          obj.scheduleName = sch.scheduleName;
        }
      }
    }

    if (isZoneLevel) {
      const validTest = [];
      const employeeDoneTest = obj.testHistory || [];
      if (program?.testValidity) {
        program.testValidity.forEach((h) => {
          const hisTest = employeeDoneTest.find((f) => f.value === h.value);

          if (hisTest) {
            const date = moment(hisTest.date?.substring(0, 10)).add(parseInt(h.days), "days").format("YYYY-MM-DD");
            console.log("date", date, program);

            if (moment().startOf("day").isSameOrBefore(date)) {
              console.log("testZone", h);
              validTest.push({
                ...h,
                id: uuidv4(),
                testNow: false,
                validDate: date,
                isDone: true,
                date: hisTest.date?.substring(0, 10),
                result: hisTest.result || "",
              });
            } else {
              validTest.push({ ...h, id: uuidv4(), testNow: false });
            }
          } else {
            validTest.push({ ...h, id: uuidv4(), testNow: false });
          }
        });
      }
      if (validTest.length === 1) {
        obj.tests = validTest[0].label;
      }
      if (validTest.length > 1) {
        obj.tests = "Multi";
      }
      console.log("validTest", validTest);
      obj.testTwo = validTest.filter((f) => f.location === "On Location");
      obj.testOne = validTest.filter((f) => f.location === "At Home");
      obj.validTests = [...validTest];
      if (validTest.length > 0 && validTest.every((e) => e.isDone)) {
        obj.testDone = "1";
      } else if (validTest.length > 0) {
        obj.testDone = "X";
      } else {
        obj.testDone = "0";
      }
      obj.qaDone = "0";
      if (obj.checkIn) {
        obj.checkIn = "1";
      } else {
        obj.checkIn = "0";
      }
      if (obj.isSchedule !== STATUS.zoneAssign) {
        obj.isSchedule = STATUS.removeProgram;
      }
    }
    return { ...obj, testingGroup, checkInDate };
  });
};

export const getValidName = (name) => {
  if (!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};

export const isValidIdNum = (id) => {
  if (!id) return false;
  if (new RegExp(/^(?!.*([a-zA-Z0-9])\1{4})[a-zA-Z0-9]{5,16}$/).test(id)) {
    return true;
  } else return false;
};

export const isValidPhoneWithCode = (phone) => {
  if (phone) return isPossiblePhoneNumber(phone);
  return false;
};

export const isValidZipCode = (code, zip) => {
  if (!zip) return false;
  const validate = postalCodes.validate(`${code}`, `${zip}`);
  console.log("zipValid", validate, code, zip);
  return typeof validate !== "string" ? validate : false;
};

export const getPhoneNo = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }

    const phone = formatPhoneNumberIntl(phone_number);
    const phoneArr = phone.split(" ");
    const countryCode = phoneArr[0];
    phoneArr.splice(0, 1);
    const phoneNo = phoneArr.join("");
    return [countryCode, phoneNo, phone];
  } catch (err) {
    console.log("Error", err);
  }
  return ["", phone_number_value, phone_number_value];
};
