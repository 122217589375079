import React from 'react'
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { DAYS_LABEL} from "constant";
const SchedulesDetails = (props) => {
  const {testOption, questionOption, day, test, days} = props;
  console.log("day",days, test)
  const TableRow = ({ item, index }) => {
   const quesSch= questionOption.find((f)=>f.id === item);
   const testSch = testOption.find((f)=>f.id === item);
   console.log('testSch',testSch);
    return (
      <tr>
        <td className='popOver-centered'>{DAYS_LABEL[item] || "-"}</td>
        <td className='popOver-centered'>{quesSch ? "Yes" : "No"}</td>
        <td className='popOver-centered'>{testSch ? "Yes" : "No"}</td>
        <td className={(testSch || test.length > 0) ? 'popOver-centered testTypeTable':"popOver-centered"}>{testSch || test.length > 0 ?( 
          <Table>
        <thead>
          <tr className="w-100">
            <th className='text-center w-25 border-0' >Test</th>
            <th className='text-center w-25 border-0'>Location</th>
            <th className='text-center w-25 border-0'>Done</th>
            <th className='text-center w-25 border-0'>Result</th>
          </tr>
        </thead>
        <tbody>
        {DAYS_LABEL[item] == day ? 
        test.length > 0 && test.map((t,i)=>(
          <tr key={i} className="w-100">
          <td  className='text-center w-25' >{t.label || t}</td>
          <td  className='text-center w-25'>{t.location || ""}</td>
          <td  className='text-center w-25'>{t.isDone ? "True": "False"}</td>
          <td  className='text-center w-25'>{t.result || "-"}</td>
          </tr>
        ))
        
       : testSch?.testType.map((t, i)=>(
          
          <tr key={i} className="w-100">
          <td  className='text-center w-25' >{t.label || t}</td>
          <td  className='text-center w-25'>{t.location || ""}</td>
          <td  className='text-center w-25'>{t.isDone ? "True": "False"}</td>
          <td  className='text-center w-25'>{t.result || "-"}</td>
          </tr>
        ))}
        </tbody>
        </Table>): "-"}</td>
      </tr>
    );
  };
  return (
    <div style={{ flex: 1 }}>
    <Row>
      <Col md="12">
          <Card className="strpied-tabled-with-hover m-0">
                <Card.Body>
                  <div className="form-group-wrapper m-0 d-block">
                    <div
                      className="table-responsive overFlow-y-hidden"
                      ><Table className="table">
                        <thead>
                          <tr>
                            <th className='popOver-centered border-0' >Day</th>
                            <th className='popOver-centered border-0'>Question</th>
                            <th className='popOver-centered border-0'>Test</th>
                            <th className='popOver-centered border-0'>Test Types</th>
                          </tr>
                        </thead>
                        <tbody>
                          {days.length > 0 &&
                          days.map((item, i) => {
                            return (
                              <TableRow key={i} index={i} item={item} />
                            )
                          })

                          }
                        </tbody>
                      </Table>
                      </div>
                      </div>
                      </Card.Body>
                      </Card>
          </Col>
          </Row>
          </div>
  )
}

export default SchedulesDetails