import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { usePlacesWidget } from "react-google-autocomplete";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import ImageCropModal from "components/Input/ImageCropModal";
import { TIMEZONE_OPT, PROFILE_IMAGE_PATH, EXTRERNAL_IMAGE_PATH } from "constant";
import {
  validatePhone,
  isValidEmail,
  convertToLower,
  formatZip,
  getValidDep,
  getValidReg,
  getValidSpaces,
  formatZipCode,
  toTitleCase,
  searchPhone,
} from "../../utils";
import moment from "moment";
import api from "api";
import { AppContext } from "context/app-context";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import PhoneNoInput from "../Phone/PhoneNoInput";
import Default_Profile from "assets/img/default_profile.png";
import countriesOption from "countries";
import EditableDropDown from "components/Dropdown/EditableDropDown";
import CustomDropDown from "components/Dropdown/CustomDropDown";
import localnoOptions from "localno";
import QrCodeCard from "components/QrCard/QrCodeCard";
import ImageInput from "components/Input/ImageInput";
import EmployeeSearchRecord from "components/EmployeeSearchRecord";
import Loader from "components/Loader/Loader";
import RadioButton from "../RadioButton/RadioButton";
const options = {
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const EmployeeModal = (props) => {
  const { user, handleSave, handleClose } = props;
  const appContext = useContext(AppContext);
  const [departments, setDepartments] = useState([]);
  const [newUser, setNewUser] = useState(user);
  const [address, setAddress] = useState(null);
  const [show, setShow] = useState("");
  const [locations, setLocations] = useState([]);
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [copyImage, setCopyImage] = useState(Default_Profile);
  const [showImageCroper, setShowImageCropper] = useState(false);
  const [regions, setRegions] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [showPopOver, setShowPopOver] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVal, setSelectedVal] = useState(user.isNew ? user.department : "");
  const [selectSearchdata, setSelectSearchData] = useState(null);
  const firstNameRef = useRef();
  const childNode = useRef();
  useEffect(() => {
    setNewUser(user);
    if (!user.isNew && user.picture) {
      setProfileImage(`${PROFILE_IMAGE_PATH}${user.picture}`);
    }
  }, [user]);
  useEffect(() => {
    if (selectedVal) {
      const calltime = departments.find((dpt) => selectedVal === dpt.id)?.callTime || "";
      const timeZone = departments.find((dpt) => selectedVal === dpt.id)?.deptTimeZone || null;
      setTimeZone(timeZone);
      const obj = { ...newUser, callTime: calltime };
      setNewUser(obj);
    }
  }, [selectedVal, departments]);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    getRegionData();
    getDepartments();
    setLocations(appContext.locations);
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  }, []);
  useEffect(() => {
    if (timeZone === null || timeZone) {
      const obj = { ...newUser, empTZ: "" };
      setNewUser(obj);
    }
  }, [timeZone]);
  useEffect(() => {
    if (address) {
      setNewUser({ ...newUser, ...address });
    }
  }, [address]);

  const handleAddressUpdate = (place) => {
    if (!place || Object.keys(place).length === 1) return;

    const obj = {};
    let address1 = "";
    let postcode = "";
    for (const curr of place.address_components) {
      const type = curr.types[0];

      switch (type) {
        case "street_number": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "premise": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "plus_code": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "neighborhood": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_4": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_3": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "sublocality_level_2": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "route": {
          address1 += ` ${curr.short_name}`;
          break;
        }
        case "postal_code": {
          postcode = `${curr.long_name}`;
          break;
        }
        case "postal_code_suffix": {
          postcode = `${postcode}-${curr.long_name}`;
          break;
        }
        case "postal_town": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "locality": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "administrative_area_level_1": {
          Object.assign(obj, { city: obj.city || curr.long_name, state: curr.short_name });
        }
        case "country": {
          Object.assign(obj, { country: curr.long_name });
          break;
        }
      }
    }
    Object.assign(obj, { street: address1.trim() || place.name, zip: postcode });
    setAddress({ ...obj });
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc",
    onPlaceSelected: (place) => {
      handleAddressUpdate(place);
    },
    options,
  });

  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartments(data);
  };
  const getRegionData = async () => {
    const data = await api.getRegions();
    setRegions(data);
  };

  const onHandleSave = async (isConfirm, newUser) => {
    if (
      isConfirm &&
      newUser.callTime !== user.callTime &&
      newUser.isSchedule === 1 &&
      appContext.company?.programLevel !== "Zone"
    ) {
      setOpenConfirmation(true);
      return;
    }

    if (!newUser.firstName || newUser.firstName === " ") {
      setShow("First name is required");
      return;
    }

    if (!newUser.lastName || newUser.lastName === " ") {
      setShow("Last name is required");
      return;
    }

    if (!newUser.email || newUser.email === " ") {
      setShow("Email name is required");
      return;
    }

    if (!isValidEmail(newUser.email)) {
      setShow("Invalid Email");
      return;
    }

    if (!newUser.phoneNumber || newUser.phoneNumber === " ") {
      setShow("Phone number is required");
      return;
    }
    if (!validatePhone(newUser.phoneNumber)) {
      setShow("Invalid Phone Number");
      return;
    }

    if (!newUser.location || newUser.location === " ") {
      setShow("Office location is required");
      return;
    }
    if (!newUser.region || newUser.region === " ") {
      setShow("Region is required");
      return;
    }

    if (!newUser.gender) {
      setShow("Required gender");
      return;
    }

    if (!newUser.department || newUser.department === " ") {
      setShow("Department is required");
      return;
    }
    const findVal = await appContext.employees.find((curr) => curr.phoneNumber === newUser.phoneNumber);

    if ((findVal && newUser.id && newUser.id !== findVal.id) || (findVal && newUser.isNew)) {
      setShow("Employee is Already Exist");
      return;
    }
    if (!newUser.callTime) {
      newUser.callTime = departments.find((dpt) => newUser.department === dpt.id)?.callTime || "";
    }
    if (!newUser.callTime) {
      setShow(`Please add Call Time ${newUser.departmentName} Department in settings`);
      return;
    }
    if (newUser.empTZ) {
      const deptTimeZone = departments.find((dpt) => newUser.department === dpt.id)?.deptTimeZone || "";
      if (newUser.empTZ === deptTimeZone) newUser.empTZ = "";
    }
    const phone = formatPhoneNumberIntl(newUser.phoneNumber);
    const phoneArr = phone.split(" ");
    newUser.countryCode = phoneArr[0];
    if (profileImage instanceof Object) {
      Object.assign(newUser, { ...newUser, profileImage: profileImage });
    }
    handleSave(newUser);
    handleClose();
  };
  const handleCropDone = (isConfirm, fileData) => {
    setShowImageCropper(false);
    if (isConfirm && fileData) {
      setProfileImage(new File([fileData], "profileImage.jpeg"));
    } else {
      setProfileImage(profileImage);
    }
  };

  const populate = (record) => {
    console.log("record", record);
    setShowPopOver(false);
    setSelectSearchData(null);
    const obj = {
      ...newUser,
      ...record,
      firstName: record.first,
      lastName: record.last,
      isNew: true,
      gender: record.sex,
      phoneNumber: searchPhone(record.phone_number),
      country: record.country ? record.country : "United States",
    };
    console.log("obj", obj);
    setNewUser(obj);
  };
  const searchPreReg = async (data, type) => {
    if (!data) return;
    setLoading(true);
    let phoneNo = data;
    if (phoneNo && type === "phone") {
      const phone = formatPhoneNumberIntl(data);
      console.log("phone", phone);
      const phoneArr = phone.split(" ");
      phoneArr.shift();
      phoneNo = phoneArr.join("");
    }
    let env = "";
    if (window.location.host.includes("localhost")) {
      env = "localhost";
    } else {
      env = "remote";
    }
    let record = [];
    try {
      record = await api.getPreRegisterData(phoneNo, env);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }

    console.log("record", record);
    if (record && record.length === 1) {
      populate(record[0]);
    }
    if (record && record.length > 1) {
      setRecordData(
        record.map((e) => {
          return { ...e, first: toTitleCase(e.first), last: toTitleCase(e.last) };
        })
      );
      setShowPopOver(!showPopOver);
    }
  };
  console.log("newUser", newUser);

  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      const obj = { ...newUser, callTime: user.callTime, onAlert: true };
      onHandleSave(false, obj);
    } else {
      onHandleSave(false, newUser);
    }
  };
  const handlePhoneNo = (e) => {
    setNewUser({
      ...newUser,
      phoneNumber: e,
    });
  };
  const getTimeZone = () => {
    const str = newUser.empTZ || timeZone;
    console.log("str", str);
    if (!str) return null;
    return { value: str, label: str };
  };

  const handleNewItem = (item) => {
    const arr = [...departments, item];
    setDepartments(arr);
  };

  const getEmployeeDepartment = () => {
    if (newUser.department) {
      const dept = departments.find((dpt) => newUser.department === dpt.id);
      if (dept) return { value: dept.id, label: dept.name };
      return "";
    }
    return "";
  };

  return (
    <>
      <Modal
        show
        animation={true}
        size="xl"
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newUser.isNew ? "Create Employee" : "Update Employee"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper">
            <div className="leftSide">
              {!newUser.isNew && (
                <>
                  <div className="upload-file-section d-flex align-items-center justify-content-between">
                    <ImageInput
                      type="profile"
                      setSelectedImage={setProfileImage}
                      selectedImage={profileImage}
                      showCropDialog={setShowImageCropper}
                      setCopyImage={setCopyImage}
                    />
                    <div className="">
                      <button className="headerButton btn-fill btn btn-secondary mr-0" onClick={() => window.print()}>
                        Print ID Badge
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">First Name: </label>
                  <input
                    ref={firstNameRef}
                    value={newUser.firstName}
                    className="modalInput"
                    placeholder="First Name"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, firstName: getValidDep(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Last Name: </label>
                  <input
                    value={newUser.lastName}
                    className="modalInput"
                    placeholder="Last Name"
                    onChange={(e) => setNewUser({ ...newUser, lastName: getValidDep(e.target.value) })}
                  />
                </div>
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">DOB: </label>
                <DatePicker
                  className="w-100"
                  openCalendarOnFocus={false}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  format="MM/dd/y"
                  onChange={(e) =>
                    setNewUser({
                      ...newUser,
                      dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                    })
                  }
                  value={
                    newUser.dob && new Date(newUser.dob) != "Invalid Date"
                      ? moment(newUser.dob, "YYYY-MM-DD").toDate()
                      : null
                  }
                  maxDate={new Date()}
                  minDate={new Date("1900-01-01")}
                />
              </div>
              <div className="form-group-wrapper position-relative">
                <label className="modalLineHeaders">Email: </label>
                <input
                  value={newUser.email}
                  className={`modalInput ${newUser.isNew && "pd-right-30"}`}
                  placeholder="Email"
                  style={{
                    width: "100%",
                    flexShrink: "initial",
                  }}
                  onChange={(e) => setNewUser({ ...newUser, email: convertToLower(e.target.value) })}
                />
                {newUser.isNew && (
                  <i
                    ref={childNode}
                    className="fas fa-search cursor-pointer"
                    onClick={() => searchPreReg(newUser.email, "email")}
                  />
                )}
              </div>

              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone #: </label>
                <PhoneNoInput
                  cssClass={newUser.isNew && "pd-right-30"}
                  handleChange={handlePhoneNo}
                  value={newUser.phoneNumber}
                />
                {newUser.isNew && (
                  <i
                    ref={childNode}
                    className="fas fa-search cursor-pointer"
                    onClick={() => searchPreReg(newUser.phoneNumber, "phone")}
                  />
                )}
              </div>
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Job Title: </label>
                <input
                  value={newUser.jobTitle}
                  className="modalInput"
                  placeholder="Job Title"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => setNewUser({ ...newUser, jobTitle: getValidSpaces(e.target.value) })}
                />
              </div>

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Address: </label>
                <input
                  ref={ref}
                  value={newUser.street}
                  placeholder="Enter Address"
                  className="modalInput"
                  onChange={(e) => setNewUser({ ...newUser, street: getValidDep(e.target.value) })}
                />
                {/* <input
                value={newUser.street}
                ref={ref}
                className="modalInput"
                placeholder="Address"
                style={{
                  width: "100%",
                }}
                onChange={(e) => setNewUser({ ...newUser, street: toTitleCase(e.target.value) })}
              /> */}
              </div>
            </div>
            <div className="rightSide">
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">City: </label>
                  <input
                    value={newUser.city}
                    className="modalInput"
                    placeholder="City"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, city: getValidDep(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">State: </label>
                  <input
                    value={newUser.state}
                    className="modalInput"
                    placeholder="State"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val) val = val.toUpperCase();
                      setNewUser({ ...newUser, state: val });
                    }}
                  />
                </div>
              </div>
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Zip Code: </label>
                  <input
                    type="text"
                    value={newUser.zip}
                    className="modalInput"
                    placeholder="XXXXX-XXXX"
                    maxLength={10}
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, zip: formatZipCode(e.target.value) })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Country: </label>

                  <Select
                    options={countriesOption}
                    blurInputOnSelect={true}
                    defaultValue={{ label: "United States", value: "United States" }}
                    menuPlacement="auto"
                    placeholder="Select Country"
                    className="w-100"
                    value={newUser.country ? { label: newUser.country, value: newUser.country } : null}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        country: e.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Office: </label>
                  <CustomDropDown
                    data={locations.map((t) => {
                      return { value: getValidReg(t.name), label: getValidReg(t.name) };
                    })}
                    title="Select Location"
                    selectedOption={newUser.location}
                    onOptionChange={(val) => setNewUser({ ...newUser, location: val.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Region: </label>
                  <CustomDropDown
                    data={regions.map((t) => {
                      return { value: getValidReg(t.name), label: getValidReg(t.name) };
                    })}
                    title="Select Region"
                    selectedOption={newUser.region}
                    onOptionChange={(val) => setNewUser({ ...newUser, region: val.value })}
                  />
                </div>
              </div>

              <div className="form-group-wrapper emp-select-area">
                <label className="modalLineHeaders emp-gender-mb">Gender: </label>
                <div className="d-flex justify-content-between w-100 flex-wrap">
                  <RadioButton
                    name="gender"
                    id="male"
                    label="Male"
                    checked={newUser?.gender === "M" ? true : false}
                    handleChange={(e) => setNewUser({ ...newUser, gender: e.target.checked ? "M" : "" })}
                  />
                  <RadioButton
                    name="gender"
                    id="female"
                    checked={newUser?.gender === "F" ? true : false}
                    label="Female"
                    handleChange={(e) => setNewUser({ ...newUser, gender: e.target.checked ? "F" : "" })}
                  />
                  <RadioButton
                    id="other"
                    checked={newUser?.gender === "X" ? true : false}
                    name="gender"
                    label="Not Specified"
                    handleChange={(e) => setNewUser({ ...newUser, gender: e.target.checked ? "X" : "" })}
                  />
                </div>
              </div>
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Call Time: </label>
                  <input
                    type="time"
                    id="callTime"
                    value={newUser.callTime}
                    className="modalInput"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, callTime: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Department: </label>
                  {/* <EditableDropDown
                data={departments.map((t) => {
                  return { value: t.id, label: toTitleCase(t.name) };
                })}
                title="Select Department"
                selectedOption={newUser.department}
                onOptionChange={(val) => setNewUser({ ...newUser, department: val.value })}
                setSelectedVal={setSelectedVal}
                addNewItem={handleNewItem}
              /> */}
                  <Select
                    options={departments.map((t) => {
                      return { value: t.id, label: getValidReg(t.name) };
                    })}
                    blurInputOnSelect={true}
                    menuPlacement="auto"
                    placeholder="Select Department"
                    className="w-100"
                    value={getEmployeeDepartment()}
                    onChange={(val) => {
                      setSelectedVal(val.value);
                      setNewUser({ ...newUser, department: val.value, departmentName: val.label });
                    }}
                  />
                </div>
              </div>
              {/* <div className="form-group-wrapper">
                <label className="modalLineHeaders">Time Zone: </label>
                <Select
                  options={TIMEZONE_OPT}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder="Select TimeZone"
                  className="w-100"
                  value={getTimeZone()}
                  onChange={(e) => setNewUser({ ...newUser, empTZ: e.value })}
                />
              </div> */}
              <div className={!newUser.isNew ? "first-last-name-wrapper" : ""}>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">License/SSN: </label>
                  <input
                    value={newUser.idNumber}
                    className="modalInput"
                    maxLength={16}
                    placeholder="ID Number"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, idNumber: e.target.value })}
                  />
                </div>
                {!newUser.isNew && (
                  <div className="form-group-wrapper">
                    <label className="modalLineHeaders">schrID: </label>
                    <input
                      value={newUser.schrID}
                      readOnly
                      className="modalInput"
                      placeholder="schrID Number"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="first-last-name-wrapper mb-3">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders emp-gender-mb">Vaccinated: </label>
                  <div className="d-flex align-items-center justify-content-between">
                    <RadioButton
                      name="isVaccinated"
                      id="vaccinated-yes"
                      checked={newUser.isVaccinated}
                      label="Yes"
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          isVaccinated: e.target.checked && true,
                        })
                      }
                    />
                    <RadioButton
                      label="No"
                      name="isVaccinated"
                      id="vaccinated-no"
                      checked={!newUser.isVaccinated}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          isVaccinated: e.target.checked && false,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders emp-gender-mb">Union Member: </label>
                  <div className="d-flex align-items-center justify-content-between">
                    <RadioButton
                      label="Yes"
                      name="Union"
                      id="union-yes"
                      checked={newUser.unionNo}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          unionNo: e.target.checked && true,
                        })
                      }
                    />
                    <RadioButton
                      label="No"
                      name="Union"
                      id="union-no"
                      checked={!newUser.unionNo}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          unionNo: e.target.checked && false,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {newUser.unionNo && (
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Local #: </label>
                  <Select
                    options={localnoOptions}
                    blurInputOnSelect={true}
                    menuPlacement="auto"
                    placeholder="Select Local Number"
                    className="w-100"
                    value={newUser.localNo ? { label: newUser.localNo, value: newUser.localNo } : null}
                    onChange={(e) =>
                      setNewUser({
                        ...newUser,
                        localNo: e.value,
                      })
                    }
                  />
                </div>
              )}
              {showPopOver && recordData.length > 1 && (
                <EmployeeSearchRecord
                  data={recordData}
                  handleSelect={populate}
                  handleClose={() => {
                    setShowPopOver(false), setSelectSearchData(null);
                  }}
                  setSelectSearchData={setSelectSearchData}
                  selectSearchdata={selectSearchdata}
                />
              )}
              {openConfirmation && (
                <ConfirmationModal
                  show={openConfirmation}
                  title="Update Call Time"
                  message={`${newUser.firstName} ${newUser.lastName} is currently in an active program. Update current program call time?`}
                  handleConfirm={handleConfirm}
                  handleClose={() => setOpenConfirmation(false)}
                  closeBtn={true}
                />
              )}
            </div>
            {showImageCroper && (
              <ImageCropModal selectedImage={copyImage} handleConfirm={handleCropDone}></ImageCropModal>
            )}
            {loading && <Loader />}
            <div id="section-to-print" className="printSide">
              <QrCodeCard profileImage={profileImage} newUser={newUser} appContext={appContext} />
            </div>
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {" "}
          {show.length > 0 && <strong style={{ color: "Red" }}>{show}</strong>}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(true, newUser)}
          >
            {newUser.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeModal;
