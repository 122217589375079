import React, { useState, useContext, useRef } from "react";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import TableRow from "./TableRow";
import { AppContext } from "context/app-context";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import API from "api";
import {
  calculateTdWidth,
} from "utils";
import Loader from "components/Loader/Loader";

const TimerTest = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [departments, setDepartments]= useState([])

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 50, 4);

  const [loading, setLoading] = useState();
  const [list, setList] = useState([]);
  const [editObj, setEditObj] = useState(null);
  const appContext = useContext(AppContext);
  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartments(data);
  };

  useEffect(() => {
    getList();
    getDepartments();
  }, []);

  
  const getList = async () => {
    setList(await API.getTestTypes());
  };
  

  const handleEdit = (item) => {
    setEditObj(item);
  };
  const handleSave = async (item) => {
    const obj = { ...item };
    setEditObj(null);
    setLoading(true);
    try {
      await API.updateTestTypes(obj);
      setLoading(false);
      setList(await API.getTestTypes());
      appContext.showSuccessMessage("Test Type updated succesfully");
    } catch (error) {
      setEditObj(obj);
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setEditObj(null);
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Test Settings
                </Card.Title>
                
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table>
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <th
                          style={{
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="name">Name</span>
                          </div>
                        </th>
                        <th
                          style={{
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Total Time">Total Time</span>
                          </div>
                        </th>
                        <th
                          style={{
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="First Alert">First Alert</span>
                          </div>
                        </th>
                        <th
                          style={{
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Second Alert">Second Alert</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, i) => {
                        return (
                          <TableRow
                            key={i}
                            item={item}
                            handleEdit={handleEdit}
                          />
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader/>
      )}
      {editObj && (
        <TestTypeModal
          item={editObj}
          handleSave={handleSave}
          handleClose={handleClose}
        />
      )}
    
    </div>
  );
};

export default TimerTest;
