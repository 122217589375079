import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";
import { formatPhoneNumber, formatDateMDYTime, formatDate } from "utils";
import moment from "moment";
import { formatTimeZone } from "utils";

const ExportToExcel = (props) => {
  const { items, selectedItem, title, appContext, filter, timeFilter, sortBy } = props;
  const exportData = () => {
    if (!selectedItem) return items;
    return items.map((item) => {
      let obj = {};
      selectedItem.forEach((c) => {
        if (c.itemKey === "isSchedule") {
          const val = item[c.itemKey] == "1" ? "✔" : item[c.itemKey] == "2" ? "⚠" : "";
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "testDone") {
          Object.assign(obj, { [c.itemKey]: item[c.itemKey] === "1" ? "✔" : item[c.itemKey] === "X" ? "X" : "", });
        } else if (c.itemKey === "qaDone") {
          Object.assign(obj, {
            [c.itemKey]: item[c.itemKey] === "1" || item[c.itemKey] === "M" ? "✔" : item[c.itemKey] === "X" ? "X" : "",
          });
        } else if (c.itemKey === "callTime") {
            Object.assign(obj, {
              [c.itemKey]: `${item[c.itemKey]}` ,
            });
        } else if (c.itemKey === "isVaccinated") {
          Object.assign(obj, { [c.itemKey]: item[c.itemKey] ? "Yes" : "No" });
        } else if (c.itemKey === "phoneNumber") {
          const val = formatPhoneNumber(item[c.itemKey]);
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "updatedAt") {
          const val = formatDate(item[c.itemKey]);
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "checkIn") {
          const val = item[c.itemKey] == "1" ? "✔" : "";
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "onBoardingTesting") {
          const val = item[c.itemKey] && item[c.itemKey].isDone ? "✔": item[c.itemKey] && !item[c.itemKey].isDone ? "X" : "";
          Object.assign(obj, { [c.itemKey]: val });
        } else {
          Object.assign(obj, { [c.itemKey]: item[c.itemKey] });
        }
      });
      return obj;
    });
  };
  const handleExportToExcel = () => {
    if (items.length > 0) {
      const data = exportData();
      console.log("data",data);
      const colLength = Object.keys(data[0]).length;
      const rowLength = Object.keys(data).length;

      const filters = {
        updatedAt: { data: undefined, label: "Updated At" },
        firstName: { data: filter?.firstName, label: "First Name" },
        lastName: { data: filter?.lastName, label: "Last Name" },
        email: { data: filter?.email, label: "Email" },
        phoneNumber: { data: filter?.phoneNumber, label: "Phone" },
        phone: { data: filter?.phone, label: "Phone Number" },
        street: { data: filter?.street, label: "Address" },
        dob: { data: filter?.dob, label: "Date of Birth" },
        jobTitle: { data: filter?.jobTitle, label: "Job Title" },
        scheduleName: { data: filter?.scheduleName, label: "Schedule" },
        isVaccinated: { data: filter?.isVaccinated, label: "Vaccinated" },
        departmentName: { data: filter?.departmentName, label: "Department" },
        gender: { data: filter?.gender, label: "Gender" },
        region: { data: filter?.region, label: "Region" },
        programName: { data: filter?.programName, label: "Program Name" },
        zoneColor: { data: undefined, label: "Zone Color" },
        location: { data: filter?.location, label: "Office" },
        callTime: { data: filter?.callTime, label: "Call Time" },
        schrID: { data: filter?.schrID, label: "SCHR ID" },
        testingGroup: { data: filter?.testingGroup, label: "Screening Type" },
        onBoardingTesting: { data: undefined, label: "Test Now" },
        qaDone: { data: filter?.qaDone, label: "Q/A" },
        localNo: { data: filter?.localNo, label: "Local #" },
        testDone: { data: filter?.testDone, label: "Test" },
        tests: { data: undefined, label: "Test Type" },
        associated: { data: filter?.associated, label: "Associated" },
        checkIn: { data: filter?.checkIn, label: "Check In" },
        role: { data: filter?.role, label: "Role" },
        status: { data: filter?.status, label: "Status" },
        isSchedule: { data: filter?.isSchedule, label: "Program Status" },
        logDate: { data: undefined, label: "Date" },
      };
      console.log("selectItem", selectedItem, data);
      const arr = Object.keys(data[0]).map((c) => filters[c]?.label || "");

      const columnsWidth = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === "email" || arr[i] === "testingGroup") {
          columnsWidth.push({ wch: 18 });
          continue;
        }
        if (
          arr[i] === "street" ||
          arr[i] === "isSchedule" ||
          arr[i] === "dob" ||
          arr[i] === "phoneNumber"
        ) {
          columnsWidth.push({ wch: 15 });
          continue;
        }
        if (arr[i] === "scheduleName" || arr[i] === "departmentName") {
          columnsWidth.push({ wch: 13 });
          continue;
        }
        if (arr[i] === "SCREENING TYPE" || arr[i] === "programName" ) {
          columnsWidth.push({ wch: 16 });
          continue;
        }
        columnsWidth.push({ wch: 12 });
      }

      function gapFilteredVal(filters) {
        var str = "";
        for (const [key, value] of Object.entries(filters)) {
          if (key === "isVaccinated" && value.data !== undefined && value.data.length > 0) {
            const val = value.data === "1" ? "Yes" : "No";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (key === "testDone" && value.data !== undefined && value.data.length > 0) {
            const val = value.data === "1" ? "✔" : value.data === "X" ? "X" : "Empty";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (key === "qaDone" && value.data !== undefined && value.data.length > 0) {
            const val = value.data === "1" || value.data === "M" ? "✔" : value.data === "X" ? "X" : "Empty";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (key === "isSchedule" && value.data !== undefined && value.data.length > 0) {
            const val = value.data == "1" ? "✔" : value.data == "2" ? "⚠" : "Empty";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (key === "checkIn" && value.data !== undefined && Object.keys(value.data).length > 0) {
            const val = value.data == "1" ? "✔" : "Empty";
            // const { startDate, endDate } = value.data;
            // const val = `${moment(startDate._d).format("MMM DD, YYYY")} to ${moment(endDate._d).format(
            //   "MMM DD, YYYY"
            // )}`;
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (value.data !== undefined && value.data.length > 0) {
            str += `${value.label} - ${value.data}, `;
          }
        }
        return str;
      }
      // Function for gap in excel row skip rows
      // function create_gap_rows(ws, nrows) {
      //   var ref = XLSX.utils.decode_range(ws["!ref"]); // get original range
      //   ref.e.r += nrows; // add to ending row
      //   ws["!ref"] = XLSX.utils.encode_range(ref); // reassign row
      // }
      const sortedVal = filters[sortBy];
      var wsrows = [
        { hpx: 80, level: 1 }, // "points"
      ];
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: colLength - 1 } }];

      const fileName = `SafeCamp.xlsx`;
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let dateRange = "";
      if (timeFilter) {
        const { start, end } = timeFilter;
        dateRange = `Date Range: ${start ? moment(start._id).format("MMM DD, YYYY") : "-"} to ${
          end ? moment(end._id).format("MMM DD, YYYY") : "-"
        }`;
      }
      const reportTitle = title || "Complete Tests Report";
      const filterVal = filter
        ? `Filtered By: ${Object.keys(filter).length > 0 ? gapFilteredVal(filters) : "None"}`
        : "";
      const sorted = filter ? `Sorted By: ${sortedVal ? sortedVal.label : "None"}` : "";
      const ws = XLSX.utils.aoa_to_sheet([[`${reportTitle} \n ${filterVal} \n ${sorted}`]], { origin: "A1" });
      XLSX.utils.sheet_add_aoa(ws, [arr], { origin: -1 });
      XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: -1 });
      ws["!rows"] = wsrows;
      ws["!merges"] = merge;
      ws["!cols"] = columnsWidth;
      ws["A1"].s = {
        alignment: {
          horizontal: "center",
          wrapText: true,
        },
        font: {
          name: "Calibri",
          sz: 16,
          bold: true,
          color: { rgb: "#000" },
        },
      };
      for (let i = 65; i < colLength + 65; i++) {
        ws[`${String.fromCharCode(i)}2`].s = {
          alignment: {
            horizontal: "center",
          },
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            underline: true,
            color: { rgb: "#000" },
          },
        };
      }
      // for (let i = 65; i < colLength + 65; i++) {
      //   for(let j=0; j<rowLength; j++){
      //   ws[`${String.fromCharCode(i)}${j+3}`].s = {
      //     alignment: {
      //       horizontal: "center",
      //       wrapText: true
      //     },
      //   };
      // }
      // }
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const fileContent = new Blob([excelBuffer], { type: fileType });
      try {
        FileSaver.saveAs(fileContent, fileName);
        appContext.showSuccessMessage("Excel file export successfully");
      } catch (err) {
        appContext.showErrorMessage("file can't be expored");
      }
    }
  };

  return (
    <Button
        variant="secondary"
        className="headerButton btn-fill" onClick={handleExportToExcel}>
      Export to Excel
    </Button>
  );
};

export default ExportToExcel;
