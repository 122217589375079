import React, { useState} from "react";
import { Modal, Button } from "react-bootstrap";
import ErrorMessage from "components/Message/ErrorMessage";
import { AppContext } from "context/app-context";
const ClearedProgramModal = (props) => {
  const { handleConfirm } = props;
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const onHandleConfirm = () => {
    if (!reason ) {
      setError("Please enter the reason");
      return;
    }
    handleConfirm(true, reason);
  };
 
  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleConfirm(null);
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
        Cleared Program Reason
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>

          <div className="form-group-wrapper">
            <label className="modalLineHeaders"> Reason: </label>

            <textarea className="modalInput w-75" onChange={(e) => setReason(e.target.value)}>
              {reason}
            </textarea>
          </div>
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleConfirm(null);
          }}
        >
          Cancel
        </Button>
        <Button variant="secondary"
         className="headerButton btn-fill" onClick={() => onHandleConfirm()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClearedProgramModal;
