import ExcelJS from "exceljs";
import React, { useState, useContext , useEffect} from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Button } from "react-bootstrap";
import { AppContext } from "context/app-context";
import moment from "moment";

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const dateArray = [];
const monthArray = [];
const dayArray = [];
let headerKeys = [];
let selectedEndDate;
let selectedStartDate;
let Difference_In_Days;
const ExportSelectedData = (props) => {
  const appContext = useContext(AppContext);
  const { uniquePrograms, filteredUsers } = props;
  const [selectedStartDateType, setSelectedStartDateType] = useState();
  const [item, setItem] = useState({});
  useEffect(()=>{
    handleApply()
  },[])
  const handleCancel = (event, picker) => {
    picker.element.val("");
    setDateRange({});
  };
  const handleApply = (event, picker) => {
    // picker.element.val(
    //   picker.startDate.format("MM/DD/YYYY") +
    //     " - " +
    //     picker.endDate.format("MM/DD/YYYY")
    // );
    selectedStartDate = moment().toDate();
    selectedEndDate = moment().add(13,'days').endOf("day").toDate();
    setSelectedStartDateType(typeof selectedStartDate);
    let Difference_In_Time =
      selectedEndDate.getTime() - selectedStartDate.getTime();
    Difference_In_Days = Math.trunc(Difference_In_Time / (1000 * 3600 * 24));
    dayArray.length = 0;
    monthArray.length = 0;
    dateArray.length = 0;
    if (typeof Difference_In_Days === "number") {
      for (let j = 0; j < Difference_In_Days + 1; j++) {
        let day = new Date(selectedStartDate);
        day.setDate(selectedStartDate.getDate() + j);
        dateArray.push(day.getDate());
        monthArray.push(day.getMonth() + 1);
        let dayValue = dayNames[day.getDay()];
        dayArray.push({ id: dayValue });
      }
    }
    const map = new Map();
    const result = dayArray.map((curr) => {
      map.set(curr.id, (map.get(curr.id) ?? -1) + 1);
      return { ...curr, id: `${curr.id}_${map.get(curr.id)}` };
    });

    headerKeys = Object.assign({}, result);
  };
  const UpdateValue = () => {
    if (item.endDate)
      return {
        startDate: formatDateMDY(item.startDate),
        endDate: formatDateMDY(item.endDate),
      };
    return {
      autoUpdateInput: false,
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Clear",
      },
    };
  };
  const exportData = () => {
    let rows = [];
    let rowNumbers = [];
    let departmentNameArray = [];
    if (
      typeof filteredUsers !== "undefined" &&
      typeof uniquePrograms !== "undefined"
    ) {
      for (let index = 0; index < Object.keys(uniquePrograms).length; index++) {
        let key = Object.keys(uniquePrograms)[index];
        const extractedData = filteredUsers.filter((user) => {
          return user.id === uniquePrograms[key][0].id;
        });
        departmentNameArray.push(extractedData[0].departmentName);
        rowNumbers.push(uniquePrograms[key].length);
        for (let i = 0; i < uniquePrograms[key].length; i++) {
          rows.push(uniquePrograms[key][i]);
        }
      }
    }
    let rowPositions = [3];
    if (rowNumbers.length > 0) {
      for (let j = 1; j < rowNumbers.length; j++) {
        rowPositions.push(rowPositions[j - 1] + 1 + rowNumbers[j - 1]);
      }
    }
    let zoneColorPosition = [4];
    let k = 5;
    while (zoneColorPosition.length <= rows.length - 1) {
      if (rowPositions.indexOf(k) === -1) {
        zoneColorPosition.push(k);
      }
      k++;
    }

    for (let m = 0; m < rows.length; m++) {
      const extractedDataRows = filteredUsers.filter((user) => {
        return user.id === rows[m].id;
      });
      rows[m].zoneColor = extractedDataRows[0].zoneColor;
    }
    let keys;
    let keyLimit = Math.trunc(dateArray.length / 7) + 1;

    for (let m = 0; m < rows.length; m++) {
      if ("endDate" in rows[m] && typeof selectedStartDate === "object") {
        if (selectedStartDate.getTime() < rows[m].endDate.getTime()) {
          for (let index = 0; index < rows[m].scheduleData.length; index++) {
            let key = rows[m].scheduleData[index].name;
            for (
              let i = 0;
              i < rows[m].scheduleData[index].testType.length;
              i++
            ) {
              for (let s = 0; s < keyLimit; s++) {
                keys = key.concat("_", s);
                if (typeof rows[m][keys] === "undefined") {
                  rows[m][keys] = "";
                }
                if (i === 0) {
                  rows[m][keys] = rows[m][keys].concat(
                    rows[m].scheduleData[index].testType[i].label
                  );
                } else {
                  rows[m][keys] = rows[m][keys].concat(
                    ",",
                    rows[m].scheduleData[index].testType[i].label
                  );
                }
              }
            }
          }
        }
      }
    }

    let index;
    for (let y = 0; y < rows.length; y++) {
      if ("endDate" in rows[y] && typeof selectedEndDate === "object") {
        if (selectedEndDate.getTime() > rows[y].endDate.getTime()) {
          let index = dateArray.indexOf(rows[y].endDate.getDate());

          for (let z = index + 1; z < Object.keys(headerKeys).length; z++) {
            if (`${headerKeys[z].id}` in rows[y]) {
              delete rows[y][headerKeys[z].id];
            }
          }
        }
      } else {
        index = y;
      }
    }

    handlerClickDownloadButton(
      rows,
      rowPositions,
      departmentNameArray,
      zoneColorPosition
    );
  };

  const handlerClickDownloadButton = async (
    rows,
    rowPositions,
    departmentNameArray,
    zoneColorPosition
  ) => {
    try {
      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet("sheet1");
      const worksheet = workbook.getWorksheet("sheet1");
      const headings = [
        { width: 12, header: "Zone", key: "programName" },
        { width: 12, header: "First Name", key: "firstName" },
        { width: 12, header: "Last Name", key: "lastName" },
        { width: 12, header: "Email", key: "email" },
        { width: 12, header: "Phone #", key: "phoneNumber" },
      ];

      for (let index = 0; index < dateArray.length; index++) {
        headings.push({
          width: 12,
          header: `${dateArray[index]}/${monthArray[index]}`,
          key: `${headerKeys[index].id}`,
        });
      }
      worksheet.columns = headings;
      worksheet.addRows(rows);
      let firstRow = ["", "", "", "", ""];
      for (let index = 0; index < dayArray.length; index++) {
        firstRow.push(dayArray[index].id);
      }
      worksheet.insertRow(1, firstRow);
      for (let index = 0; index < rowPositions.length; index++) {
        worksheet.insertRow(rowPositions[index], [
          `${departmentNameArray[index]}`,
        ]);
      }

      for (let i = 0; i < rowPositions.length; i++) {
        worksheet.getCell(`A${rowPositions[i]}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "000000" },
        };
        worksheet.getCell(`A${rowPositions[i]}`).font = {
          color: { argb: "FFFFFF" },
        };
        worksheet.getCell(`B${rowPositions[i]}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "000000" },
        };
        worksheet.getCell(`B${rowPositions[i]}`).font = {
          color: { argb: "FFFFFF" },
        };
        worksheet.getCell(`C${rowPositions[i]}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "000000" },
        };
        worksheet.getCell(`C${rowPositions[i]}`).font = {
          color: { argb: "FFFFFF" },
        };
        worksheet.getCell(`D${rowPositions[i]}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "000000" },
        };
        worksheet.getCell(`D${rowPositions[i]}`).font = {
          color: { argb: "FFFFFF" },
        };
      }
      let zoneColorArray = [];
      for (let r = 0; r < rows.length; r++) {
        let str = rows[r].zoneColor.replace(/\s/g, "");
        str = str.toLowerCase();
        let bgColor = "";
        switch (str) {
          case "orange":
            bgColor = "ffa500";
            break;
          case "black":
            bgColor = "000000";
            break;
          case "green":
            bgColor = "006600";
            break;
          case "blue":
            bgColor = "006699";
            break;
          case "gray":
            bgColor = "D8D8D8";
            break;
          case "deeppink":
            bgColor = "FF3366";
            break;
          case "purple":
            bgColor = "9933FF";
            break;
          case "brown":
            bgColor = "996633";
            break;
          default:
            bgColor = "FFFFFF";
            break;
        }
        zoneColorArray.push(bgColor);
      }
      for (let s = 0; s < zoneColorPosition.length; s++) {
        worksheet.getCell(`A${zoneColorPosition[s]}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: `${zoneColorArray[s]}` },
        };
      }
      worksheet.getRow(1).alignment = {
        horizontal: "center",
      };
      worksheet.getRow(2).alignment = {
        horizontal: "center",
      };

      let alphabetArray = [];
      let alphabetString = "";
      for (let i = 9, alphabet = ""; ++i < 36; ) {
        alphabetString = i.toString(36);
        alphabetString = alphabetString.toUpperCase();
        alphabetArray.push(alphabetString);
      }
      for (let o = 0; o < 5; o++) {
        worksheet.getCell(`${alphabetArray[o]}2`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "BEBEBE" },
        };
        worksheet.getCell(`${alphabetArray[o]}2`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
      for (let o = 5; o < 12; o++) {
        worksheet.getCell(`${alphabetArray[o]}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCC99" },
        };
        worksheet.getCell(`${alphabetArray[o]}2`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCC99" },
        };
        worksheet.getCell(`${alphabetArray[o]}1`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getCell(`${alphabetArray[o]}2`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
      for (let o = 12; o < 19; o++) {
        worksheet.getCell(`${alphabetArray[o]}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "99CCCC" },
        };
        worksheet.getCell(`${alphabetArray[o]}2`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "99CCCC" },
        };
        worksheet.getCell(`${alphabetArray[o]}1`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getCell(`${alphabetArray[o]}2`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
      for (let o = 19; o < 26; o++) {
        worksheet.getCell(`${alphabetArray[o]}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCC99" },
        };
        worksheet.getCell(`${alphabetArray[o]}2`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCC99" },
        };
        worksheet.getCell(`${alphabetArray[o]}1`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getCell(`${alphabetArray[o]}2`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      for (let o = 0; o < 6; o++) {
        worksheet.getCell(`A${alphabetArray[o]}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "99CCCC" },
        };
        worksheet.getCell(`A${alphabetArray[o]}2`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "99CCCC" },
        };
        worksheet.getCell(`A${alphabetArray[o]}1`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getCell(`A${alphabetArray[o]}2`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      for (let o = 6; o < 13; o++) {
        worksheet.getCell(`A${alphabetArray[o]}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCC99" },
        };
        worksheet.getCell(`A${alphabetArray[o]}2`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCC99" },
        };
        worksheet.getCell(`A${alphabetArray[o]}1`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getCell(`A${alphabetArray[o]}2`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      for (let o = 13; o < 19; o++) {
        worksheet.getCell(`A${alphabetArray[o]}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "99CCCC" },
        };
        worksheet.getCell(`A${alphabetArray[o]}2`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "99CCCC" },
        };
        worksheet.getCell(`A${alphabetArray[o]}1`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getCell(`A${alphabetArray[o]}2`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      for (let o = 19; o < 26; o++) {
        worksheet.getCell(`A${alphabetArray[o]}1`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCC99" },
        };
        worksheet.getCell(`A${alphabetArray[o]}2`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCC99" },
        };
        worksheet.getCell(`A${alphabetArray[o]}1`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        worksheet.getCell(`A${alphabetArray[o]}2`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }

      let format = "xlsx";
      const uint8Array =
        format === "xlsx"
          ? await workbook.xlsx.writeBuffer()
          : await workbook.csv.writeBuffer();
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "sampleData." + format;
      a.click();
      a.remove();
      appContext.showSuccessMessage(
        "Excel file export successfully !!"
      );
    } catch (err) {
      appContext.showErrorMessage(
        err.message
      );
    }
  };
  return (
    <>
      {selectedStartDateType === "object" && (
        <Button variant="secondary"
        className="headerButton btn-fill" onClick={exportData}>
          Export Manifest
        </Button>
      )}

      {/* <DateRangePicker
        onApply={handleApply}
        onCancel={handleCancel}
        initialSettings={UpdateValue()}
      >
        <input
          type="text"
          placeholder="Select Date Range"
          readOnly
          className="dateRangeInput col-4"
          defaultValue=""
        />
      </DateRangePicker> */}
    </>
  );
};
export default ExportSelectedData;
