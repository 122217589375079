import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import {
  SCHEDULE_TYPE_EVERY_DAY,
  SCHEDULE_TYPE_UNTIL_CANCEL,
  SCHEDULE_TYPE_SELECTED_DAYS,
  SCHEDULE_OPTIONS,
} from "constant";
import { formatDateMDY, userCompanyID, checkValidity } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import { getValidReg } from "utils";
import Message from "components/Message/Message";
import ScheduleItem from "components/Schedule/ScheduleItem";
import RadioButton from "../RadioButton/RadioButton";

const ScheduleModal = (props) => {
  const { handleClose, item, handleSave, scheduleCategory, schedules } = props;

  const [checked, setChecked] = useState(item.isNew ? SCHEDULE_TYPE_EVERY_DAY : item.scheduleType || "");
  const [name, setName] = useState(item.scheduleName || "");
  const [error, setError] = useState("");
  const [allChecked, setAllChecked] = useState(item.isNew ? true : item.length === 7 ? true : false);
  const [messagePopup, SetMessagePopup] = useState(false);
  const [message, setMessage] = useState("");
  const [Date, setDate] = useState(
    item.endDate
      ? { startDate: item.startDate, endDate: item.endDate }
      : item.startDate
      ? { startDate: item.startDate }
      : {}
  );
  const [dayOptions, setDayOptions] = useState(
    item.isNew
      ? SCHEDULE_OPTIONS.map((d) => {
          return { ...d, isChecked: true };
        })
      : item.dayOptions?.length > 0
      ? SCHEDULE_OPTIONS.map((d) => item.dayOptions.find((f) => f.id === d.id) || d)
      : SCHEDULE_OPTIONS
  );
  useEffect(() => {
    const allCheck = dayOptions.every((f) => f.isChecked);
    setAllChecked(allCheck);
  }, [dayOptions]);
  const UpdateValue = () => {
    if (item.endDate) return { startDate: formatDateMDY(item.startDate), endDate: formatDateMDY(item.endDate) };
    return {
      autoUpdateInput: false,
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Clear",
      },
    };
  };

  const singleDateUpdateValue = () => {
    if (item.startDate) return { startDate: formatDateMDY(item.startDate), singleDatePicker: true };
    return {
      singleDatePicker: true,
      autoUpdateInput: false,
      locale: {
        format: "DD/MM/YYYY",
        cancelLabel: "Clear",
      },
    };
  };
  const isExistName = () => {
    const isExist = schedules.filter(
      (d) =>
      d.scheduleCategory === scheduleCategory && userCompanyID.get() === d.companyID && d.id !== item.id && checkValidity(d.scheduleName) == checkValidity(name)
    );
    if (isExist && isExist.length > 0) return true;
    return false;
  };

  const onHandleSave = () => {
    if (!name) {
      setError("Enter the schedule name");
      return;
    }

    if (checked === "") {
      setError("select the schedule type");
      return;
    }

    if (
      (checked === SCHEDULE_TYPE_SELECTED_DAYS || checked === SCHEDULE_TYPE_EVERY_DAY) &&
      Object.keys(Date).length === 0
    ) {
      setError("select the start and end date");
      return;
    }
    if (checked === SCHEDULE_TYPE_UNTIL_CANCEL && Object.keys(Date).length === 0) {
      setError("select the start Date");
      return;
    }

    const dayData = dayOptions.filter((d) => d.isChecked);
    if (dayData.length === 0) {
      setError("select the Day");
      return;
    }
    if (scheduleCategory === "Testing") {
      for (let index = 0; index < dayData.length; index++) {
        const selOpt = dayData[index];
        for (let i = 0; i < selOpt.testType.length; i++) {
          const testTypeOpt = selOpt.testType[i];
          if (!testTypeOpt.value) {
            setError("please select Test Type");
            return;
          }
          if (!testTypeOpt.location) {
            setError("please Select location");
            return;
          }
        }
      }
    }

    const questionData =
      scheduleCategory === "Testing"
        ? dayData
        : dayData.map((d) => {
            return { id: d.id, name: d.name, isChecked: d.isChecked };
          });

    const obj = {
      isNew: item.isNew,
      scheduleName: name,
      scheduleType: checked,
      dayOptions: JSON.stringify(questionData),
      scheduleCategory: scheduleCategory,
      days: dayData.map((d) => d.id).join(","),
    };
    
    if (isExistName()) {
      setError("Scehdule name already exists");
      return;
    }
    if (!item.isNew) Object.assign(obj, { id: item.id });
    console.log(" schedules", obj)

    if (checked === SCHEDULE_TYPE_EVERY_DAY && Object.keys(Date).length > 0) {
      Object.assign(obj, {
        startDate: Date.startDate.substring(0, 10),
        endDate: Date.endDate.substring(0, 10),
      });
    } else if (checked === SCHEDULE_TYPE_SELECTED_DAYS && Object.keys(Date).length > 0) {
      Object.assign(obj, {
        startDate: Date.startDate.substring(0, 10),
        endDate: Date.endDate.substring(0, 10),
      });
    } else if (checked === SCHEDULE_TYPE_UNTIL_CANCEL && Object.keys(Date).length > 0) {
      Object.assign(obj, { startDate: Date.startDate.substring(0, 10), endDate: null });
    } else {
      Object.assign(obj, { startDate: null, endDate: null });
    }

    handleSave(obj);
    handleClose();
  };

  const handleSaveChange = (selectOpt) => {
    const objdata = [...dayOptions];
    objdata.splice(selectOpt.id, 1, selectOpt);
    setDayOptions(objdata);
  };
  console.log("dayoptipn", dayOptions);

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
    console.log("DateRange", picker.startDate, picker.endDate);
    setDate({ startDate: moment(picker.startDate).format(), endDate: moment(picker.endDate).format() });
  };
  const handleSingleDateApply = (event, picker) => {
    picker.element.val(picker.startDate.format("MM/DD/YYYY"));
    setDate({ startDate: moment(picker.startDate).format() });
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
    setDate({});
  };

  const onChangeType = (e) => {
    setChecked(e.target.value);
    if (e.target.value === SCHEDULE_TYPE_EVERY_DAY) {
      setDayOptions(
        dayOptions.map((d) => {
          return { ...d, isChecked: true };
        })
      );
    }
  };
  const handleAllSelect = (e) => {
    if (e.target.checked) {
      setDayOptions(
        dayOptions.map((d) => {
          return { ...d, isChecked: true };
        })
      );
    } else {
      setDayOptions(
        dayOptions.map((d) => {
          return { ...d, isChecked: false };
        })
      );
    }
    setAllChecked(e.target.checked);
  };

  return (
    <>
      <Modal className="scheduleModal" show onHide={handleClose} animation={true} size={"lg"} centered>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Select {scheduleCategory} Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Schedule Name: </label>
            <input
              value={name}
              className="modalInput"
              placeholder="Schedule Name"
              style={{
                width: "100%",
              }}
              onChange={(e) => setName(getValidReg(e.target.value))}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Type: </label>
            <Form className="d-block d-sm-flex align-items-center">
              <RadioButton
                  id="1st"
                  value={SCHEDULE_TYPE_EVERY_DAY}
                  name="scheduleList"
                  cssClass="mr-4"
                  label="Every Day"
                  checked={checked === SCHEDULE_TYPE_EVERY_DAY}
                  handleChange={onChangeType}
              />
              <RadioButton
                  id="2nd"
                  value={SCHEDULE_TYPE_SELECTED_DAYS}
                  name="scheduleList"
                  label="Select Days"
                  checked={checked === SCHEDULE_TYPE_SELECTED_DAYS}
                  handleChange={onChangeType}
              />
              {/* <div>
                <Form.Check.Input
                  id="3rd"
                  value={SCHEDULE_TYPE_UNTIL_CANCEL}
                  className="m-2"
                  name="scheduleList"
                  type="radio"
                  checked={checked === SCHEDULE_TYPE_UNTIL_CANCEL}
                  onChange={onChangeType}
                />
                <Form.Check.Label htmlFor="3rd" className="px-2 scheduleLabel">
                  Until Cancelled
                </Form.Check.Label>
              </div> */}
            </Form>
          </div>
          <>
            {(checked === SCHEDULE_TYPE_EVERY_DAY || checked === SCHEDULE_TYPE_SELECTED_DAYS) && (
              <div className="form-group-wrapper">
                <label>Start and End Date: </label>
                <div className="scheduleView">
                  <DateRangePicker onApply={handleApply} onCancel={handleCancel} initialSettings={UpdateValue()}>
                    <input
                      type="text"
                      placeholder="Select Date Range"
                      readOnly
                      className="dateRangeInput col-4"
                      defaultValue=""
                    />
                  </DateRangePicker>
                </div>
              </div>
            )}
            {checked === SCHEDULE_TYPE_UNTIL_CANCEL && (
              <div className="form-group-wrapper">
                <strong>Date: </strong>
                <div className="scheduleView">
                  <DateRangePicker
                    onApply={handleSingleDateApply}
                    onCancel={handleCancel}
                    initialSettings={singleDateUpdateValue()}
                  >
                    <input
                      type="text"
                      placeholder="Select Date"
                      readOnly
                      className="dateRangeInput col-4"
                      defaultValue=""
                    />
                  </DateRangePicker>
                </div>
              </div>
            )}
          </>
          {(checked === SCHEDULE_TYPE_UNTIL_CANCEL ||
            checked === SCHEDULE_TYPE_SELECTED_DAYS ||
            checked === SCHEDULE_TYPE_EVERY_DAY) && (
            <>
              <div className="scheduleView">
                <div className="row scheduleView-inner align-items-center flex-nowrap">
                  <div className="col-sm-12">
                    <div className="row schedules-header">
                      <div className="col-md-3">
                        <div className="weekDays-wrapper">
                          <Form.Check.Input
                              type="checkbox"
                              className="m-2"
                              id="all"
                              name="all"
                              checked={checked === SCHEDULE_TYPE_EVERY_DAY || allChecked}
                              disabled={checked === SCHEDULE_TYPE_EVERY_DAY}
                              onChange={handleAllSelect}
                          />
                          <label>Week Days</label>
                        </div>
                      </div>
                      {scheduleCategory === "Testing" && (
                          <>
                            <div className="col-md-4">
                              <div className="schedule-test-type">
                                <label>
                                  Test Type
                                </label>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="schedule-location">
                                <label>Location</label>
                              </div>
                            </div>
                          </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="itemRowWrapper">
                  {dayOptions.map((obj, index) => (
                      <ScheduleItem
                          key={obj.id}
                          handleSaveChange={handleSaveChange}
                          obj={obj}
                          scheduleCategory={scheduleCategory}
                          optionType={checked}
                          SetMessagePopup={SetMessagePopup}
                          setMessage={setMessage}
                      />
                  ))}
                </div>
              </div>
            </>
          )}
          {messagePopup && (
            <Message
              showAlert={messagePopup}
              message={message}
              handleClose={(confirm) => SetMessagePopup(confirm)}
              type="info"
              title="Information"
            />
          )}
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button style={{ marginBottom: 10 }} variant="secondary" className="modalButtons headerButton btn-fill" onClick={onHandleSave}>
            {item.isNew ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleModal;
