/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useState, useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import API from "api";
import { AppContext } from "../context/app-context";
import labRoutes from "labRoutes.js";
import adminRoutes from "adminRoutes";
import subAdminRoutes from "subAdminRoutes";
import zoneLevelRoutes from "zoneLevelRoutes";
import siteRoutes from "siteRoutes";
import sidebarImage from "assets/img/sidebar-3.jpg";
import { Hub } from "aws-amplify";
import { debounce } from "debounce";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { formatTest, medFlowInLocalStorage, userCompanyID } from "utils";

import { Auth } from "aws-amplify";
import { useIdleTimer } from "react-idle-timer";
import IdleModal from "components/IdleModal";
import { useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { Storage } from "aws-amplify";
import { AMAZON_CLIENT_RESULT } from "../constant";
import SweetAlert from "react-bootstrap-sweetalert";
import PDF from "components/PDF";
import ReactPDF from "@react-pdf/renderer";
import Loader from "components/Loader/Loader";
import AnalyticalDashbaord from "views/AnalyticalDashboard.js";
import awsconfig from "../aws-exports";
import { DataStore, syncExpression } from "@aws-amplify/datastore";
import {
  Test,
  Employee,
  Question,
  Schedule,
  Department,
  Locations,
  Regions,
  Programs,
  EmployeeQuestion,
  EmployeeDailyLog,
} from "../models";
import Employees from "views/Employees";

function Admin() {
  const timeout = 1800000;
  const [showIdleModal, setShowIdleModal] = useState(false);
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");
  const [leader, setLeader] = useState(true);
  const [timedOut, setTimedOut] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  let history = useHistory();

  const handleCloseModal = () => {
    setShowIdleModal(false);
  };

  const handleLogOut = async () => {
    setShowIdleModal(false);
    try {
      await DataStore.clear();
      await Auth.signOut();
      medFlowInLocalStorage.clear();
      userCompanyID.clear();
      handlePause();
      history.push("/login");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  const handleOnIdle = (event) => {
    setShowIdleModal(true);
  };

  const handleOnActive = (event) => {
    setTimedOut(false);
  };

  const handleOnAction = (event) => {
    setTimedOut(false);
  };

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();
  const handleStart = () => start();

  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
    getTotalIdleTime,
    getLastIdleTime,
    isIdle,
    pause,
    resume,
    start,
    isLeader,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: false,
    },
  });

  // useEffect(() => {
  //   setRemaining(getRemainingTime());
  //   setLastActive(getLastActiveTime());
  //   setElapsed(getElapsedTime());
  //   setInterval(() => {
  //     setRemaining(getRemainingTime());
  //     setLastActive(getLastActiveTime());
  //     setElapsed(getElapsedTime());
  //     setLeader(isLeader());
  //   }, 1000);
  // }, []);

  useEffect(() => {
    if (showIdleModal) {
      const timeout = setTimeout(() => {
        if (showIdleModal) {
          handleLogOut();
        }
      }, 300000); // TODO: - This time is the extra time you want to see how long they're idle for until they get logged out
      return () => clearTimeout(timeout);
    }
  }, [showIdleModal]);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [userRoutes, setUserRoutes] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [testFilter, setTestFilter] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [regions, setRegions] = useState([]);
  const [company, setCompany] = useState(null);
  const [successMessageText, setSuccessMessageText] = useState("");
  const [errorMessageText, setErrorMessageText] = useState("");
  const [settings, setSettings] = useState({});

  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.disabled) return null;
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} render={(props) => <prop.component {...props} />} key={key} />;
      } else {
        return null;
      }
    });
  };

  const fetchUserGroups = async () => {
    console.log("In Fetching User Record");
    const admins = await API.fetchUsersInGroup("Admins");
    const employees = await API.fetchUsersInGroup("Employees");
    const coordinators = await API.fetchUsersInGroup("Coordinators");
    const staffs = await API.fetchUsersInGroup("Staffs");
    return Promise.all([admins, employees, coordinators, staffs]).then((values) => [
      admins,
      employees,
      coordinators,
      staffs,
    ]);
  };

  const fetchAdmins = async () => {
    const admins = await API.fetchUsersInGroup("Admins");
    return admins;
  };

  const onSubscriptionTrigger = debounce(() => refreshDataOnSubscribe(), 6000);
  const refreshDataOnSubscribe = () => {
    console.log("REFRESH_LIST");
    fetchEmployees();
  };
  const [user, setUser] = useState();

  // useEffect(()=>{
  //   DataStore.stop();
  // },[])

  useEffect(() => {
    let subscription = null;
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      console.log("Adminusers", user);
      if (user) {
        if (user.isAdmin() || user.isLab() || user.isSite()) {
          console.log("companyIDs", user.companyID);
          let syncExpressionsByUserType = [];
          if (user.companyID) {
            syncExpressionsByUserType = [
              syncExpression(Test, () => {
                return (test) => test.companyID("eq", user.companyID);
              }),
              // syncExpression(Employee, () => {
              //   return (t) => t.or((test) => test.companyID("eq", user.companyID).isNew("eq", true));
              // }),
              syncExpression(Question, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
              syncExpression(Schedule, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
              syncExpression(Locations, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
              syncExpression(Department, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
              syncExpression(Regions, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
              syncExpression(Programs, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
            ];
          }

          syncExpressionsByUserType.push(
            syncExpression(EmployeeDailyLog, () => {
              return (test) => test.companyID("eq", user.companyID);
            })
          );
          // syncExpressionsByUserType.push(
          //   syncExpression(EmployeeQuestion, () => {
          //     return (test) => test.companyID("eq", userCompanyID.get());
          //   })
          // );
          DataStore.configure({
            maxRecordsToSync: 90000,
            syncPageSize: 2000,
            syncExpressions: syncExpressionsByUserType,
          });
          await DataStore.stop();
          await DataStore.start();
          setUser(user);
          handleStart();
        } else {
          setLoading(false);
          setShowAlert(true);
        }
      } else {
        setLoading(false);
        console.log("push to login");
        history.push("/login");
      }
    };
    getUSer().then((res) => {
      if (window.location.href("tcmatrix")) return;
      subscription = DataStore.observe(Employee).subscribe((msg) => {
        console.log("message subs ", msg);
        onSubscriptionTrigger();
      });
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  const fetchEmployees = async () => {
    const models = await API.getEmployees();
    setEmployees(models.filter((f) => f.email !== "adminmf@yopmail.com"));
  };
  const fetchLocations = async () => {
    const models = await API.getLocations();
    setLocations(models);
  };
  const fetchPrograms = async () => {
    const models = await API.getProgramSettings();
    setPrograms(models);
  };
  const fetchRegions = async () => {
    const models = await API.getRegions();
    setRegions(models);
  };
  const fetchSchedules = async () => {
    const models = await API.getSchedules();
    setSchedules(models);
  };

  const fetchQuestions = async () => {
    const models = await API.getQuestions();
    setQuestions(models);
  };
  const loadSetting = async () => {
    const setting = await API.getCompanySetting();
    setSettings(setting);
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const isZoneLevelCompany = () => (company?.programLevel || "Employee") === "Zone";

  const contextSwitch = (role) => {
    if (user?.isAdmin()) {
      return {
        company,
        user,
        users,
        employees,
        schedules,
        questions,
        locations,
        regions,
        programs,
        isZoneLevelCompany: () => isZoneLevelCompany(),
        resetCompanyInfo: () => getCompnayInfo(),
        resetSchedules: () => fetchSchedules(),
        resetQuestions: () => fetchQuestions(),
        resetEmployees: () => fetchEmployees(),
        resetUsers: () => setUsers(fetchUserGroups()),
        resetLocations: () => fetchLocations(),
        resetPrograms: () => fetchPrograms(),
        resetRegions: () => fetchRegions(),
        sendEmailToCrew: (tests) => sendResultOnEmail(tests),
        admins,
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
      };
    }
  };

  const sendResultOnEmail = (tests) => {
    if (tests.length === 0) return;
    setLoading(true);

    try {
      tests.forEach(async (test, index) => {
        const resp = await getLab(test);
        ReactPDF.pdf(<PDF test={test} testsLab={resp} parseTestResultForPDF={parseTestResultForPDF} />)
          .toBlob()
          .then(async (data) => {
            try {
              const fileName = `${test.id}-result.pdf`;
              await Storage.put(fileName, data);
              await API.sendEmailToCrew([{ email: test.email, fileName: `public/${fileName}` }]);
              await API.updateEmailStatus(test.id);
            } catch (error) {
              console.log("Error");
            }
            if (index === tests.length - 1) {
              setLoading(false);
            }
          });
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const getCompnayInfo = async () => {
    const comp = await API.getCompanySetting();
    setCompany(comp);
  };
  const adminListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        const comp = await API.getCompanySetting();
        // setUsers(fetchUserGroups());
        getCompnayInfo();
        fetchEmployees();
        fetchSchedules();
        fetchQuestions();
        fetchRegions();
        fetchLocations();
        fetchPrograms();
        loadSetting();
        setUserRoutes(
          comp?.programLevel === "Zone"
            ? zoneLevelRoutes
            : user.phone_number === "+19999999999" || user.phone_number === "+18888888888"
            ? adminRoutes
            : subAdminRoutes
        );
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    user?.isAdmin() && adminListener();
  }, [user]);
  console.log("AdmnCompany", company);
  return (
    <>
      {!loading ? (
        <div className="wrapper">
          {showAlert && (
            <SweetAlert
              show={showAlert}
              error
              title="Error"
              onConfirm={async () => {
                await Auth.signOut();
                window.location.reload();
              }}
            >
              Your account does not have access to the Med Flow HR
            </SweetAlert>
          )}
          {successMessageText && (
            <SweetAlert
              show={successMessageText ? true : false}
              success
              title="Success"
              onConfirm={() => setSuccessMessageText("")}
            >
              {successMessageText}
            </SweetAlert>
          )}
          {errorMessageText && (
            <SweetAlert
              show={errorMessageText ? true : false}
              error
              title="Error"
              onConfirm={() => setErrorMessageText("")}
            >
              {errorMessageText}
            </SweetAlert>
          )}
          <Sidebar
            color={color}
            image={""}
            routes={userRoutes}
            user={user}
            testFilter={testFilter}
            setTestFilter={setTestFilter}
          />
          {/* comment back in to enable log out timer!!!!!!! */}
          {/* <IdleTimer
            crossTab={{
              emitOnAllTabs: false,
            }}
            startOnMount={false}
            startManually={true}
          /> */}
          <div className="main-panel" ref={mainPanel}>
            <AdminNavbar routes={userRoutes} role={user?.roles[0]} settings={settings} user={user} />
            <div className="content">
              <AppContext.Provider value={contextSwitch(user?.roles[0])}>
                <Switch>{getRoutes(userRoutes)}</Switch>
                {/* {window.location.pathname === "/admin" && user?.isAdmin() && <AnalyticalDashbaord users={employees} />} */}
                {window.location.pathname === "/admin" && <Employees />}
              </AppContext.Provider>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
      <IdleModal
        showChildModal="showChildModal"
        showIdleModal={showIdleModal}
        animation={true}
        handleLogOut={handleLogOut}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

export default Admin;
// export default Admin;
