import React, { useContext } from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import {
  formatPhoneNumber,
  formatZipCode,
  getDemoGraphics,
  getAge,
  formatDateOfBirth,
  formatDateMDY,
  currentDate,
  formatPDFName,
} from "utils";

const PDF = ({ test, testsLab, parseTestResultForPDF }) => {
  let demos = getDemoGraphics(test);
  const today = new Date();

  const time =
    today.getHours() > 12
      ? `${today.getHours() - 12}:${today.getMinutes()} PM`
      : `${today.getHours()}:${today.getMinutes()} AM`;

  return (
    <Document title={`${demos.firstName} ${demos.lastName}`}>
      <Page size="A4" style={styles.page}>
        <View style={{ position: "absolute", top: "30%", left: "5%" }} fixed>
          <Image
            style={{ width: "600px", opacity: 0.2 }}
            src={require("assets/img/safecamp_BRAND.1-nobg.png").default}
          />
        </View>
        {testsLab ? (
          <>
            <Text style={{ fontSize: 28 }}>{testsLab.name}</Text>
            <Text style={{ fontSize: 16 }}>{testsLab.street}</Text>
            <Text style={{ fontSize: 16 }}>{testsLab.city_state_zip}</Text>
            <Text style={{ fontSize: 16 }}>Phone: {formatPhoneNumber(testsLab.contact_phone)}</Text>
          </>
        ) : (
          <Text style={{ fontSize: 16 }}>Lab: N/A</Text>
        )}
        <Text style={{ position: "absolute", top: "35", right: "20", color: "red" }}>Final</Text>
        {testsLab && testsLab.logoSrc && (
          <View>
            <Image
              style={{ width: "50px", height: "50px", position: "absolute", right: "-5", bottom: "-5" }}
              src={testsLab.logoSrc}
            />
          </View>
        )}
        <View style={{ ...styles.hr, marginTop: 10, marginBottom: 20 }} />
        <View style={styles.patientInfo}>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>Patient:</Text>
            <Text style={styles.infoText}>Patient #:</Text>
            <Text style={styles.infoText}>Doctor:</Text>
            <Text style={styles.infoText}>Phone:</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>{formatPDFName(demos)}</Text>
            <Text style={styles.infoText}>NA</Text>
            <Text style={styles.infoText}>{testsLab ? testsLab.lab_director : ""}</Text>
            <Text style={styles.infoText}>{test.phoneNumber}</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>Birth:</Text>
            <Text style={styles.infoText}>Age:</Text>
            <Text style={styles.infoText}>Gender:</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>{formatDateOfBirth(demos.dob)}</Text>
            <Text style={styles.infoText}>{getAge(demos.dob)}</Text>
            <Text style={styles.infoText}>{demos.gender === "F" ? "Female" : "Male"}</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>Accession:</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>Collection Date:</Text>
            <Text style={styles.infoText}>Received Date:</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>{test.barcode}</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>{formatDateMDY(test.createdAt)}</Text>
            <Text style={styles.infoText}>{formatDateMDY(test.createdAt)}</Text>
          </View>
        </View>

        <View style={styles.address}>
          <Text style={styles.infoText}>{formatPDFName(demos)}</Text>
          <Text style={styles.infoText}>{demos.street2 ? demos.street + " " + demos.street2 : demos.street}</Text>
          <Text style={styles.infoText}>{demos.city + ", " + demos.state + " " + formatZipCode(demos.zip)}</Text>
        </View>

        <View style={styles.hr} />
        <View style={styles.infoHeader}>
          <View style={styles.infoCol}>
            <Text style={styles.infoTextRightBorder}>Test Name</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoTextRightBorder}>Result</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoTextRightBorder}>Units</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoTextRightBorder}>Flag</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.infoTextRightBorder}>Reference Range/Cutoff</Text>
          </View>
        </View>
        <View style={styles.hrTwo} />
        <View style={styles.infoHeader}>
          <View style={styles.infoCol}>
            <Text style={styles.infoText}>2019-nCoV rRT-PCR</Text>
            <Text style={styles.infoText}>2019-nCoV</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={styles.infoText}>{parseTestResultForPDF(test)}</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
            <Text style={{ fontSize: 12, opacity: 0 }}>fake</Text>
          </View>
          <View style={styles.infoCol}>
            <Text style={styles.italicText}>{`Run on ${formatDateMDY(test.createdAt)}`}</Text>
            <Text style={styles.infoText}>{parseTestResultForPDF(test)}</Text>
          </View>
        </View>
        <View style={{ ...styles.hr, opacity: 0.75, marginBottom: 20 }} />
        <View>
          <Text style={{ ...styles.infoText, fontWeight: "200", marginRight: 20 }}>Notes:</Text>
          <Text style={{ ...styles.infoText, paddingHorizontal: 40 }}>{testsLab ? testsLab.testing_notes : "N/A"}</Text>
        </View>

        {/* <View style={{ ...styles.patientInfo, marginVertical: 0, width: "90%" }}>
          <Text style={{ ...styles.infoText, fontWeight: "200", marginRight: 20 }}>
            {testsLab && testsLab.molecular_notes ? "Antigen Notes:" : ""}
          </Text>
          <Text style={{ ...styles.infoText, fontWeight: "200", marginRight: 10 }}>
            {testsLab && testsLab.antigen_notes ? testsLab.antigen_notes : ""}
          </Text>
        </View>
        <View style={{ ...styles.patientInfo, marginVertical: 0, width: "90%" }}>
          <Text style={{ ...styles.infoText, fontWeight: "200", marginRight: 20 }}>
            {testsLab && testsLab.molecular_notes ? "Molecular Notes:" : ""}
          </Text>
          <Text style={{ ...styles.infoText, fontWeight: "200", marginRight: 10 }}>
            {testsLab && testsLab.molecular_notes ? testsLab.molecular_notes : ""}
          </Text>
        </View> */}
        <View
          style={{
            ...styles.infoCol,
            alignSelf: "center",
            alignItems: "center",
            position: "absolute",
            bottom: "90",
          }}
          fixed
        >
          <Text style={{ ...styles.infoText, fontFamily: "Times-Bold" }}>
            CLIA #{testsLab ? testsLab.clia_number : "N/A"}
          </Text>
          <Text style={{ ...styles.infoText, fontFamily: "Times-Bold" }}>
            Laboratory Director: Dr. {testsLab ? testsLab.lab_director : "N/A"}
          </Text>
        </View>

        <View
          style={{
            position: "absolute",
            bottom: "45",
            left: "20",
            width: "100%",
          }}
          fixed
        >
          <View style={{ ...styles.hr }} />
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View style={{ display: "flex", flexDirection: "column" }}>
              <Text
                style={{
                  ...styles.infoText,
                  fontWeight: "200",
                  fontSize: 10,
                  marginRight: 20,
                }}
              >
                Originally Reported On: {formatDateMDY(test.createdAt)}
              </Text>
              <Text
                style={{
                  ...styles.infoText,
                  fontWeight: "200",
                  fontSize: 10,
                  marginRight: 20,
                }}
              >
                Printed: {currentDate()} {time}
              </Text>
              <Text
                style={{
                  ...styles.infoText,
                  fontWeight: "200",
                  marginRight: 20,
                  fontSize: 10,
                }}
              >
                (UTC-08:00) Pacific Time (US & Canada)
              </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              <Text style={{ ...styles.infoText, fontSize: 10 }}>STAT[S] Corrected [C] Amended [A]</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
              <Text style={{ ...styles.infoText, fontSize: 10 }}>Accession: {test.barcode} Patient #: N/A</Text>
              <Text style={{ ...styles.infoText, fontSize: 10 }}>
                Lab Results for: {demos.lastName}, {demos.firstName}
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 20,
    paddingTop: 35,
    paddingBottom: 150,
  },
  infoText: {
    fontSize: 12,
  },
  infoTextRightBorder: {
    fontSize: 12,
    fontStyle: "bold",
  },
  italicText: {
    fontSize: 12,
    fontStyle: "italic",
  },
  pageNumbers: {
    position: "absolute",
    fontSize: 10,
    bottom: 15,
    right: 22,
  },
  patientInfo: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  infoHeader: {
    flexDirection: "row",
    justifyContent: "space-around",
    borderRightWidth: 1,
    borderRightColor: "black",
    borderLeftWidth: 1,
    borderLeftColor: "black",
  },
  infoCol: {
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  hr: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    width: "100%",
  },
  hrTwo: {
    borderBottomColor: "black",
    borderBottomWidth: 2,
    width: "100%",
  },
  address: {
    marginLeft: 50,
    marginVertical: 20,
  },
  waterMark: {
    opacity: 0.05,
    fontSize: "70px",
    color: "black",
    background: "#ccc",
    position: "absolute",
    cursor: "default",
    top: "45%",
    left: "30%",
    transform: "Translate(-50%, -50%)",
  },
});

export default PDF;
