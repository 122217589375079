import React, { useState, useEffect } from "react";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import logoImage from "assets/img/safecamp_BRAND.1-nobg.png";
import { Button, Modal, Card } from "react-bootstrap";
import { useHistory, Redirect } from "react-router-dom";
import { Auth } from "aws-amplify";
import API from "api";
import { userCompanyID } from "utils";
import Loader from "components/Loader/Loader";
import RegisterModal from "./RegisterModal";
import ErrorMessage from "components/Message/ErrorMessage";
import { validatePhone } from "utils";
const Register = (props) => {
  const {
    match: { params },
  } = props;
  const [newUser, setNewUser] = useState([]);
  const [phoneError, setPhoneError] = useState("");
  const [error, setError] = useState("");
  const [companyList, setCompnayList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [regUser, setRegUser] = useState({});
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let url = window.location.href;

  useEffect(() => {
    setLoading(true);
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        redirectToAdmin();
      } else {
        const companyData = await API.getCompnayList();
        setCompnayList(companyData);
        if (url.includes("localhost")) {
          url = "devtest";
        }
        const urlData = companyData.filter((c) => url.includes(c.companyWeb));
        console.log("urldata", urlData);
        setCompany(urlData[0]);
      }
    };
    getUSer();
    setLoading(false);
  }, []);
  console.log("Compnay", company);
  const redirectToAdmin = () => {
    setLoading(false);
    if (history) history.push("/admin/employees");
    else window.location.reload();
  };
  const onHandleSave = async () => {
    if (!newUser.phoneNumber) {
      setError("Phone number is required");
      return;
    }
    if (!validatePhone(newUser.phoneNumber)) {
      setError("Invalid Phone Number");
      return;
    }
    setLoading(true);
    const cid = company.id;
    console.log("newUser", newUser.phoneNumber);
    const data = { cid, phoneNumber: newUser.phoneNumber };
    try {
      const res = await API.getRegisterData(data);
      console.log("response", res);
      if (res) {
        setRegUser({ ...res, gender: res.sex });
      } else {
        setRegUser({ ...newUser });
      }
      setShowModal(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };
  const createUser = (user) => {
    console.log("createUSer", user);
  };

  if (showModal) {
    return <RegisterModal user={regUser} handleClose={() => setShowModal(false)} handleSave={createUser} />;
  }
  return (
    <>
      {!loading ? (
        <>
          <div className="registerFormWrapper">
            <div className="preLoginWrapper">
              {/* <div className="form-group-wrapper">
            <label className="CardLineHeaders">First Name: </label>
            <input
              value={newUser.firstName}
              className="CardInput"
              placeholder="First Name"
              style={{
                width: "100%",
              }}
              onChange={(e) => setNewUser({ ...newUser, firstName: toTitleCase(e.target.value) })}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="CardLineHeaders">Last Name: </label>
            <input
              value={newUser.lastName}
              className="CardInput"
              placeholder="Last Name"
              onChange={(e) => setNewUser({ ...newUser, lastName: toTitleCase(e.target.value) })}
            />
          </div>
          <div className="form-group-wrapper">
            <label className="CardLineHeaders">Email: </label>
            <input
              value={newUser.email}
              className="CardInput"
              placeholder="Email"
              style={{
                width: "100%",
              }}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
          </div>
          
 */}
              <div class="text-center">
                <img src={logoImage} alt="logo" class="logo-width" />
              </div>
              <h4 class="w-100 text-center mt-4">
                <b>{company.companyName}</b>
              </h4>
              <div className="codeScannerPhoneInput codeScannerWrapper">
                <div className="registered-successful">
                  <div className="form-group-wrapper my-4">
                    <label className="mb-1">Phone Number:</label>
                    <PhoneNoInput
                      error={setPhoneError}
                      handleChange={(e) =>
                        setNewUser({
                          ...newUser,
                          phoneNumber: e,
                        })
                      }
                      value={newUser.phoneNumber}
                    />
                  </div>
                  <div style={{ height: "3vh" }}>
                    {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                  </div>
                  <div className="mt-1 w-50 mx-auto">
                    <Button type="submit" className="headerButton btn-fill" onClick={onHandleSave}>
                      NEXT
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Register;
