import React, { useState, useContext, useRef } from "react";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { useEffect } from "react";
import { AppContext } from "context/app-context";
import TestTypeModal from "views/TestTypeSetting/TestTypeModal";
import QuestionSchedules from "views/QuestionSchedule";
import Departments from "views/Departmens";
import API from "api";
import {
  calculateTdWidth,
} from "utils";
import Schedules from "views/Schedules";
import Questionnaire from "views/Questionnaire";
import MessageSetting from "views/MessageSetting";
import Location from "views/Location";
import Regions from "views/Regions";
import ProgramSettings from "views/ProgramSettings";
import {
  EMPLOYEE_DEPARTMENTS,
  HEALTH_SAFETY_SCHEDULE, LOCATIONS, MESSAGE_SETTINGS, PROGRAMS,
  QUESTION_SCHEDULE, REGIONS,
  TESTING_SCHEDULE,TIMER_TEST
} from 'constants/tabPillsContants';
import TabPills from 'components/Tabs/TabPills';
import Loader from "components/Loader/Loader";
import TimerTest from "./TimerTest";

const TestTypeSetting = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [departments, setDepartment]= useState([])
  const [tabsOptions, setTabOptions] = useState([])
  const [loading, setLoading] = useState();
  const appContext = useContext(AppContext);
  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartment(data);
  };

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(()=>{
    if(appContext.company?.programLevel === "Zone"){
      setTabOptions(tabPillsConstants.filter((f)=> f.title !== `${TESTING_SCHEDULE}` && f.title !== `${QUESTION_SCHEDULE}`))
    }else{
      setTabOptions(tabPillsConstants)
    }
  },[appContext.company ,departments])
  const tabPillsConstants = [
    {
      title: `${TESTING_SCHEDULE}`,
      length: `${appContext.schedules.filter((f)=>f.scheduleCategory!=="Question").length}`,
      component: <Schedules />,
    },
    {
      title: `${QUESTION_SCHEDULE}`,
      length: `${appContext.schedules.filter((f)=>f.scheduleCategory==="Question").length}`,
      component: <QuestionSchedules />
    },
    {
      title: `${HEALTH_SAFETY_SCHEDULE}`,
      length: `${appContext.questions.length}`,
      component: <Questionnaire/>
    },
    {
      title: `${EMPLOYEE_DEPARTMENTS}`,
      length: `${departments.length}`,
      component: <Departments setDepartment={setDepartment}/>,
    },
    {
      title: `${LOCATIONS}`,
      length: `${appContext.locations.length}`,
      component: <Location/>,
    },
    {
      title: `${REGIONS}`,
      length: `${appContext.regions.length}`,
      component: <Regions/>,
    },
    {
      title: `${PROGRAMS}`,
      length: `${appContext.programs.length}`,
      component: <ProgramSettings/>,
    },
    {
      title: MESSAGE_SETTINGS,
      component: <MessageSetting/>,
    },
    {
      title: TIMER_TEST,
      component: <TimerTest/>,
    },
  ];

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <div className="system-settings-wrapper">
              <TabPills tabs={tabsOptions}  />
            </div>
          </Col>
        </Row>
      ) : (
        <Loader/>
      )}
    
    </div>
  );
};

export default TestTypeSetting;
