import React, { useState, useEffect } from "react";
import logoImage from "../../assets/img/safecamp_BRAND.1-nobg.png";
import { useHistory, Redirect } from "react-router-dom";
import { phoneFormatter, setPhoneNo, medFlowInLocalStorage, personalizationLocalStorage } from "utils";
import { Auth } from "aws-amplify";
import API from "api";
import { userCompanyID } from "utils";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { NEW_PERSONALIZE } from "constant";

const Login = () => {
  const [phone, setPhone] = useState("+1");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [companyList, setCompnayList] = useState([]);
  let history = useHistory();
  let url = window.location.href;

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        redirectToAdmin();
      } else {
        const companyData = await API.getCompnayList();
        setCompnayList(companyData);
      }
    };
    getUSer();
  }, []);
  console.log("companyList", companyList);
  const handleSubmit = async () => {
    if (!phone) {
      setPhoneError("please enter the phone");
      return;
    }
    if (!password) {
      setPasswordError("please enter the password");
      return;
    }

    //  const attributePayload = {
    //   email: "pybertest@gmail.com",
    //   preferred_username: "superadmin",
    //   phone_number: "+17777777777",
    //   "custom:role": "Admins",
    //   "custom:autoConfirm": "true",
    //   "custom:firstName": "Super",
    //   "custom:lastName": "Admin",
    //   "custom:note": "System",
    //   "custom:labID":"312c3c36-f69a-4f29-acba-cfa2d6c4046c"
    // };

    // const signUp = await Auth.signUp({
    //   username: "+17777777777",
    //   password: "Test1234!",
    //   attributes: attributePayload,
    // });

    try {
      setDisableBtn(true);
      const user = await Auth.signIn(phone, password);
      const compnayID = user.attributes["custom:labID"];
      const userCompanies = await API.getEmployeeClients(user.username);
      let activeCompany = null;
      // if user is assigned any company set default 0 index
      // if (userCompanies) {
      //   const arr = userCompanies.split(",");
      //   if (arr.length > 0) activeCompany = arr[0];
      // }
      if (url.includes("localhost")) {
        url = "devtest";
      }
      // if (phone === "+18187401191") {
      //   url = "tbt.safecamphr.com";
      // }
      // if (phone === "+19999999999") {
      //   url = "houston.safecamphr.com";
      // }
      if (phone === "+923111439908") {
        url = "rapidzztest";
      }
      if (!url.includes("localhost")) {
        const urlData = companyList.filter((c) => url.includes(c.companyWeb));
        if (urlData.length > 0 && userCompanies && userCompanies.includes(urlData[0].id)) {
          activeCompany = urlData[0].id;
        } else {
          activeCompany = null;
        }
      }

      if (!activeCompany) {
        await Auth.signOut();
        setDisableBtn(false);
        alert("Not Authorized");
        medFlowInLocalStorage.clear();
        userCompanyID.clear();
        return;
      }
      userCompanyID.save(activeCompany);
      medFlowInLocalStorage.save();
      personalizationLocalStorage.save(
        user.attributes["custom:personalisation"] ||
          JSON.stringify({
            personalize:
              "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25:1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25",
          })
      );
      redirectToAdmin();
    } catch (error) {
      setDisableBtn(false);
      alert(error.message);
    }
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const redirectToAdmin = () => {
    if (history) history.push("/admin/employees");
    else window.location.reload();
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();
  return (
    <div className="loginWrapper">
      <div className="login-left-side">
        <div className="text-center">{<img src={logoImage} alt="logo" className="logo-width" />}</div>
      </div>
      <div className="login-right-side">
        <h3 className="mt-0">Login</h3>
        <p className="tag-line">Sign in to your account</p>
        <div className="form-group">
          <PhoneNoInput handleChange={(e) => setPhone(e)} value={phone} />
        </div>
        <div className="input-group group-wrapper mt-3">
          <span className="input-group-text">
            <i className="fa fa-lock" />{" "}
          </span>
          <input
            className="form-control"
            placeholder="Enter your password"
            minLength={8}
            value={password}
            type="password"
            onKeyDown={handleKeyDownEvent}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-right w-100">
          <button
            type="submit"
            disabled={disableBtn}
            className="text-uppercase w-100 btn signin-btn"
            onClick={handleSubmit}
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
