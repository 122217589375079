import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "context/app-context";
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Overlay,
  Popover,
  Modal,
} from "react-bootstrap";
import { removeSpaceIns } from "utils";
import MatrixPopOver from "./MatrixPopOver";
import { formatDateMatrix, calculateTdWidth } from "utils";
const MatrixView = (props) => {
  const { programData, prog, index, dateList, showData } = props;
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current?.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current?.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const tdWidth = calculateTdWidth(width - 70, 17);
  console.log("tdWidth", tdWidth);

  useEffect(() => handleResize(), [mainWidth]);
  const appContext = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [checkedProg, setCheckedProg] = useState("");
  const zoneColor = appContext.programs?.find((f) => f.name === prog)?.color;
  const list = programData[prog];
  console.log("programData", programData, prog);
  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCheckedProg(prog);
      setShow(true);
    } else {
      setCheckedProg("");
      setShow(false);
    }
  };
  const TableRow = ({ emp }) => {
    return (
      <>
        <tr>
          <td
            className="ellipsis"
            style={{ textAlign: "center", width: tdWidth, maxWidth: tdWidth }}
            title={emp.firstName}
          >
            {emp.firstName}
          </td>
          <td
            className="ellipsis"
            style={{ textAlign: "center", width: tdWidth, maxWidth: tdWidth }}
            title={emp.lastName}
          >
            {emp.lastName}
          </td>
          <td
            className="ellipsis"
            style={{
              textDecoration: "underline",
              color: "#A82632",
              textAlign: "center",
              width: tdWidth,
              maxWidth: tdWidth,
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onClick={() => emp.email && window.open(`mailto:${emp.email}`)}
            title={emp.email}
          >
            {emp.email}
          </td>
          <td
            className="ellipsis"
            style={{
              textDecoration: "underline",
              color: "#A82632",
              textAlign: "center",
              width: tdWidth,
              maxWidth: tdWidth,
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onClick={() => emp.phoneNumber && window.open(`tel:+${emp.phoneNumber.replace(/\D/g, "")}`)}
            title={emp.phoneNumber}
          >
            {emp.phoneNumber}
          </td>
          {dateList.map((d) => {
            const dayOpt = appContext.isZoneLevelCompany()
              ? emp.scheduleData?.filter((f) => f.date === d)
              : emp.scheduleData?.find((f) => f.date === d);
            return (
              <MatrixPopOver
                dayOpt={dayOpt}
                prevTest={showData}
                tdWidth={tdWidth}
                emp={emp}
                isZoneLevel={appContext.isZoneLevelCompany()}
              />
            );
          })}
        </tr>
      </>
    );
  };
  return (
    <>
      <tr className="matrixRowsProgram w-100">
        <td className="position-relative table-first-td" style={{ borderLeft: `3px solid ${zoneColor}` }}>
          {/* <div
          className="programColor"
          style={{
            width: "10px",
            height: "10px",
            marginRight: "5px",
            backgroundColor: `${removeSpaceIns(zoneColor)}`,
          }}
        /> */}
          <div className="ps-2 d-flex alin-items-center">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={prog === checkedProg}
                value={prog}
                onChange={handleCheckboxChange}
              />
            </Form.Check>
            <div className="ms-4">
              <div>
                <strong className="me-2 programName">Program Name</strong>
              </div>
              <span className="fw-bolder">{prog}</span>
            </div>
          </div>
        </td>
        {show && checkedProg && (
          <tr className="matrixListData">
            <td colSpan={"19"} className="expended-table-wrapper">
              <Card className="striped-table-card tcMatrixWrapper">
                <Card.Body className="table-full-width">
                  <div className="table-responsive pendingReleaseTable  desktop-noScroll">
                    <Table className="user-table employeeTableWrapper">
                      <thead ref={componentRef}>
                        <tr>
                          {" "}
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                            }}
                            className="border-0 centered"
                          >
                            <div className="centered">
                              <span title="First Name">First Name</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                              maxWidth: tdWidth,
                            }}
                            className="border-0 centered"
                          >
                            <div className="centered">
                              <span title="Last Name">Last Name</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                              maxWidth: tdWidth,
                            }}
                            className="border-0 centered"
                          >
                            <div className="centered">
                              <span title="Email">Email</span>
                            </div>
                          </th>
                          <th
                            style={{
                              color: "grey",
                              fontWeight: "bold",
                              width: tdWidth,
                              maxWidth: tdWidth,
                              textAlign: "center",
                            }}
                            className="border-0 centered"
                          >
                            <div className="centered">
                              <span title="Phone">Phone</span>
                            </div>
                          </th>
                          {dateList &&
                            dateList.map((d) => {
                              return (
                                <th
                                  style={{
                                    color: "grey",
                                    fontWeight: "bold",
                                    width: tdWidth,
                                    maxWidth: tdWidth,
                                  }}
                                  className="border-0 centered"
                                >
                                  <div className="centered">
                                    <span title={`${formatDateMatrix(d)}`}>{`${formatDateMatrix(d)}`}</span>
                                  </div>
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {list.length > 0 &&
                          list.map((item, i) => {
                            return <TableRow key={i} emp={item} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </td>
          </tr>
        )}
      </tr>
    </>
  );
};

export default MatrixView;
