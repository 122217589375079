import React, { useEffect, useState, useContext } from "react";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import api from "api";
import { AppContext } from "context/app-context";
import TextAreaEditor from "components/TextInput/TextAreaEditor";

const messageObject = {
  isCallTimeVerbige: false,
  callTimeVerbiage: "",
  isOnBoardVerbiage: false,
  onBoardVerbiage: "",
  isStartProgramVerbiage: false,
  startProgramVerbiage: "",
  isDailyTextVerbiage: false,
  DailyTextVerbiage: "",
  isDailyReminderTwoVerbiage: false,
  dailyReminderTwoVerbiage: "",
  isTestingReminderOneVerbiage: false,
  testingReminderOneVerbiage: "",
  isTestingReminderTwoVerbiage: false,
  testingReminderTwoVerbiage: "",
  isTestNowVerbiage: false,
  testNowVerbiage: "",
};

const MessageSetting = () => {
  const appContext = useContext(AppContext);
  const [message, setMessage] = useState(messageObject);
  useEffect(() => {
    loadSetting();
  }, []);
  const loadSetting = async () => {
    const setting = await api.getCompanySetting();

    setMessage(setting);
  };

  const handleSave = async () => {
    try {
      await api.newAppSettings(message);
      appContext.resetCompanyInfo();
      appContext.showSuccessMessage("Message saved sucessfully");
    } catch (err) {
      console.log(err.message);
    }
  };

  const onHandleMessageChange = (isSelected, msg, key, type) => {
    const obj = { ...message };
    switch (key) {
      case "onBoardVerbiage":
        console.log("onBoardVerbiage", type);
        Object.assign(obj, {
          isOnBoardVerbiage: isSelected,
          onBoardVerbiage: msg,
          onBoardType: type,
        });
        break;
      case "startProgramVerbiage":
        Object.assign(obj, {
          isStartProgramVerbiage: isSelected,
          startProgramVerbiage: msg,
          startProgramType: type,
        });
        break;
      case "DailyTextVerbiage":
        Object.assign(obj, {
          isDailyTextVerbiage: isSelected,
          DailyTextVerbiage: msg,
          dailyTextType: type,
        });
        break;
      case "dailyReminderTwoVerbiage":
        Object.assign(obj, {
          isDailyReminderTwoVerbiage: isSelected,
          dailyReminderTwoVerbiage: msg,
          dailyReminderTwoType: type,
        });
        break;
      case "testingReminderOneVerbiage":
        Object.assign(obj, {
          isTestingReminderOneVerbiage: isSelected,
          testingReminderOneVerbiage: msg,
          testingReminderOneType: type,
        });
        break;
      case "testingReminderTwoVerbiage":
        Object.assign(obj, {
          isTestingReminderTwoVerbiage: isSelected,
          testingReminderTwoVerbiage: msg,
          testingReminderTwoType: type,
        });
        break;
      case "testNowVerbiage":
        Object.assign(obj, {
          isTestNowVerbiage: isSelected,
          testNowVerbiage: msg,
          testNowType: type,
        });
        break;
      case "callTimeVerbiage":
        Object.assign(obj, {
          isCallTimeVerbige: isSelected,
          callTimeVerbiage: msg,
          callTimeType: type,
        });
        break;
    }
    setMessage(obj);
  };

  console.log("Message Object", message);

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header></Card.Header>
            <Card.Body>
              <TextAreaEditor
                isSelected={message.isOnBoardVerbiage}
                message={message.onBoardVerbiage}
                name="onBoardVerbiage"
                label="OnBoarding Verbiage"
                type={message.onBoardType}
                handleChange={onHandleMessageChange}
              />
              <TextAreaEditor
                isSelected={message.isStartProgramVerbiage}
                message={message.startProgramVerbiage}
                name="startProgramVerbiage"
                label="Start Program Verbiage"
                type={message.startProgramType}
                handleChange={onHandleMessageChange}
              />
              <TextAreaEditor
                isSelected={message.isTestNowVerbiage}
                message={message.testNowVerbiage}
                name="testNowVerbiage"
                label="Test Completed Verbiage"
                type={message.testNowType}
                handleChange={onHandleMessageChange}
              />
              <TextAreaEditor
                isSelected={message.isCallTimeVerbige}
                message={message.callTimeVerbiage}
                name="callTimeVerbiage"
                label="Call Time Verbiage"
                type={message.callTimeType}
                handleChange={onHandleMessageChange}
              />
              <TextAreaEditor
                isSelected={message.isDailyTextVerbiage}
                message={message.DailyTextVerbiage}
                name="DailyTextVerbiage"
                label="Question Reminder-I Verbiage"
                type={message.dailyTextType}
                handleChange={onHandleMessageChange}
              />
              <TextAreaEditor
                isSelected={message.isDailyReminderTwoVerbiage}
                message={message.dailyReminderTwoVerbiage}
                name="dailyReminderTwoVerbiage"
                label="Question Reminder-II Verbiage"
                type={message.dailyReminderTwoType}
                handleChange={onHandleMessageChange}
              />
              <TextAreaEditor
                isSelected={message.isTestingReminderOneVerbiage}
                message={message.testingReminderOneVerbiage}
                name="testingReminderOneVerbiage"
                label="Testing Reminder-I Verbiage"
                type={message.testingReminderOneType}
                handleChange={onHandleMessageChange}
              />
              <TextAreaEditor
                isSelected={message.isTestingReminderTwoVerbiage}
                message={message.testingReminderTwoVerbiage}
                name="testingReminderTwoVerbiage"
                label="Testing Reminder-II Verbiage"
                type={message.testingReminderTwoType}
                handleChange={onHandleMessageChange}
              />

              <Button className="mb-3" onClick={handleSave}>
                Save
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MessageSetting;