import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import { TIMEZONE_OPT } from "constant";
const GeneralDeptTime = (props) => {
  const { handleClose, handleSave, client } = props;
  const [dept, setDept] = useState(client);
  const [error , setError] = useState("");
  console.log("props", props);
  const onhandleSave = () => {
    if(!dept.defaultCallTime){
        setError("please Set Call Time");
        return
    }
    if(!dept.defaultTimeZone){
        setError("Please Set Time Zone");
        return
    }
    handleSave(dept)
  }
  return (
    <Modal show onHide={handleClose} animation={true} size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">General Department Time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-12">
            <div className="createClientsWrapper flex-wrap">
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">General Call Time:</label>
                  <input
                    type="time"
                    id="callTime"
                    step="900"
                    className="modalInput"
                    value={dept.defaultCallTime || ""}
                    onChange={(e) => setDept({ ...dept, defaultCallTime: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">General Time Zone:</label>
                  <Select
                    options={TIMEZONE_OPT}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    placeholder="Select Time zone"
                    value={dept.defaultTimeZone ? { label: dept.defaultTimeZone, value: dept.defaultTimeZone } : null}
                    onChange={(e) =>
                      setDept({
                        ...dept,
                        defaultTimeZone: e.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </div>
      </Modal.Body>
      
      <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
            Close
          </Button>
          <Button variant="secondary" className="headerButton btn-fill" onClick={onhandleSave}>
            Update
          </Button>
        </Modal.Footer>
    </Modal>
  );
};

export default GeneralDeptTime;
