import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { AppContext } from "../../context/app-context";
import Select from "react-select";
import ErrorMessage from "components/Message/ErrorMessage";
import api from "api";
import {
  AssignGroup_Type_Question_Testing,
  AssignGroup_Type_Question_Only,
  AssignGroup_Type_testing_Only,
  QUESTION_GROUP_TYPES,
} from "constant";
import ProgramNameInput from "components/Modal/ProgramNameInput";
import moment from "moment";

const AssignGroupModal = (props) => {
  const { handleClose, handleSave, data, handleProgramSave } = props;
  const [programName, setProgramName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [questionGroup, setQuestionGroup] = useState("");
  const [groupNames, setGroupNames] = useState([]);
  const [testingSchedule, setTestingSchedule] = useState("");
  const [questionSchedule, setQuestionSchedule] = useState("");
  const [programNameOptions, setProgramNameOptions] = useState([]);
  const [showProgramModal, setShowProgramModal] = useState(false);
  const [quesOnly, setQuesOnly] = useState(false);
  const [testOnly, setTestOnly] = useState(false);
  const [error, setError] = useState("");
  const appContext = useContext(AppContext);
  useEffect(() => {
    getQuestionGroups();
  }, []);
  useEffect(() => {
    if (data) {
      const qes = appContext.questions.find((q) => q.id === data.questionID);
      setProgramName({ value: data.programName, label: data.programName });
      const type = data.groupName?.replace(/\//, "and").toLowerCase();
      setSelectedType(type);
      setQuesOnly(type === AssignGroup_Type_Question_Testing || type === AssignGroup_Type_Question_Only ? true : false);
      setTestOnly(type === AssignGroup_Type_Question_Testing || type === AssignGroup_Type_testing_Only ? true : false);
      if (qes) {
        setQuestionGroup({ value: data.questionID, label: qes.groupName });
      }
      if (data.scheduleLinked && data.scheduleLinked.length > 0) {
        const quesSchedules = appContext.schedules.find(
          (s) => s.scheduleCategory === "Question" && data.scheduleLinked.indexOf(s.id) !== -1
        );
        if (quesSchedules) setQuestionSchedule({ value: quesSchedules.id, label: quesSchedules.scheduleName });
        const testSchedules = appContext.schedules.find(
          (s) => s.scheduleCategory !== "Question" && data.scheduleLinked.indexOf(s.id) !== -1
        );
        if (testSchedules) setTestingSchedule({ value: testSchedules.id, label: testSchedules.scheduleName });
      } else {
        setTestingSchedule({ value: data.scheduleID, label: data.scheduleName });
      }
    }
  }, [data]);
  // useEffect(() => {
  //   const uniquePrograms = appContext.employees.reduce((obj, user) => {
  //     if (user.programName) {
  //       obj[user.programName] = (obj[user.programName] || 0) + 1;
  //     }
  //     return obj;
  //   }, {});
  //   const options = [];
  //   Object.keys(uniquePrograms).forEach((k) => {
  //     options.push({ value: k, label: k });
  //   });
  //   setProgramNameOptions(options);
  // }, [appContext.employees]);

  useEffect(() => {
    if (appContext.programs.length > 0) {
      const options = [];
      appContext.programs.forEach((p) => {
        options.push({ value: p.name, label: p.name, id: p.id });
      });
      setProgramNameOptions(options);
    }
  }, [appContext.programs]);

  useEffect(() => {
    if (quesOnly & testOnly) {
      setSelectedType(AssignGroup_Type_Question_Testing);
    } else if (quesOnly) {
      setSelectedType(AssignGroup_Type_Question_Only);
    } else if (testOnly) {
      setSelectedType(AssignGroup_Type_testing_Only);
    }
  }, [quesOnly, testOnly]);
  const getQuestionGroups = async () => {
    const data = await api.getQuestions();
    setGroupNames(data);
  };

  const questionGroupOptions = useMemo(() => {
    return groupNames.map((opt) => {
      return { value: opt.id, label: opt.groupName };
    });
  }, [groupNames]);
  console.log("appContext.schedules", appContext.schedules);
  const scheduleQuesOptions = useMemo(() => {
    return appContext.schedules
      .filter((f) => f.scheduleCategory === "Question" && moment().startOf("day").isSameOrBefore(f.endDate))
      .map((opt) => {
        return { value: opt.id, label: opt.scheduleName };
      });
  }, [appContext.schedules]);
  const scheduleTestOptions = useMemo(() => {
    return appContext.schedules
      .filter((f) => f.scheduleCategory !== "Question" && moment().startOf("day").isSameOrBefore(f.endDate))
      .map((opt) => {
        return { value: opt.id, label: opt.scheduleName };
      });
  }, [appContext.schedules]);

  const onHandleSaveProgram = () => {
    if (!programName) {
      setError("Please select progam name");
      return;
    }
    handleProgramSave(programName);
  };

  const onHandleSave = () => {
    let quesSch = questionSchedule;
    let testSch = testingSchedule;
    if (!quesOnly) quesSch = "";
    if (!testOnly) testSch = "";
    if (!programName) {
      setError("Please select progam name");
      return;
    }
    if (selectedType === "") {
      setError("Please select the group type");
      return;
    }
    if (
      (selectedType === AssignGroup_Type_Question_Only || selectedType === AssignGroup_Type_Question_Testing) &&
      questionGroup === ""
    ) {
      setError("Select the question group name");
      return;
    }
    if (quesOnly && !quesSch) {
      setError("Please select question schedule");
      return;
    }
    if (testOnly && !testSch) {
      setError("Please select testing schedule");
      return;
    }

    const arrSch = [];
    if (quesSch) arrSch.push(quesSch.value);
    if (testSch) arrSch.push(testSch.value);
    const obj = {
      assigngrouptype: QUESTION_GROUP_TYPES[selectedType],
      scheduleID: arrSch[0],
      programName: programName.value,
      programID: programName.id,
      scheduleLinked: arrSch,
    };
    if (selectedType !== AssignGroup_Type_testing_Only) {
      Object.assign(obj, { groupname: questionGroup });
    }
    if (data) {
      Object.assign(obj, { id: data.id }, { isSchedule: data.isSchedule });
    }
    if (!data) {
      Object.assign(obj, { isSchedule: 2 });
    }
    handleSave(obj);
    handleClose();
  };
  const handleGroupChange = (selectoption) => setQuestionGroup(selectoption);

  // const handleChangeSelectInput = (e, r) => {
  //   const newValue = r.prevInputValue;
  //   if (r.action === "input-blur" && newValue) {
  //     const formatValue = getValidReg(newValue);
  //     const newOption = { value: formatValue, label: formatValue };
  //     setProgramNameOptions([...programNameOptions, newOption]);
  //     setProgramName(newOption);
  //   }
  // };

  const handleNewProgramName = (isConfirm, val) => {
    if (isConfirm && val) {
      const newOption = { value: val.name, label: val.name, id: val.id };
      setProgramNameOptions([...programNameOptions, newOption]);
      setProgramName(newOption);
      appContext.resetPrograms();
      appContext.showSuccessMessage("New Program Created Successfully");
    }
    setShowProgramModal(false);
  };

  return (
    <>
      <Modal show onHide={handleClose} animation={true} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Assign Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="createClientsWrapper">
            <div className="first-last-name-wrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">Give your Program a Name: </label>
                <Select
                  options={programNameOptions}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  className="assignSchedule"
                  menuPlacement="auto"
                  placeholder="Select Program"
                  value={programName}
                  onChange={(e) => setProgramName(e)}
                />
              </div>
              <div className="form-group-wrapper mt-4">
                <Button
                  className="modalButtons headerButton btn-fill"
                  style={{ width: "fit-content" }}
                  variant="secondary"
                  onClick={() => setShowProgramModal(true)}
                >
                  New Program
                </Button>
              </div>
            </div>
          </section>
          <div className="form-group-wrapper">
            {/* <div>
                <Form.Check.Input
                  id="1st"
                  className="m-2"
                  value={AssignGroup_Type_Question_Testing}
                  name="AssignGroupList"
                  type="radio"
                  checked={selectedType === AssignGroup_Type_Question_Testing}
                  onChange={onChangeType}
                />
                <Form.Check.Label htmlFor="1st" className="px-2 scheduleLabel">
                  Question & Testing
                </Form.Check.Label>
              </div> */}
            <div className="question-wrapper">
              <Form.Check.Input
                id="2nd"
                className="m-2"
                value={AssignGroup_Type_Question_Only}
                name={AssignGroup_Type_Question_Only}
                type="checkbox"
                checked={quesOnly}
                onChange={(e) => setQuesOnly(e.target.checked)}
              />
              <Form.Check.Label htmlFor="2nd" className="px-2 scheduleLabel">
                Question Schedule
              </Form.Check.Label>
            </div>
          </div>
          {quesOnly && (
            <section className="createClientsWrapper">
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Question Group Name: </label>
                  <Select
                    options={questionGroupOptions}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    className="assignSchedule"
                    placeholder="Select Question"
                    value={questionGroup}
                    onChange={handleGroupChange}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Question Schedule: </label>
                  <Select
                    options={scheduleQuesOptions}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    className="assignSchedule"
                    placeholder="Select Question Schedule"
                    value={questionSchedule}
                    onChange={(e) => setQuestionSchedule(e)}
                  />
                </div>
              </div>
            </section>
          )}
          <div className="form-group-wrapper">
            <div className="question-wrapper">
              <Form.Check.Input
                id="3rd"
                className="m-2"
                value={AssignGroup_Type_testing_Only}
                name={AssignGroup_Type_testing_Only}
                type="checkbox"
                checked={testOnly}
                onChange={(e) => setTestOnly(e.target.checked)}
              />
              <Form.Check.Label htmlFor="3rd" className="px-2 scheduleLabel">
                Testing Schedule
              </Form.Check.Label>
            </div>
          </div>
          {testOnly && (
            <div className="createClientsWrapper">
              <div className="first-last-name-wrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Testing Schedule: </label>
                  <Select
                    options={scheduleTestOptions}
                    blurInputOnSelect={true}
                    defaultValue={null}
                    menuPlacement="auto"
                    className="assignSchedule"
                    placeholder="Select Testing Schedule"
                    value={testingSchedule}
                    onChange={(e) => setTestingSchedule(e)}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            {data ? "Update" : "Assign"}
          </Button>
        </Modal.Footer>
      </Modal>
      {showProgramModal && (
        <ProgramNameInput
          showChildModal="showChildModal"
          handleConfirm={handleNewProgramName}
          programs={appContext.programs}
        />
      )}
    </>
  );
};

export default AssignGroupModal;
