import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import React, { useState } from "react";
import { Card, Table, Row, Col, Button, Modal, ModalBody } from "react-bootstrap";
import { removeSpaceIns } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import ColorDropDown from "components/Dropdown/ColorDropDown";

const ProgramsOptionsModal = (props) => {
  const { data, handleSave, handleClose } = props;
  const [selectedData, setSelectedData] = useState(null);
  4;
  const [error, setError] = useState("");
  console.log("data", data);

  const onHandleSave = () => {
    if (!selectedData) {
      setError("Please Select Any Program");
      return;
    }
    handleSave(selectedData);
  };

  const handleProgramChange = (obj) => {
    console.log("handleProgramChange", obj);
    setSelectedData(obj);
  };

  return (
    <Modal
      show
      animation={true}
      size="lg"
      onHide={() => {
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Programs
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <section className="createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Select Program: </label>
            <ColorDropDown title="Select Program" data={data} onOptionChange={handleProgramChange} />
          </div>
        </section>
      </Modal.Body>
      <div className="px-4 pt-2" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={handleClose}>
          Close
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={onHandleSave}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProgramsOptionsModal;
