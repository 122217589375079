import moment from "moment";
import React, { useState, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { onBoardingTest, formatDateMDY } from "utils";
import SchedulesDetails from "./SchedulesDetails";
const ZonePopoverStick = (props) => {
  const { item, user, itemKey } = props;
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef();
  let setTimeoutConst = null;
  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
    }, 1000);
  };
  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const TableRow = ({item, index})=>{
    return (
      <tr>
      <td className="popOver-centered">{item.label}</td>
      <td className="popOver-centered">{item.location || ""}</td>
      <td className="popOver-centered">{item.isDone ? "True": "False"}</td>
      <td className="popOver-centered">{formatDateMDY(item.date)}</td>
      <td className="popOver-centered">{item.result || "-"}</td>
      <td className="popOver-centered">{formatDateMDY(item.validDate)}</td>
      </tr>
    )
  }

  return (
    <>
      <td
        className="ellipsis"
        ref={childNode}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setShowPopover(true)}
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
          cursor: "pointer",
        }}
      >
        {user.tests}
    </td>
      {user.validTests && user.validTests.length > 0 && showPopover &&
      (
      <Overlay show={showPopover} placement="auto" flip target={childNode.current}>
        <Popover
          onMouseEnter={() => {
            setShowPopover(true);
          }}
          onMouseLeave={handleMouseLeave}
          id="popover"
          className="popOverSchedule"
        >
          <Popover.Header className="fw-bold">
          </Popover.Header>
          <Card className="striped-table-card programPopOverCard">
            <Card.Body>
              <div className="form-group-wrapper d-block">
                <div className="table-responsive overFlow-y-hidden">
                <Table className="table">
                      <thead>
                          <tr>
                            <th className='popOver-centered border-0' >Test</th>
                            <th className='popOver-centered border-0'>Location</th>
                            <th className='popOver-centered border-0'>Done</th>
                            <th className='popOver-centered border-0' >Test Date</th>
                            <th className='popOver-centered border-0'>Result</th>
                            <th className='popOver-centered border-0'>Valid Date</th>
                          </tr>
                        </thead>
                      <tbody>
                        {
                        user.validTests.map((item, i) =>{
                          return <TableRow key={i} index={i} item={item} />
                        })}
                      </tbody>
                    </Table>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Popover>
      </Overlay>
    )}
    </>
  );
};

export default ZonePopoverStick;
