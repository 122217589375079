import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Card, Table, Row, Col } from "react-bootstrap";
import HeaderItem from "components/Table/HeaderItem";
import QuestionnaireModal from "components/Modal/QuestionnaireModal";
import API from "api";
import { PAGE_LIMIT } from "constant";
import MFPagination from "components/Pagination/MFPagination";
import Filter from "../components/Filter";
import { QuestionStatus } from "constant";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import Loader from "components/Loader/Loader";
import QuestionnaireViewModal from "components/Modal/QuestionnaireViewModal";
import { AppContext } from "../context/app-context";
import { calculateTdWidth, formatQuestion } from "utils";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";

const Questionnaire = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);
  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 900, 2);

  const [isShow, setIsShow] = useState(false);
  const [isQuestionView, setIsQuestionView] = useState(false);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [groups, setGroups] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const searchTerms = ["Group Name"];
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortDescending, setSortDescending] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [itemToDelete, setItemToDelete] = useState("");
  const appContext = useContext(AppContext);
  const [newItem, setNewItem] = useState({
    groupName: "",
    question: {
      question: [
        {
          id: (Math.random() * new Date()) % 10,
          name: "",
          status: QuestionStatus.new,
        },
      ],
    },
  });

  const sortGroups = (groups, sortParam) => {
    if (sortParam === "question") {
      if (sortDescending) {
        return [...groups].sort((a, b) =>
          Object.keys(a[sortParam].question).length < Object.keys(b[sortParam].question).length
            ? 1
            : Object.keys(b[sortParam].question).length < Object.keys(a[sortParam].question).length
            ? -1
            : 0
        );
      } else {
        return [...groups].sort((a, b) =>
          Object.keys(b[sortParam].question).length < Object.keys(a[sortParam].question).length
            ? 1
            : Object.keys(a[sortParam].question).length < Object.keys(b[sortParam].question).length
            ? -1
            : 0
        );
      }
    } else {
      if (sortDescending) {
        return [...groups].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
      } else {
        return [...groups].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
      }
    }
  };

  useEffect(() => {
    setGroups(formatQuestion(appContext.questions));
    setFilteredGroups(formatQuestion(appContext.questions));
    setLoading(false);
  }, [appContext.questions]);

  useEffect(() => {
    if (filteredGroups.length > 0) setFilteredGroups(sortGroups(filteredGroups, sortBy));
  }, [sortDescending, sortBy]);

  const handleConfirm = async (isConfirm) => {
    setIsShowDelete(false);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await API.deleteQuestionnaire(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetQuestions();
      appContext.showSuccessMessage("Questionnaire Group deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };

  const currentItems = (grps) => {
    const indexOfLast = currentPage * PAGE_LIMIT;
    const indexOfFirst = indexOfLast - PAGE_LIMIT;
    return grps.slice(indexOfFirst, indexOfLast);
  };

  let itemsToMap = currentItems(filteredGroups);

  const handleSubmit = async (newGroup) => {
    setIsShow(false);
    const data = { ...newGroup, question: JSON.stringify(newGroup.question) };
    try {
      setLoading(true);
      if (newGroup.isNew) {
        await API.createQuestionnaire(data);
        appContext.showSuccessMessage("Questionnaire Group created successfully");
      }
      if (!newGroup.isNew) {
        await API.updateQuestionnaire(data);
        appContext.showSuccessMessage("Questionnaire Group updated successfully");
      }
      appContext.resetQuestions();
    } catch (err) {
      console.log(`err in ${newGroup.isNew ? "creation" : " updation"}`, err);
    }
    setLoading(false);
  };

  const getPageNumbers = (groups) => {
    const pageNumbers = groups.length > PAGE_LIMIT ? Math.ceil(groups.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(groups);

  if (filteredGroups.length > 0) {
    pageNumbers = getPageNumbers(filteredGroups);
  }

  const handleClose = () => {
    setIsQuestionView(false);
    setIsShow(false);
    setIsShowDelete(false);
    setItemToDelete(null);
  };

  const handleChangePage = (number) => setCurrentPage(number);

  const handleEdit = (item) => {
    const obj = { ...item, isNew: false };
    // const data = {...obj};
    // const data = Object.assign({},obj);
    const data = JSON.parse(JSON.stringify(obj));
    setNewItem(data);
    setIsShow(true);
  };

  const handleViewQuestion = (item) => {
    const obj = { ...item };
    setNewItem(obj);
    setIsQuestionView(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  useEffect(() => {
    if (groups.length > 0) {
      setFilteredGroups(nestedFilter(groups, filter));
      setCurrentPage(1);
    }
  }, [filter]);

  const TableRow = ({ item }) => {
    return (
      <tr>
        <td className="icon" style={{ textAlign: "center", textOverflow: "visible" }}>
          <img
            src={editIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={item.id}
            onClick={(e) => handleEdit({ ...item })}
          />
        </td>
        <td title={item.groupName}>{item.groupName}</td>
        <td
          style={{
            textAlign: "center",
          }}
        >
          <span
            onClick={() => handleViewQuestion({ ...item })}
            onKeyPress={() => handleViewQuestion(item)}
            style={{
              textDecoration: "underline",
              color: "#A82632",
            }}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
              e.target.style.textDecoration = "none";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            role="button"
            tabIndex={0}
            title={item.groupName}
          >
            {item.question && Object.keys(item.question.question).length}
          </span>
        </td>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px", textAlign: "center" }}>
          <img
            src={trashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={item.id}
            onClick={(e) => {
              setIsShowDelete(true);
              setItemToDelete(item);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <div className="buttonHeader">
                    <Button
                      className="headerButton btn-fill"
                      variant="primary"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      Filter
                    </Button>
                    {/* <FilterSelect handleFilterChange={handleFilterChange} /> */}
                    <Button
                      className="headerButton btn-fill"
                      variant="secondary"
                      onClick={() => {
                        setNewItem({
                          groupName: "",
                          isNew: true,
                          question: {
                            question: [
                              {
                                id: (Math.random() * new Date()) % 10,
                                name: "",
                                status: QuestionStatus.new,
                              },
                            ],
                          },
                          isNew: true,
                        });
                        setIsShow(true);
                      }}
                    >
                      Create a Group
                    </Button>
                  </div>
                </Row>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={pageNumbers} />
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    filteredGroups.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>

                        <HeaderItem
                          width={tdWidth}
                          ItemKey="groupName"
                          title="GroupName"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey="question"
                          title="No of Question"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsToMap &&
                        itemsToMap.map((item, i) => {
                          return <TableRow key={i} item={item} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {isShow && (
        <QuestionnaireModal item={JSON.stringify(newItem)} handleClose={handleClose} handleSubmit={handleSubmit} />
      )}

      {isQuestionView && <QuestionnaireViewModal item={newItem} handleClose={handleClose} />}

      {isShowDelete && (
        <ConfirmationModal
          show={isShowDelete}
          title="Delete Group"
          message="Are you sure, you want to remove Group?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default Questionnaire;
