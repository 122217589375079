import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth, checkValidity } from "utils";
import RegionModal from "components/Modal/RegionModal";
import editIcon from "../assets/img/edit-icon.png";
import trashIcon from "../assets/img/trash-icon.png";

const Regions = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [regions, setRegions] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(null);
  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = calculateTdWidth(width - 150, 1);

  useEffect(() => {
    setRegions(appContext.regions);
  }, [appContext.regions]);

  const handleConfirmDelete = async (isConfirm) => {
    console.log("[DELETE USER]", itemToDelete);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await API.deleteRegion(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetRegions();
      appContext.showSuccessMessage("Region deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };
  const OnHandleRegion = async (reg) => {
    setOpenCreateModal(false);
    try {
      setLoading(true);
      if (reg.isNew) {
        (await API.newRegions(reg.name))
          ? appContext.showSuccessMessage("Region Created Successfully")
          : appContext.showErrorMessage("Region alredy exist");
      } else {
        await API.updateRegion(reg);
        appContext.showSuccessMessage("Region Updated Successfully");
      }
      appContext.resetRegions();
      setSelectItem(null);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };
  const handleEdit = (item) => {
    setSelectItem(item);
    setOpenCreateModal(true);
  };
  const TableRow = ({ item }) => {
    return (
      <tr>
        <td className="icon">
          <img
            src={editIcon}
            alt="edit icon"
            width="18"
            className="fas fa-edit"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => handleEdit(item)}
          />
        </td>
        <td style={{ textAlign: "left", textOverflow: "visible" }}>{item.name}</td>
        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          <img
            src={trashIcon}
            alt="trash icon"
            width="15"
            style={{ marginLeft: "10px" }}
            className="fa fa-trash"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={(e) => setItemToDelete(item)}
          />
        </td>
      </tr>
    );
  };
  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <div className="buttonHeader">
                  <Button
                    className="headerButton btn-fill"
                    variant="secondary"
                    onClick={() => setOpenCreateModal(true)}
                  >
                    Create Region
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    regions.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table">
                    <thead ref={componentRef}>
                      <tr>
                        <th></th>
                        <th
                          style={{
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="name">Name</span>
                          </div>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {regions.map((item, i) => {
                        return <TableRow key={i} item={item} />;
                      })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Bounce
              style={{
                position: "fixed",
                left: "50%",
                right: "50%",
                top: "50%",
              }}
              size={35}
              color={"#A82632"}
            />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete Region"
              message="Are you sure, you want to remove Region?"
              handleConfirm={handleConfirmDelete}
            />
          )}
          {openCreateModal && (
            <RegionModal
              handleClose={() => {
                setSelectItem(null), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleRegion}
              regions={regions}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Regions;
