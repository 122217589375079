import React from "react";
const RadioButton = (props) => {
    const { id, label , name, checked, handleChange, value, cssClass } = props;
    return (
        <div className={`radio-button-wrapper ${cssClass ?? ''}`}>
            <input
                value={value}
                type="radio"
                name={name}
                id={id}
                checked={checked}
                onChange={(e) => handleChange(e)}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default RadioButton;
