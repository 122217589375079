import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { AppContext } from "context/app-context";
import { Card, Table, Row, Col, Button } from "react-bootstrap";
import { Bounce } from "react-activity";
import API from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { calculateTdWidth } from "utils";
import { DAYS } from "constant";
import moment from "moment";
import { removeSpaceIns, formatDateMatrix } from "utils";
import Loader from "components/Loader/Loader";
import MatrixPopOver from "components/MatrixPopOver";
import MatrixView from "components/MatrixView";

const TcMatrix = () => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const appContext = useContext(AppContext);
  const [departmentData, setDepartmentData] = useState({});
  const [filterDepartments, setFilterDepartments] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [dateList, setDateList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  // useEffect(() => {
  //   setWidth(componentRef.current.offsetWidth);
  // }, [componentRef]);

  // const handleResize = () => {
  //   if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  // };
  // useEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // });
  // useEffect(() => handleResize(), [mainWidth]);
  const tdWidth = 80;

  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartments(data);
  };

  useEffect(async () => {
    setLoading(true);
    setDateList(getDaysArray(moment().startOf("day"), moment().add(13, "days").format("YYYY-MM-DD")));
    getDepartments();
    // await API.deleteDailyLog();
  }, []);

  useEffect(async () => {
    setLoading(true);
    if (!showData) {
      setDateList(getDaysArray(moment().startOf("day"), moment().add(13, "days").format("YYYY-MM-DD")));
      await uniquePrograms();
    } else {
      setDateList(getDaysArray(moment().subtract(14, "days"), moment().format("YYYY-MM-DD")).reverse());
      await getLogData();
    }
    setLoading(false);
  }, [appContext.employees, appContext.schedules, showData]);
  const getLogData = async () => {
    let rows = [];
    let dataLogs = [];
    try {
      dataLogs = await API.getAllEmployeeDailyLogs();
    } catch (err) {
      console.log("Error:- dailyLogs", err.message);
    }
    console.log("dataLogs", dataLogs);
    const empTtl = appContext.employees?.length;
    const logsTtl = dataLogs.length;
    const startDate = moment().subtract(14, "days").startOf("day").toDate();
    const endDate = moment().subtract(1, "day").endOf("day").toDate();
    for (let i = 0; i < empTtl; i++) {
      const emp = appContext.employees[i];
      let dataFilterlist = [];
      for (let j = 0; j < logsTtl; j++) {
        const f = dataLogs[j];
        const isDateRange =
          new Date(f.logDate?.substring(0, 10)) > startDate && new Date(f.logDate?.substring(0, 10)) < endDate;
        const testType = [...(f.testOne || []), ...(f.testTwo || [])];
        const isDone = testType.filter((s) => s.isDone);
        if (f.employeeID === emp.id && isDateRange && isDone.length > 0) {
          dataFilterlist.push({ ...f, date: f.logDate?.substring(0, 10), testType: isDone });
        }
      }
      console.log("dataFilter", dataFilterlist);
      if (dataFilterlist.length > 0) {
        rows.push({ ...emp, scheduleData: dataFilterlist });
      }
    }
    console.log("rows", rows);
    const prevListData = rows.reduce((obj, user) => {
      if (obj[user.department] && obj[user.department][user?.programName] && user) {
        console.log("LOGs");
        obj[user.department][user?.programName].push(user);
      } else if (obj[user.department] && user?.programName && user) {
        Object.assign(obj[user.department], { ...obj[user.department], [user.programName]: [user] });
      } else if (user) {
        obj[user.department] = { [user.programName]: [user] };
      }
      return obj;
    }, {});
    console.log("prevListData", prevListData);
    setDepartmentData(prevListData);
    setFilterDepartments(Object.keys(prevListData));
  };

  const getDaysArray = (startDate, endDate) => {
    const now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.add(1, "days");
    }
    return dates;
  };
  const getEmployeeScheduleDetails = (emp) => {
    const clone = { ...emp };
    if (appContext.schedules && clone.scheduleLinked) {
      for (let i = 0; i < clone.scheduleLinked.length; i++) {
        const schID = clone.scheduleLinked[i];
        const empSchedule = appContext.schedules.find((s) => s.id === schID);
        if (empSchedule && empSchedule.scheduleCategory === "Testing") {
          console.log("empSch", empSchedule);
          const isEndDate = moment().add(13, "days").isSameOrAfter(empSchedule.endDate);
          const DateList = getDaysArray(
            moment().startOf("day"),
            isEndDate ? empSchedule.endDate : moment().add(13, "days").format("YYYY-MM-DD")
          );
          const schTestTypes = [];
          DateList.forEach((d) => {
            const day = moment(d).format("dddd");
            const list = empSchedule.dayOptions.find((f) => f.name === day);
            if (list) {
              schTestTypes.push({ ...list, date: d });
            }
          });
          console.log("schTestTypes", schTestTypes);
          Object.assign(clone, {
            scheduleData: schTestTypes,
            endDateSch: empSchedule.endDate,
            startDateSch: empSchedule.startDate,
            ScheduleDays: empSchedule.days,
          });
          return clone;
        }
      }
    }

    return false;
  };
  console.log("departmentData", departmentData);
  const uniquePrograms = async () => {
    const result = appContext.employees
      .filter((f) => f.isSchedule === 1)
      .reduce((obj, user) => {
        const userObj = getEmployeeScheduleDetails(user);
        if (obj[user.department] && obj[user.department][user?.programName] && userObj) {
          console.log("LOGs Future");
          obj[user.department][user?.programName].push(userObj);
        } else if (obj[user.department] && user?.programName && userObj) {
          Object.assign(obj[user.department], { ...obj[user.department], [user.programName]: [userObj] });
        } else if (userObj) {
          obj[user.department] = { [user.programName]: [userObj] };
        }
        return obj;
      }, {});
    setDepartmentData(result);
    setFilterDepartments(Object.keys(result));
  };

  const handleConfirmDelete = async (isConfirm) => {
    console.log("[DELETE USER]", itemToDelete);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      await API.deleteProgramSettings(itemToDelete.id);
      setItemToDelete(null);
      appContext.resetPrograms();
      appContext.showSuccessMessage("program deleted successfully");
    } catch (error) {
      appContext.showErrorMessage(error.message);
    }
    setLoading(false);
  };
  const TableRow = ({ item }) => {
    const [open, setOpen] = useState(false);
    const list = departmentData[item];
    const dptName = departments.find((f) => f.id === item)?.name;
    const programData = { ...list };
    const filterPrograms = Object.keys(list);
    const handleOpenDpt = () => {
      setOpen(!open);
    };
    return (
      <>
        <tr className="tcMatrixRows">
          <td
            colSpan={"21"}
            className={`ellipsis ${open ? "activeDepartment" : ""}`}
            style={{
              textAlign: "left",
              textOverflow: "visible",
              width: tdWidth,
              maxWidth: tdWidth,
              paddingLeft: "8px",
            }}
          >
            <div className="d-flex align-items-center">
              <i
                onClick={() => handleOpenDpt()}
                onMouseLeave={(e) => e}
                onMouseOver={(e) => {
                  e.target.style.cursor = "pointer";
                }}
                aria-hidden="true"
                className={`tcMatrixPlusIcon ${open ? "fas fa-minus me-3 activeProgram" : "fas fa-plus me-2"}`}
              />
              <div className="ms-4">
                <div>
                  <strong className="me-2 departmentName">Department Name</strong>
                </div>
                <span className="fw-bolder">{dptName}</span>
              </div>
              <div className="ms-auto px-3">
                <div>
                  <span className="px-3 text-muted">Program</span>
                </div>
                <strong className="text-danger ps-3">{filterPrograms?.length || ""}</strong>
              </div>
            </div>
          </td>
          {/* <td colSpan={"19"}>
          {" "}
          {list.map((emp) => {
            const zoneColor = appContext.programs.find((f) => f.name === emp.programName)?.color;
            return (
              <Table className="ProgramTestTypeTable">
                <tbody>
                  <tr key={emp.id} className="programSchedules">
                    <td
                      className="ellipsis"
                      style={{
                        width: tdWidth,
                        maxWidth: tdWidth,
                      }}
                      title={emp.programName}
                    >
                      <div
                        className="programColor mx-1"
                        style={{ width: "10px", height: "10px", backgroundColor: `${removeSpaceIns(zoneColor)}` }}
                      ></div>
                      {emp.programName || "-"}
                    </td>
                    <td
                      className="ellipsis"
                      style={{ textAlign: "center", width: tdWidth, maxWidth: tdWidth }}
                      title={emp.firstName}
                    >
                      {emp.firstName}
                    </td>
                    <td
                      className="ellipsis"
                      style={{ textAlign: "center", width: tdWidth, maxWidth: tdWidth }}
                      title={emp.lastName}
                    >
                      {emp.lastName}
                    </td>
                    <td
                      className="ellipsis"
                      style={{
                        textDecoration: "underline",
                        color: "#A82632",
                        textAlign: "center",
                        width: tdWidth,
                        maxWidth: tdWidth,
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.textDecoration = "none";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.textDecoration = "underline";
                      }}
                      onClick={() => emp.email && window.open(`mailto:${emp.email}`)}
                      title={emp.email}
                    >
                      {emp.email}
                    </td>
                    <td
                      className="ellipsis"
                      style={{
                        textDecoration: "underline",
                        color: "#A82632",
                        textAlign: "center",
                        width: tdWidth,
                        maxWidth: tdWidth,
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = "pointer";
                        e.target.style.textDecoration = "none";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.textDecoration = "underline";
                      }}
                      onClick={() => emp.phoneNumber && window.open(`tel:+${emp.phoneNumber.replace(/\D/g, "")}`)}
                      title={emp.phoneNumber}
                    >
                      {emp.phoneNumber}
                    </td>
                    {dateList.map((d) => {
                      const dayOpt = emp.scheduleData?.find((f) => f.date === d);
                      return <MatrixPopOver dayOpt={dayOpt} prevTest={showData} tdWidth={tdWidth} emp={emp} />;
                    })}
                  </tr>
                </tbody>
              </Table>
            );
          })}
        </td> */}
        </tr>
        {open && filterPrograms && (
          <tr className="matrixListData">
            <td colSpan={"21"} className="expended-table-wrapper">
              <div className="form-group-wrapper d-block">
                <div className="table-responsive overFlow-y-hidden"></div>
                <Table>
                  <tbody>
                    {filterPrograms.length > 0 &&
                      filterPrograms.map((item, i) => {
                        return (
                          <MatrixView
                            key={i}
                            index={i}
                            prog={item}
                            programData={programData}
                            dateList={dateList}
                            tdWidth={tdWidth}
                            showData={showData}
                          />
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };
  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  TC Matrix ({filterDepartments.length})
                </Card.Title>
                <div className="buttonHeader pe-2">
                  <Button variant="secondary" className="headerButton btn-fill" onClick={() => setShowData(!showData)}>
                    {!showData ? "Show Previous Date" : "Show Future Date"}
                  </Button>
                </div>
              </Card.Header>
              <Card.Body className="table-full-width px-0 desktop-noScroll">
                <div
                  className={
                    filterDepartments.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="table programsViewTable">
                    {/* <thead ref={componentRef}>
                      <tr>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                            paddingLeft: "8px",
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Department">Department</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                            textAlign: "center",
                          }}
                          className="border-0"
                        >
                          <div>
                            <span title="Program">Program</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                        >
                          <div className="centered">
                            <span title="First Name">First Name</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                        >
                          <div className="centered">
                            <span title="Last Name">Last Name</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                          }}
                          className="border-0 centered"
                        >
                          <div className="centered">
                            <span title="Email">Email</span>
                          </div>
                        </th>
                        <th
                          style={{
                            color: "grey",
                            fontWeight: "bold",
                            width: tdWidth,
                            maxWidth: tdWidth,
                            textAlign: "center",
                          }}
                          className="border-0 centered"
                        >
                          <div className="centered">
                            <span title="Phone">Phone</span>
                          </div>
                        </th>
                        {dateList &&
                          dateList.map((d) => {
                            return (
                              <th
                                style={{
                                  color: "grey",
                                  fontWeight: "bold",
                                  width: tdWidth,
                                  maxWidth: tdWidth,
                                }}
                                className="border-0 centered"
                              >
                                <div className="centered">
                                  <span title={`${formatDateMatrix(d)}`}>{`${formatDateMatrix(d)}`}</span>
                                </div>
                              </th>
                            );
                          })}
                      </tr>
                    </thead> */}
                    <tbody>
                      {filterDepartments.length > 0 &&
                        filterDepartments.map((item, i) => {
                          return <TableRow key={i} item={item} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete programSettings"
              message="Are you sure, you want to remove program on settings?"
              handleConfirm={handleConfirmDelete}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TcMatrix;
