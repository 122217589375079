import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { getValidReg, checkValidity } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
const LocationModal = (props) => {
  const { handleClose, selectItem, handleSave, locations } = props;
  const [error, setError] = useState("");
  const [newLocation, setNewLocation] = useState(selectItem);
  const handleSaveChange = () => {
    let obj = {};
    selectItem
      ? Object.assign(obj, { ...newLocation, isNew: false })
      : Object.assign(obj, { ...newLocation, isNew: true });
    if (!obj.name || obj.name === " ") {
      setError("Location be required");
      return;
    }
    const findVal = locations.find((f) => obj.id !== f.id && checkValidity(f.name) == checkValidity(obj.name));
    if (findVal) {
      setError("Lcation Already Exist");
      return;
    }
    handleSave(obj);
  };
  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"md"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="row mb-3">
          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">Locations:</label>
            <input
              value={newLocation?.name}
              className="modalInput"
              placeholder="Enter Location"
              style={{
                width: "100%",
              }}
              onChange={(e) => setNewLocation({ ...newLocation, name: getValidReg(e.target.value) })}
            />
          </div>
        </div>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={handleSaveChange}>
          {selectItem ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationModal;
