import React, { useState, useContext, useRef, useMemo } from "react";
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Overlay,
  Popover,
  Modal,
} from "react-bootstrap";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "api";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { AppContext } from "../context/app-context";
import SweetAlert from "react-bootstrap-sweetalert";
import Filter from "../components/Filter";
import HeaderItem from "components/Table/HeaderItem";
import EmployeeModal from "components/Employee/EmployeeModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import AssignGroupModal from "components/Modal/AssignGroupModal";
import ExportToExcel from "components/ExportToExcel";
import StartProgramModal from "components/Modal/StartProgramModal";
import { EmptyUserObj, PAGE_LIMIT, GROUP_TYPES, EMPLOYEE_HEADERS, CONFIRMATION_TYPE, STATUS } from "../constant";
import {
  formatDOB,
  setPhoneNo,
  parseBooleanValue,
  formatDateMDYTime,
  isValidPhone,
  validatePhone,
  getPhoneNumber,
  isValidRegion,
  isValidLocation,
  isValidDob,
  isValidEmail,
  getValidGender,
  isValidCallTime,
  capitalizeLetter,
  getVaccinated,
  getValidDep,
  getCallTime,
  formatDateMDY,
  personalizationLocalStorage,
  tdEmail,
  tdPhone,
  tdCheckBox,
  tdProgramStatus,
  tdZoneColor,
  isValidName,
  calculateTdWidth,
  toTitleCase,
  getValidReg,
  sortingFilterInLC,
  changeSort,
  sortList,
  getValidSpaces,
  checkValidity,
  formatEmployeesData,
  formatTimeZone,
  importPhoneNoFormat,
  formatDate,
} from "utils";
import MFPagination from "components/Pagination/MFPagination";
import EmployeeImportModal from "components/Modal/EmployeeImportModal";
import "../../src/tableStyling.css";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import PopoverStick from "components/PopoverStick";
import EditIcon from "../assets/img/edit-icon.png";
import TrashIcon from "../assets/img/trash-icon.png";
import FileSaver from "file-saver";
import { jsonToCSV } from "react-papaparse";
import TestNowModal from "components/Modal/TestNowModal";
import Loader from "components/Loader/Loader";
import ExportSelectedData from "components/ExportSelectedData";
import ProgramsOptionsModal from "components/Modal/ProgramsOptionsModal";
import ZonePopoverStick from "components/ZonePopoverStick";
import ExternalTestProgramModal from "components/Modal/ExternalTestProgramModal";
import EmployeeAuditTrail from "components/Modal/EmployeeAuditTrail";

const Employees = (props) => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [newUser, setNewUser] = useState(EmptyUserObj);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [count, setCount] = useState(0);
  const [fullscreen, setFullscreen] = useState(true);
  const [title, setTitle] = useState("");
  const [assignProgram, setAssignProgram] = useState([]);
  const [assignProgramSuccessMsg, setAssignProgramSuccessMsg] = useState("");
  const [openTestNowModal, setOpenTestNowModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [programData, setProgramData] = useState(null);
  const [exportManifest, setExportManifest] = useState(false);
  const [showProgramOptions, setShowProgramOptions] = useState(false);
  const [showExternalTest, setShowExternalTest] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Schedule",
    "isVaccinated",
    "Job Title",
    "Department",
    "Call Time",
    "Gender",
    "Region",
    "Office",
    "Program Name",
    "Zone Color",
    "SCHR ID",
    "Local #",
    "Screening Type",
    "Test_Type",
    "Q/A",
    "Test",
    "Check In",
    "Updated At",
    "Program Status",
  ];
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [programMessage, setProgramMessage] = useState("");
  const [openErrModal, setOpenErrModal] = useState(false);
  const [openProgram, setOpenProgram] = useState(false);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [departments, setDepartments] = useState([]);
  const headerAlignKeys =
    "location,region,isVaccinated,schrID,gender,updatedAt,jobTitle,tests,departmentName,programName,dob,zoneColor,testingGroup,empTZ,scheduleName,qaDone,testDone,checkIn,isSchedule,callTime,localNo";

  const location = useLocation();
  const tdWidth = calculateTdWidth(
    width - (appContext.isZoneLevelCompany() ? 180 : 130),
    personalize.length > 0 ? personalize.filter((p) => p.isCheck).length : 24
  );
  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
        setTimeout(() => setShowFilter(false), 1);
      }
    }
  }, [location.state]);

  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartments(data);
  };
  const getEmployeeScheduleDetails = (emp) => {
    const clone = { ...emp };
    if (appContext.schedules && clone.scheduleLinked) {
      for (let i = 0; i < clone.scheduleLinked.length; i++) {
        const schID = clone.scheduleLinked[i];
        const empSchedule = appContext.schedules.find((s) => s.id === schID);
        if (typeof empSchedule !== "undefined") {
          if (empSchedule.scheduleCategory === "Testing") {
            clone.scheduleData = empSchedule.dayOptions;
            clone.endDate = new Date(empSchedule.endDate);
            // emp.scheduleData = empSchedule.dayOptions;
          }
        }
      }
    }

    return clone;
  };

  const uniquePrograms = filteredUsers
    .filter((f) => f.isSchedule === 1)
    .reduce((obj, user) => {
      const userObj = getEmployeeScheduleDetails(user);
      if (obj[user.department]) {
        obj[user.department].push(userObj);
      } else {
        obj[user.department] = [userObj];
      }
      return obj;
    }, {});

  useEffect(() => {
    getDepartments();
    const sortLS = sortingFilterInLC.get();
    if (sortLS.employees && sortLS.employees.sortBy) {
      setSortBy(sortLS.employees.sortBy);
      setSortDescending(sortLS.employees.sortDescending);
    }
    setPersonalize(personalizationLocalStorage.get(appContext.user, 0));
  }, [appContext.company]);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const filterUsers = (searchTerm, searchInput) => {
    return setFilteredUsers(users.filter((user) => user[searchTerm].includes(searchInput)));
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    console.log("Filters", filters);
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (key === "updatedAt") {
          return (
            new Date(obj.updatedAt) > filters[key].startDate._d && new Date(obj.updatedAt) < filters[key].endDate._d
          );
        }
        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "scheduleID") {
          return obj.scheduleID && (obj.scheduleID === filters[key] || obj.scheduleLinked.indexOf(filters[key]) !== -1);
        }
        if (key === "department") {
          return obj.department && obj.department === filters[key];
        }
        if (key === "isSchedule") {
          return (obj.isSchedule ? obj.isSchedule : 0) == filters[key];
        }
        if (key === "isVaccinated") {
          return (obj.isVaccinated ? 1 : 0).toString() === filter[key];
        }
        if (key === "qaDone") {
          return obj.qaDone === filter[key];
        }
        if (key === "testDone") {
          return obj.testDone === filter[key];
        }
        if (key === "empTZ") {
          return formatTimeZone(obj[key]).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "checkIn") {
          return obj.checkIn === filter[key];
          // return new Date(obj.checkIn) > filters[key].startDate._d && new Date(obj.checkIn) < filters[key].endDate._d;
        }
        if ((key === "programName" || key === "zoneColor") && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
    if (e.target.checked && user.scheduleID) {
      setAssignProgram((a) =>
        a.length > 0 ? (a[0].scheduleID === user.scheduleID ? [...a, user] : [...a]) : [...a, user]
      );
    } else {
      setAssignProgram(assignProgram.filter((f) => f.id !== user.id));
    }
  };
  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredUsers.map((t) => t.id) : []);
    const values = val ? filteredUsers.filter((f) => f.isSchedule === 2 && f) : [];
    let assignVal = [];
    if (values.length > 0) {
      const reduceVal = values.reduce((result, value) => {
        if (value.scheduleLinked && value.scheduleLinked.length > 0) {
          result[value.scheduleLinked] = (result[value.scheduleLinked] || 0) + 1;
        }
        return result;
      }, {});
      console.log("reduceVal", reduceVal);
      const maxVal = Object.keys(reduceVal).reduce((a, b) => (reduceVal[a] > reduceVal[b] ? a : b));
      const arrVal = maxVal.split(",");
      assignVal = values.filter((f) => JSON.stringify(f.scheduleLinked) === JSON.stringify(arrVal));
    }
    setAssignProgram(assignVal);
  };
  const createUser = async (user) => {
    try {
      setLoading(true);
      const phoneNo = setPhoneNo(user.phoneNumber);
      await api.newLocations(user.location);
      await api.newRegions(user.region);

      if (user.isNew) {
        const resultID = await api.newEmployee(user);
        appContext.showSuccessMessage("Employee created successfully");
        setCheckboxes([resultID]);
        setOpenConfirmation({
          isShow: true,
          actionType: CONFIRMATION_TYPE.ASSIGN_PROGRAM,
          title: "Assign Program",
          message: "Would you like to assign the program?",
        });
      } else {
        await api.updateEmployee(user);
        if (!user.onAlert) appContext.showSuccessMessage("Employee updated successfully");
      }
      await appContext.resetEmployees();
      await appContext.resetLocations();
      await appContext.resetRegions();
      getDepartments();
    } catch (error) {
      console.log("ERROR:User-", error);
      appContext.showErrorMessage(error.message);
      setOpenCreator(true);
    }
  };
  console.log("filteredUSer", filteredUsers);
  const getGroupTypeUsers = () => {
    const filterCheckData = users.filter((i) => checkboxes.includes(i.id) && i.groupType);
    const findVal = users.filter((i) => checkboxes.includes(i.id) && !i.groupType);
    if (findVal.length > 0 && !assignProgramSuccessMsg) {
      setAssignProgramSuccessMsg({ error: findVal });
    }
    setProgramMessage(
      `Success, you have Started program of ${filterCheckData.length} ${
        filterCheckData.length > 1 ? "employees" : "an employee"
      }`
    );
    return filterCheckData;
  };

  const handleProgram = async (val) => {
    const data = getGroupTypeUsers();
    if (Object.keys(data).length === 0 && !assignProgramSuccessMsg) {
      setShowAlert(true);
      setCheckboxes([]);
      setAssignProgram([]);
      setAllSelected(false);
      setAssignProgramSuccessMsg("");
      return;
    }
    try {
      setLoading(true);
      await api.updateEmployeeProgramState(data, val, null, appContext.user.name);
      // await saveProgramNotStartedIds();
      setCheckboxes([]);
      setAssignProgram([]);
      setAllSelected(false);
      await appContext.resetEmployees();
      if (val === 1) {
        setOpenProgram(true);
      }
    } catch (err) {
      console.log("Error Program", err);
      setLoading(false);
    }
  };
  const updateProgramID = useMemo(() => {
    if (appContext.employees && appContext.programs) {
      const dataList = appContext.employees
        .filter((f) => f.programName)
        .map((t) => {
          const prg = appContext.programs.find((f) => f.name === t.programName);
          if (prg) {
            return { ...t, programID: prg.id };
          }
        });
      return dataList;
    }
  }, [appContext.employees, appContext.programs]);

  console.log("updateProgramID", updateProgramID);

  const saveProgramNotStartedIds = async () => {
    try {
      const filterCheckData = users.filter((i) => checkboxes.includes(i.id) && i.isSchedule !== 1);
      const ids = filterCheckData.map((t) => {
        return {
          firstName: t.firstName,
          lastName: t.lastName,
          phoneNumber: t.phoneNumber,
          email: t.phoneNumber,
        };
      });
      const content = jsonToCSV(ids);
      const fileContent = new Blob([content], { type: "csv" });
      await FileSaver.saveAs(fileContent, "program_not_start_data.csv");
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleAssignProgramandStart = async (obj) => {
    console.log("obj", obj);
    const findVal = users.filter(
      (f) =>
        (checkboxes.includes(f.id) && f.departmentName === "-") ||
        (checkboxes.includes(f.id) &&
          f.departmentName &&
          departments.findIndex((d) => d.id === f.department && d.deptTimeZone) === -1)
    );
    let checkVal = checkboxes;
    if (findVal.length > 0) {
      checkVal = checkboxes.filter((chb) => findVal.findIndex((f) => f.id === chb) === -1);
    }
    const data = users.filter((f) => checkVal.includes(f.id));
    try {
      setLoading(true);
      await api.updateEmployeeProgramState(data, 1, obj, appContext.user.name);
      setProgramMessage(
        `Success, you have Started program of ${checkVal.length} ${checkVal.length > 1 ? "employees" : "an employee"}`
      );
      setAssignProgramSuccessMsg({
        success: null,
        error: findVal,
      });
      setCheckboxes([]);
      setAllSelected(false);
      setAssignProgram([]);
      appContext.resetEmployees();
      setOpenProgram(true);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };
  const handleAssignGroupSave = async (obj) => {
    // const checkboxez = checkboxes.filter((f)=> users.find((t)=> t.id === f &&  t.isSchedule !== 1))
    // if(checkboxez.length === 0) return
    console.log("obj", obj);
    const findVal = users.filter(
      (f) =>
        (checkboxes.includes(f.id) && f.departmentName === "-") ||
        (checkboxes.includes(f.id) &&
          f.departmentName &&
          departments.findIndex((d) => d.id === f.department && d.deptTimeZone) === -1)
    );
    let checkVal = checkboxes;
    if (findVal.length > 0) {
      checkVal = checkboxes.filter((chb) => findVal.findIndex((f) => f.id === chb) === -1);
    }
    try {
      setLoading(true);
      await api.updateEmployeeGroup(checkVal, obj);
      setAssignProgramSuccessMsg({
        success: `Success, you have assigned program of ${checkVal.length} ${
          checkVal.length > 1 ? "employees" : "employee"
        }`,
        error: findVal,
      });
      setCheckboxes([]);
      setAllSelected(false);
      setAssignProgram([]);
      appContext.resetEmployees();
      setDisplayMessage(true);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };

  const handleAssignGroupClose = () => {
    setOpenAssignModal(false);
  };
  const handleClose = () => {
    setOpenCreator(false);
    setOpenConfirmation({ isShow: false });
  };
  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.employees = { sortBy, sortDescending, filter, timeFilter };
    sortingFilterInLC.save(pervSortLS);
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredUsers);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);
  useEffect(() => {
    formatEmployees();
    setLoading(false);
  }, [appContext.employees]);

  useEffect(() => {
    formatEmployees();
  }, [appContext.schedules, departments, appContext.programs, appContext.company]);

  // useEffect(()=>{
  //   formatEmployees();
  //   if(appContext.company?.programLevel === "Zone"){
  //     console.log("personalize", personalize);
  //     const filterVal = personalize.filter((f)=> f.itemKey !== "isSchedule")
  //     setPersonalize(filterVal);
  //   }
  // },[appContext.company])
  useEffect(() => {
    formatEmployees();
  }, [appContext.questions]);

  useEffect(() => {
    handleSaveFilter();
    if (filteredUsers.length === 0) return;
    changeSort(sortBy, sortDescending, filteredUsers, setFilteredUsers);
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(users, filter)));
      setCurrentPage(1);
    }
  }, [filter]);

  const formatEmployees = () => {
    const emps = formatEmployeesData(
      appContext.employees,
      appContext.schedules,
      departments,
      appContext.programs,
      appContext.company
    );
    setUsers(emps);
    if (sortBy) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(emps, filter)));
    } else {
      setFilteredUsers(nestedFilter(emps, filter));
    }
  };

  if (filteredUsers.length > 0) {
    pageNumbers = getPageNumbers(filteredUsers);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleEditUser = (user) => {
    const obj = { ...user, isNew: false };
    setNewUser(obj);
    setOpenCreator(true);
  };

  const onDeleteConfirm = async () => {
    console.log("[DELETE USER]", userToDelete);

    let ttl = checkboxes.length;
    if (userToDelete || ttl > 0) {
      try {
        setLoading(true);
        if (userToDelete) {
          await api.deleteEmployee(userToDelete.id);
        } else if (ttl > 0) {
          for (let i = 0; i < ttl; i++) await api.deleteEmployee(checkboxes[i]);
        }

        setUserToDelete(null);
        setCheckboxes([]);
        setAssignProgram([]);
        setAllSelected(false);
        appContext.showSuccessMessage("Employee deleted successfully");
        appContext.resetEmployees();
      } catch (error) {
        appContext.showErrorMessage(error.message);
      }
    }
    setLoading(false);
  };

  const handleRemoveGroup = async () => {
    try {
      setLoading(true);
      await api.removeEmployeeGroup(checkboxes, appContext.isZoneLevelCompany(), appContext.user.name);
      setCheckboxes([]);
      setAllSelected(false);
      setAssignProgram([]);
      appContext.showSuccessMessage(
        `${checkboxes.length} ${checkboxes.length > 1 ? "employees" : "employee"} program removed succesfully`
      );
      appContext.resetEmployees();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleImport = async (empData) => {
    if (empData.length === 0) return;
    const companyObj = await api.getCompanySetting();
    let allEmployess = companyObj.employeeCounter;
    const programName = [];
    const arr = [];
    const arrdata = [];
    const empList = [...users];
    const newEmpIds = [];
    const departmentList = [...departments];
    const locList = [...appContext.locations];
    const regList = [...appContext.regions];
    const ttlLength = empData.length;
    const filterheader = EMPLOYEE_HEADERS.every((c) => Object.keys(empData[0].data).includes(c));
    if (!filterheader) {
      appContext.showErrorMessage("data not matched");
      setOpenImportModal(false);
      return;
    }
    setOpenImportModal(false);
    setLoading(true);

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data, countryCode: "", callTime: data.callTime || null };
      if (!emp.firstName && !emp.lastName && !emp.phoneNumber && !emp.email) continue;
      emp.firstName = getValidDep(emp.firstName || "");
      emp.lastName = getValidDep(emp.lastName || "");
      emp.isVaccinated = getVaccinated(emp.isVaccinated || "");
      emp.jobTitle = getValidSpaces(emp.jobTitle || "");
      emp.zip = getValidSpaces(emp.zip || "");
      emp.phoneNumber = getPhoneNumber(emp.phoneNumber || "");
      if (emp.location) {
        emp.location = getValidReg(emp.location);
        const locationVal = locList.find((loc) => checkValidity(loc.name) == checkValidity(emp.location));
        if (locationVal) {
          emp.location = locationVal.name;
        } else {
          await api.newLocations(emp.location);
          locList.push({ name: emp.location });
        }
      } else if (locList.length === 1) {
        emp.location = locList[0].name;
      }
      if (emp.region) {
        emp.region = getValidReg(emp.region);
        const regionVal = regList.find((reg) => checkValidity(reg.name) == checkValidity(emp.region));
        if (regionVal) {
          emp.region = regionVal.name;
        } else {
          await api.newRegions(emp.region);
          regList.push({ name: emp.region });
        }
      } else if (regList.length === 1) {
        emp.region = regList[0].name;
      }
      emp.callTime = getCallTime(emp.callTime);
      if (!isValidCallTime(emp.callTime)) {
        emp.callTime = null;
      }

      if (emp.department) {
        if (!emp.callTime) {
          emp.callTime = appContext.company.defaultCallTime;
        }
        if (!emp.deptTimeZone) {
          emp.deptTimeZone = appContext.company.defaultTimeZone;
        }
        emp.department = getValidReg(emp.department);
        const val = departmentList.find((curr) => checkValidity(curr.name) == checkValidity(emp.department));
        if (val) {
          emp.department = val.id;
        } else {
          const obj = { name: emp.department, callTime: emp.callTime, deptTimeZone: emp.deptTimeZone };
          const id = await api.addDepartment(obj);
          departmentList.push({ id: id, name: getValidReg(emp.department), callTime: emp.callTime });
          emp.department = id;
        }
      }
      if (emp.gender) {
        emp.gender = getValidGender(emp.gender);
      }
      if (emp.country) {
        emp.country = getValidDep(emp.country);
      } else {
        emp.country = "United States";
      }
      if (emp.department && !emp.callTime) {
        emp.callTime = departmentList.find((dpt) => emp.department === dpt.id)?.callTime || null;
      }

      if (emp.dob && !isValidDob(emp.dob)) {
        arr.push({
          message: `Invalid (${emp.dob}) Date of Birth format is (MM/DD/YYYY)  on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      if (emp.dob) {
        emp.dob = formatDOB(emp.dob);
      }

      emp.phoneNumber = importPhoneNoFormat(emp.phoneNumber);

      const phone = formatPhoneNumberIntl(emp.phoneNumber);
      console.log("phone", phone);
      const phoneArr = phone.split(" ");
      emp.countryCode = phoneArr[0];

      if (emp.phoneNumber && !validatePhone(emp.phoneNumber)) {
        arr.push({
          message: `phone number is not correct ${emp.phoneNumber} on row ${index + 2}`,
          data: emp,
        });
        continue;
      }

      if (emp.email && !isValidEmail(emp.email)) {
        arr.push({
          message: `Invalid email ${emp.email} on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      const findVal = await empList.find((curr) => curr.phoneNumber === emp.phoneNumber);
      if (findVal) {
        arr.push({
          message: `Employee Already exist on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      const program = emp.programName?.trim();
      if (program && programName.indexOf(program) === -1) {
        programName.push(program);
      }

      allEmployess = allEmployess + 1;

      const resultID = await api.newEmployee(emp, allEmployess);

      // get the new employee id and push into array to set as selected
      if (resultID) newEmpIds.push(resultID);

      empList.push({ firstName: emp.firstName, lastName: emp.lastName, dob: emp.dob });
      arrdata.push("Success");
    }
    if (programName.length > 0) {
      programName.forEach(async (p) => {
        await api.newProgramSettings({ name: p, color: "black" });
      });
    }

    getDepartments();
    await api.updateCompnayEmployeeNo(allEmployess);
    await appContext.resetEmployees();
    await appContext.resetLocations();
    await appContext.resetRegions();
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle("Employee");
      setCount(allEmployess);
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
    // set newly created employee as selected
    if (newEmpIds.length > 0) {
      setCheckboxes(newEmpIds);
    }
  };

  const handleImportEmployee = async (empData) => {
    if (empData.length === 0) return;
    console.log("Import Data", empData);
    const ttlLength = empData.length;
    const likeIds = [];
    const ids = [];
    let i = 0;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      if (data.name) {
        const arr = data.name.split(" ");

        const firstName = arr[0].toLowerCase().trim();
        const lastName = (arr[1] || "").trim().toLowerCase();
        console.log("Emp", firstName, lastName);
        const emp = appContext.employees.filter(
          (e) =>
            (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
              lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
            (e.firstName.toLowerCase().indexOf(firstName) !== -1 && e.lastName.toLowerCase().indexOf(lastName) !== -1)
        );
        if (emp.length === 0) {
          ids.push({
            firstName: firstName,
            lastName: lastName,
            hrFirst: "",
            hrLast: "",
            zone: data.programName,
          });
        } else {
          likeIds.push({
            id: emp[0].id,
            firstName: firstName,
            lastName: lastName,
            hrFirst: emp[0].firstName,
            hrLast: emp[0].lastName,
            zone: data.programName,
          });
        }
      }
    }

    likeIds.forEach(async (e) => {
      await api.updateEmployeeDefault(e.id, e.zone);
    });

    console.log("Like Search", likeIds);

    const content = jsonToCSV(ids);
    const fileContent = new Blob([content], { type: "csv" });
    // await FileSaver.saveAs(fileContent, "houston_zone_22_07_22.csv");
  };

  const handleImportEmployeeOld = async (empData) => {
    if (empData.length === 0) return;
    const ttlLength = empData.length;
    const likeIds = [];
    const ids = [];
    let i = 0;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      if (data.firstName) {
        const firstName = data.firstName.toLowerCase();
        const lastName = data.lastName.toLowerCase();
        const firstLike = data.firstName.toLowerCase().substring(0, 2);
        const lastLike = data.lastName.toLowerCase().substring(0, 2);
        const emp = appContext.employees.filter(
          (e) =>
            (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
              lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
            (e.firstName.toLowerCase().indexOf(firstName) !== -1 && e.lastName.toLowerCase().indexOf(lastName) !== -1)
        );
        if (emp.length === 0) {
          // ids.push({ firstName, lastName });
          const emp1 = appContext.employees.filter(
            (e) =>
              (firstLike === e.firstName.toLowerCase().substring(0, 2) &&
                lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
              (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
                lastLike === e.lastName.toLowerCase().substring(0, 2))
          );
          if (emp1.length === 0) {
            likeIds.push({ firstName: data.firstName, lastName: data.lastName, hrFirst: "", hrLast: "" });
          } else {
            likeIds.push({
              firstName: data.firstName,
              lastName: data.lastName,
              hrFirst: emp1[0].firstName,
              hrLast: emp1[0].lastName,
            });
          }
        } else {
          likeIds.push({
            firstName: data.firstName,
            lastName: data.lastName,
            hrFirst: emp[0].firstName,
            hrLast: emp[0].lastName,
          });
        }
      }
    }

    console.log("Like Search", likeIds);

    const content = jsonToCSV(likeIds);
    const fileContent = new Blob([content], { type: "csv" });
    //  await FileSaver.saveAs(fileContent, "houston_data_exists_20_07_22.csv");
  };

  const onHandleProgramSave = async (zone) => {
    try {
      const records = users.filter((f) => checkboxes.includes(f.id));
      if (records.length > 0) {
        setLoading(true);
        records.forEach(async (e) => {
          await api.updateEmployeeDefault(e.id, zone.label);
        });
        setLoading(false);
      }
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
    appContext.resetEmployees();
    setOpenAssignModal(false);
    setCheckboxes([]);
  };

  console.log(
    "Filter SHCR",
    users.filter((f) => f.schrID.includes("-010-000140"))
  );

  const handleTestNow = async (assignTest) => {
    console.log("assigntestEmpl", assignTest);
    setOpenTestNowModal(false);
    setLoading(true);
    try {
      await api.updateEmployeeTest(checkboxes, assignTest);
      appContext.showSuccessMessage("Assign Test Successfully");
      setCheckboxes([]);
      setAllSelected(false);
      appContext.resetEmployees();
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };
  const updateEmpProgramName = async (data) => {
    console.log("updateEmpProgramName", data);
    setShowProgramOptions(false);
    if (!data || checkboxes.length === 0) return;
    setLoading(true);
    const findVal = users.filter(
      (f) =>
        (checkboxes.includes(f.id) && f.departmentName === "-") ||
        (checkboxes.includes(f.id) &&
          f.departmentName &&
          departments.findIndex((d) => d.id === f.department && d.deptTimeZone) === -1)
    );
    let checkVal = checkboxes;
    if (findVal.length > 0) {
      checkVal = checkboxes.filter((chb) => findVal.findIndex((f) => f.id === chb) === -1);
    }
    try {
      await api.updateEmpProgramName(checkVal, data, STATUS.zoneAssign, appContext.user.name);
      setAssignProgramSuccessMsg({
        success: `Success, you have assigned program of ${checkVal.length} ${
          checkVal.length > 1 ? "employees" : "employee"
        }`,
        error: findVal,
      });
      appContext.resetEmployees();
      setCheckboxes([]);
      setAllSelected(false);
      setLoading(false);
      setDisplayMessage(true);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };
  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      switch (type) {
        case CONFIRMATION_TYPE.DELETE:
          setCheckboxes([]);
          setAllSelected(false);
          setAssignProgram([]);
          setUserToDelete(null);
          break;
        case CONFIRMATION_TYPE.PROGRAM_START:
          handleAssignGroupSave(programData);
          break;
        case CONFIRMATION_TYPE.ASSIGN_PROGRAM:
          setCheckboxes([]);
          setAllSelected(false);
          setAssignProgram([]);
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        onDeleteConfirm();
        break;
      case CONFIRMATION_TYPE.PROGRAM_START:
        handleAssignProgramandStart(programData);
        break;
      case CONFIRMATION_TYPE.ASSIGN_PROGRAM:
        if (appContext.isZoneLevelCompany()) {
          setShowProgramOptions(true);
        } else {
          setOpenAssignModal(true);
        }
        break;
    }
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = personalizationLocalStorage.saveAs(data, 0);
    setOpenPersonalizationModal(false);
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const tdFormat = (item, user) => {
    if (item === "updatedAt") return formatDate(user.updatedAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "isVaccinated") return parseBooleanValue(user.isVaccinated);
    if (item === "departmentName") return user.departmentName ? user.departmentName : "";
    if (item === "testingGroup") return user.testingGroup ? user.testingGroup : "-";
    if (item === "jobTitle") return user.jobTitle ? user.jobTitle : "";
    if (item === "localNo") return user.localNo ? user.localNo : "-";
    if (item === "callTime") return user.callTime ? `${user.callTime}` : "-";
    return user[item];
  };

  const popOverFn = (item, user, itemKey) => {
    if (appContext.company?.programLevel === "Zone" && itemKey === "tests") {
      return <ZonePopoverStick item={item} user={user} itemKey={itemKey} />;
    } else {
      return <PopoverStick item={item} user={user} itemKey={itemKey} />;
    }
  };
  const renderTd = (item, user) => {
    if (item.itemKey === "email") return tdEmail(user.email);
    if (item.itemKey === "phoneNumber") return tdPhone(user.phoneNumber);
    if (item.itemKey === "qaDone") return tdCheckBox(item, user);
    if (item.itemKey === "testDone") {
      if (appContext.isZoneLevelCompany()) {
        return tdCheckBox(item, user, openExternalTest);
      }
      return tdCheckBox(item, user);
    }
    if (item.itemKey === "checkIn") return tdCheckBox(item, user);
    if (item.itemKey === "onBoardingTesting") return tdCheckBox(item, user);
    if (item.itemKey === "isSchedule") return tdProgramStatus(item, user);
    if (item.itemKey === "zoneColor") return tdZoneColor(item, user);
    if (item.itemKey === "scheduleName") return popOverFn(item, user);
    if (item.itemKey === "tests") return popOverFn(item, user, item.itemKey);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const openDeleteConfirmation = (user) => {
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: "Delete User",
      message: "Are you sure, you want to remove Employee?",
    });
    if (user) setUserToDelete(user);
  };

  const openExternalTest = (user, type) => {
    console.log("openExternalTest", user, type);
    setProgramData(user);
    setSelectedType(type);
    setShowExternalTest(true);
  };

  const handleExternaltest = async (userObject, logObject) => {
    console.log("[handleExternaltest]", userObject);
    setShowExternalTest(false);
    setProgramData([]);
    setSelectedType("");
    if (!userObject) return;
    try {
      setLoading(true);
      await api.zoneLevelExternalTest(userObject);
      if (logObject) {
        await api.addlogs([logObject]);
      }
      appContext.showSuccessMessage("Successfully add external test");
      appContext.resetEmployees();
      setCheckboxes([]);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const handleHistoryView = (user) => {
    setSelectedEmployee(user);
  };
  console.log("filter", filter);
  const TableRow = ({ user }) => {
    return (
      <tr>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <div className="d-flex align-items-center w-100 column-gap-10">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={checkboxes.indexOf(user.id) !== -1}
                value={user.id}
                onChange={(e) => handleCheckboxChange(e, user)}
              />
            </Form.Check>
            <img
              src={EditIcon}
              width="18"
              alt="edit icon"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={user.id}
              onClick={(e) => handleEditUser(user)}
            />
          </div>
        </td>

        {personalize.map((item) => item.isCheck && renderTd(item, user))}

        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          {appContext.company?.programLevel === "Zone" ? (
            <div
              style={{ textAlign: "center", color: "#42cef5", textAlign: "center", cursor: "Pointer" }}
              onClick={() => handleHistoryView(user)}
            >
              View
            </div>
          ) : (
            <img
              src={TrashIcon}
              width="15"
              alt="trash icon"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              id={user.id}
              onClick={(e) => {
                openDeleteConfirmation(user);
              }}
            />
          )}
        </td>
      </tr>
    );
  };

  const headerAlign = (item) => (headerAlignKeys.includes(item) ? "centered" : undefined);
  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Employees ({filteredUsers ? filteredUsers.length : users.length})
                </Card.Title>
                <div className="buttonHeader">
                  <Button className="headerButton btn-fill" onClick={() => setShowFilter(!showFilter)}>
                    Filter
                  </Button>
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={() => {
                      if (filteredUsers && filteredUsers.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                  >
                    {!allSelected ? "Select All" : "Deselect All"}
                  </Button>
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    disabled={checkboxes.length === 0}
                    onClick={() => openDeleteConfirmation()}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={() => {
                      setNewUser({ ...EmptyUserObj });
                      setOpenCreator(true);
                    }}
                  >
                    Create Employee
                  </Button>

                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={() => {
                      setOpenImportModal(true);
                    }}
                  >
                    Load Employee Data
                  </Button>

                  {appContext.company?.programLevel !== "Zone" && (
                    <>
                      <Button
                        className="headerButton btn-fill"
                        variant="secondary"
                        disabled={checkboxes.length === 0}
                        onClick={() => setOpenTestNowModal(true)}
                      >
                        Test Now
                      </Button>
                      <Button
                        variant="secondary"
                        className="headerButton btn-fill"
                        onClick={() => {
                          setOpenAssignModal(true);
                        }}
                        disabled={checkboxes.length === 0}
                      >
                        Assign Program
                      </Button>
                      <Button
                        variant="secondary"
                        className="headerButton btn-fill"
                        onClick={(e) => handleProgram(1)}
                        disabled={checkboxes.length === 0}
                      >
                        Start Program
                      </Button>
                      <ExportSelectedData filteredUsers={filteredUsers} uniquePrograms={uniquePrograms} />
                    </>
                  )}
                  {appContext.company?.programLevel === "Zone" && (
                    <Button
                      variant="secondary"
                      className="headerButton btn-fill"
                      onClick={() => setShowProgramOptions(true)}
                      disabled={checkboxes.length === 0}
                    >
                      Assign Program
                    </Button>
                  )}
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={handleRemoveGroup}
                    disabled={checkboxes.length === 0}
                  >
                    Remove Program
                  </Button>
                  <ExportToExcel
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    items={filteredUsers}
                    appContext={appContext}
                    filter={filter}
                    title="Employee Data"
                    sortBy={sortBy}
                  />
                  <Button
                    variant="secondary"
                    className="headerButton btn-fill"
                    onClick={() => setOpenPersonalizationModal(true)}
                  >
                    Personalize
                  </Button>
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                    isZoneLevel={appContext.isZoneLevelCompany()}
                  />
                )}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    filteredUsers.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className="user-table employeeTableWrapper">
                    <thead ref={componentRef}>
                      <tr>
                        <th />
                        {personalize.map(
                          (item, i) =>
                            item.isCheck && (
                              <HeaderItem
                                key={i}
                                width={tdWidth}
                                ItemKey={item.itemKey}
                                title={item.title}
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                aligned={headerAlign(item.itemKey)}
                              />
                            )
                        )}
                        <th
                          className={`border-0 ellipsis centered`}
                          style={{
                            color: "#231f20",
                            fontWeight: "bold",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersToMap &&
                        usersToMap.map((user, i) => {
                          return <TableRow user={user} key={i} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {showAlert && (
        <SweetAlert
          btnSize="md"
          info
          onConfirm={() => {
            setShowAlert(false);
            setAssignProgramSuccessMsg("");
          }}
        >
          Schedule Not assigned
        </SweetAlert>
      )}
      {openCreator && <EmployeeModal user={newUser} handleClose={handleClose} handleSave={createUser} />}

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
        />
      )}

      {openImportModal && (
        <EmployeeImportModal handleClose={() => setOpenImportModal(false)} handleImport={handleImport} />
      )}
      {openProgram && (
        <StartProgramModal
          successMessage={programMessage}
          assignProgramErrorMsg={assignProgramSuccessMsg?.error}
          appContext={appContext}
          msg={
            Object.keys(assignProgramSuccessMsg).length === 1
              ? "Schdule Not assigned"
              : "Department or Department TimeZone not Assigned"
          }
          handleClose={() => {
            setOpenProgram(false);
            setProgramData(null);
            setAssignProgramSuccessMsg("");
          }}
        />
      )}
      {displayMessage && (
        <StartProgramModal
          successMessage={assignProgramSuccessMsg?.success}
          assignProgramErrorMsg={assignProgramSuccessMsg?.error}
          appContext={appContext}
          msg={"Department or Department TimeZone not Assigned"}
          assignProgram
          handleClose={() => {
            setDisplayMessage(false);
            setProgramData(null);
            setAssignProgramSuccessMsg("");
          }}
        />
      )}
      {openErrModal && (
        <ImportErrorModal
          title={title}
          errData={errorData}
          count={count}
          successData={successData}
          handleClose={async (newIds) => {
            if (newIds.length > 0 || checkboxes.length > 0) {
              setCheckboxes(checkboxes.concat(newIds));
              setOpenConfirmation({
                isShow: true,
                actionType: CONFIRMATION_TYPE.ASSIGN_PROGRAM,
                title: "Assign Program",
                message: "Would you like to assign the program?",
              });
            }
            setOpenErrModal(false);
            setErrorData([]);
            setsuccessData([]);
            setTitle("");
            await appContext.resetEmployees();
            await appContext.resetLocations();
            await appContext.resetRegions();
          }}
        />
      )}
      {openAssignModal && (
        <AssignGroupModal
          handleClose={handleAssignGroupClose}
          handleSave={(obj) => {
            setProgramData(obj);
            setOpenConfirmation({
              isShow: true,
              actionType: CONFIRMATION_TYPE.PROGRAM_START,
              title: "Start Program",
              message: "Would you like to start the Program?",
            });
          }}
          handleProgramSave={onHandleProgramSave}
          data={assignProgram[0]}
        />
      )}
      {openTestNowModal && (
        <TestNowModal
          handleClose={() => setOpenTestNowModal(false)}
          handleSave={handleTestNow}
          company={appContext.company}
        />
      )}
      {showProgramOptions && (
        <ProgramsOptionsModal
          data={appContext.programs}
          handleClose={() => setShowProgramOptions(false)}
          handleSave={updateEmpProgramName}
        />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {showExternalTest && (
        <ExternalTestProgramModal
          handleConfirm={handleExternaltest}
          user={programData}
          selectedType={selectedType}
          companyType={appContext.isZoneLevelCompany()}
        />
      )}
      {selectedEmployee && <EmployeeAuditTrail user={selectedEmployee} handleClose={() => setSelectedEmployee(null)} />}
    </div>
  );
};

export default Employees;
