import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import ColorLabel from "components/ColorLabel";
import { toTitleCase } from "utils";
export const PAGE_LIMIT = 20;

export const PROFILE_IMAGE_PATH = "https://medflow-images.s3.eu-west-1.amazonaws.com/public/profile/";
export const EXTRERNAL_IMAGE_PATH = "https://employee-external-tests.s3.eu-west-1.amazonaws.com/public/";

export const MESSAGE_TYPE = {
  default: "default",
  info: "info",
  success: "success",
  warning: "warning",
  danger: "danger",
  error: "error",
  input: "input",
  custom: "custom",
  controlled: "controlled",
};

export const TEST_HEADER_TYPE = {
  unprocessed: "unprocessed",
  completed: "completed",
  pending: "pending",
};

export const TEST_STATUS = {
  Sent: "Sent",
  Processing: "Processing",
  Pending: "Pending",
  Processed: "Processed",
};

export const newRoleOptions = [
  {
    value: "Admin",
    label: "Admin",
  },
  // {
  //   value: "Employee",
  //   label: "Employee",
  // },
  // {
  //   value: "Coordinator",
  //   label: "Coordinator",
  // },
  // {
  //   value: "Staff",
  //   label: "Staff",
  // },
];

export const USER_ROLE_TYPE = {
  Admin: "Admin",
  Coordinator: "Coordinator",
  Staff: "Staff",
  Employee: "Employee",
};

export const HEALTH_AND_SAFTEY = "Health & Safety";

export const EmptyUserObj = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  isNew: true,
  countryCode: "",
};
export const TEST_TYPE_VALUE = {
  Lucira: "Lucira",
  PCR: "PCR",
  Antigen: "Rapid Antigen",
  Other: "Rapid PCR",
  Molecular: "Cue",
};

export const AMAZON_CLIENT_RESULT = {
  Positive: "Fail",
  Negative: "Pass",
  Expired: "Expired",
  positive: "Fail",
  negative: "Pass",
  expired: "Expired",
  Pass: "Pass",
  Fail: "Fail",
  pass: "Pass",
  fail: "Fail",
};

export const RESULT_COLOR = {
  positive: "red",
  negative: "green",
  expired: "orange",
  pass: "green",
  fail: "red",
};

export const weekDaysOptions = [
  {
    value: "0",
    label: "SUN",
  },
  {
    value: "1",
    label: "MON",
  },
  {
    value: "2",
    label: "TUE",
  },
  {
    value: "3",
    label: "WED",
  },
  {
    value: "4",
    label: "THU",
  },
  {
    value: "5",
    label: "FRI",
  },
  {
    value: "6",
    label: "SAT",
  },
];

export const TestTypeOptions = [
  {
    value: "Antigen",
    label: "Antigen",
  },
  {
    value: "Molecular",
    label: "Molecular",
  },
];

export const WEEK_DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const SUPER_ADMIN_ID = "20cafd4b-2671-4854-8389-4f185d60c69b";

export const EmptyEmployeeObj = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  isNew: true,
};

export const QuestionStatus = {
  new: 1,
  edit: 2,
  delete: 3,
};

export const SCHEDULE_TYPES = {
  everyday: "Every Day",
  selecteddays: "Selected Days",
  untilcancel: "Until Cancel",
};

export const QUESTION_GROUP_TYPES = {
  questionandtesting: {
    value: 1,
    label: "Question/Testing",
    shortCode: "QT",
  },
  question: { value: 2, label: "Question", shortCode: "Q" },
  testing: { value: 3, label: "Testing", shortCode: "T" },
};

export const LOCATION_OPTIONS = [
  { value: "Cupertino", label: "Cupertino" },
  { value: "Los Angeles", label: "Los Angeles" },
  { value: "Austin", label: "Austin" },
  { value: "Nashville", label: "Nashville" },
  { value: "New York", label: "New York" },
  { value: "London", label: "London" },
  { value: "Singapore", label: "Singapore" },
  { value: "Tokyo", label: "Tokyo" },
];

export const REGION_OPTIONS = [
  { value: "Americas", label: "Americas" },
  { value: "EMEA", label: "EMEA" },
  { value: "Asia Pac", label: "Asia Pac" },
  { value: "Japan", label: "Japan" },
];
export const GROUP_TYPES = {
  1: { value: 1, label: "Question/Testing", shortCode: "QT" },
  2: { value: 2, label: "Question", shortCode: "Q" },
  3: { value: 3, label: "Testing", shortCode: "T" },
};

export const STATUS_OPTIONS = [
  { value: "0", label: "Empty" },
  {
    value: "1",
    label: <ViewCheckBox id="filter" bgColor="green" />,
  },
  {
    value: "2",
    label: <ViewCheckBox id="filter" bgColor="yellow" />,
  },
];
export const ZONE_STATUS_OPTIONS = [
  { value: "0", label: "Empty" },
  {
    value: "3",
    label: <ViewCheckBox id="filter" bgColor="green" />,
  },
];

export const QA_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
  { value: "M", label: <ViewCheckBox id="filter" bgColor="blue" /> },
  { value: "X", label: <div style={{ color: "red" }}>X</div> },
];
export const TEST_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
  { value: "X", label: <div style={{ color: "red" }}>X</div> },
];
export const CHECKIN_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
];

export const TEST_TYPES = [
  { value: "Molecular", label: "Cue" },
  { value: "PCR", label: "PCR" },
  { value: "Antigen", label: "Rapid Antigen" },
  { value: "Other", label: "Rapid PCR" },
  { value: "Lucira", label: "Lucira" },
];

export const VACCINATED_OPTIONS = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];
export const ON_LOCATION_OPT = [
  { value: "onLocation", label: "onLocation" },
  { value: "At Home", label: "At Home" },
];
export const MESSAGE_SEND_TYPE = [
  { value: "E", label: "Email" },
  { value: "M", label: "Text Message" },
];
export const SCHEDULE_TYPE_EVERY_DAY = "everyday";
export const SCHEDULE_TYPE_SELECTED_DAYS = "selecteddays";
export const SCHEDULE_TYPE_UNTIL_CANCEL = "untilcancel";

export const AssignGroup_Type_Question_Testing = "questionandtesting";
export const AssignGroup_Type_Question_Only = "question";
export const AssignGroup_Type_testing_Only = "testing";

export const DAYS_LABEL = {
  0: "SUN",
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
};
export const COLOR_OPT = [
  { value: "Black", label: <ColorLabel color={"Black"} /> },
  { value: "Green", label: <ColorLabel color={"Green"} /> },
  { value: "Blue", label: <ColorLabel color={"Blue"} /> },
  { value: "Gray", label: <ColorLabel color={"Gray"} /> },
  { value: "Brown", label: <ColorLabel color={"Brown"} /> },
  { value: "Deep Pink", label: <ColorLabel color={"Deep Pink"} /> },
  { value: "Purple", label: <ColorLabel color={"Purple"} /> },
  { value: "Orange", label: <ColorLabel color={"Orange"} /> },
];

export const COLOR_VALUE = {
  black: "Black",
  green: "Green",
  blue: "Blue",
  gray: "Gray",
  pink: "Deep Pink",
  purple: "Purple",
  orange: "Orange",
};

export const NEW_PERSONALIZE = [
  {
    itemKey: "firstName",
    title: "First Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 1,
  },
  {
    itemKey: "lastName",
    title: "Last Name",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
  },
  {
    itemKey: "email",
    title: "Email",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 3,
  },
  {
    itemKey: "phoneNumber",
    title: "Phone Number",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 4,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "left",
    textOverflow: "visible",
    isCheck: true,
    id: 5,
  },
  {
    itemKey: "region",
    title: "Region",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 6,
  },
  {
    itemKey: "location",
    title: "Office",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 7,
  },
  {
    itemKey: "jobTitle",
    title: "Job Title",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 8,
  },
  {
    itemKey: "dob",
    title: "Date Of Birth",
    textAlign: "",
    textOverflow: "visible",
    isCheck: true,
    id: 9,
  },
  {
    itemKey: "isVaccinated",
    title: "Vaccinated",
    isCheck: true,
    textAlign: "center",
    textOverflow: "visible",
    id: 10,
  },
  {
    itemKey: "schrID",
    title: "SCHR ID",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 11,
  },
  {
    itemKey: "gender",
    title: "Gender",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 12,
  },
  {
    itemKey: "departmentName",
    title: "Department",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 13,
  },
  {
    itemKey: "callTime",
    title: "Call Time",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 14,
  },
  {
    itemKey: "programName",
    title: "Progarm Name",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 15,
  },
  {
    itemKey: "zoneColor",
    title: "Zone Color",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 16,
  },
  {
    itemKey: "testingGroup",
    title: "Screening Type",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 17,
  },
  {
    itemKey: "scheduleName",
    title: "Schedule",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 18,
  },
  {
    itemKey: "localNo",
    title: "Local #",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 19,
  },
  {
    itemKey: "qaDone",
    title: "Q/A",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 20,
  },
  {
    itemKey: "testDone",
    title: "Test",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 21,
  },
  {
    itemKey: "tests",
    title: "Test type",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 22,
  },
  {
    itemKey: "checkIn",
    title: "Check In",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 23,
  },
  {
    itemKey: "updatedAt",
    title: "Last Update",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 24,
  },
  {
    itemKey: "isSchedule",
    title: "Program Status",
    textAlign: "center",
    textOverflow: "visible",
    isCheck: true,
    id: 25,
  },
];

export const EMPLOYEE_HEADERS = ["firstName", "lastName", "phoneNumber", "email"];
export const LOCATION = ["cupertino", "los angeles", "austin", "nashville", "new york", "london", "singapore", "tokyo"];
export const REGION = ["americas", "emea", "asia pac", "japan"];

export const TIMEZONE_OPT = [
  { label: "Eastern Standard Time", value: "Eastern Standard Time" },
  { label: "Central Standard Time", value: "Central Standard Time" },
  { label: "Mountain Standard Time", value: "Mountain Standard Time" },
  { label: "Pacific Standard Time", value: "Pacific Standard Time" },
  { label: "Pakistan Standard Time", value: "Pakistan Standard Time" },
];

export const TIMEZONE_VALUE = {
  est: "Eastern Standard Time",
  cst: "Central Standard Time",
  mst: "Mountain Standard Time",
  pst: "Pacific Standard Time",
  pkt: "Pakistan Standard Time",
};

export const SCHEDULE_CATEGORY = {
  TESTING: "Testing",
  QUESTIONS: "Question",
};

export const SCHEDULE_OPTIONS = [
  {
    id: 0,
    name: "Sunday",
    isChecked: false,
    testType: [{ value: "", label: "", location: "", isDone: false, scheduleCategory: "Testing", result: "" }],
  },
  {
    id: 1,
    name: "Monday",
    isChecked: false,
    testType: [{ value: "", label: "", location: "", isDone: false, scheduleCategory: "Testing", result: "" }],
  },
  {
    id: 2,
    name: "Tuesday",
    isChecked: false,
    testType: [{ value: "", label: "", location: "", isDone: false, scheduleCategory: "Testing", result: "" }],
  },
  {
    id: 3,
    name: "Wednesday",
    isChecked: false,
    testType: [{ value: "", label: "", location: "", isDone: false, scheduleCategory: "Testing", result: "" }],
  },
  {
    id: 4,
    name: "Thursday",
    isChecked: false,
    testType: [{ value: "", label: "", location: "", isDone: false, scheduleCategory: "Testing", result: "" }],
  },
  {
    id: 5,
    name: "Friday",
    isChecked: false,
    testType: [{ value: "", label: "", location: "", isDone: false, scheduleCategory: "Testing", result: "" }],
  },
  {
    id: 6,
    name: "Saturday",
    isChecked: false,
    testType: [
      { value: "", label: "", location: "", testNow: false, isDone: false, scheduleCategory: "Testing", result: "" },
    ],
  },
];

export const DAYS = [0, 1, 2, 3, 4, 5, 6];

export const CONFIRMATION_TYPE = {
  DELETE: "delete",
  PROGRAM_START: "programstart",
  ASSIGN_PROGRAM: "assignprogram",
  UPDATE_TIME: "updateTime",
};

export const DAILY_QA_STATUS = {
  X: false,
  1: true,
  M: true,
  0: null,
};

export const Audit_Entries = ["Program Started", "Pre Screening Question", "Covid Test", "Program Ended"];
export const Zone_Audit_Entries = ["Program Assigned", "Covid Test", "Program Removed"];

export const Audit_Types = {
  StartProgram: "Program Started",
  EndProgram: "Program Ended",
  CovidTest: "Covid Test",
  ExternalTest: "Covid Test",
  PreScreeningQuestion: "Pre Screening Question",
  ClearedForWork: "Pre Screening Question",
};

export const Zone_Audit_Types = {
  AssignProgram: "Program Assigned",
  RemoveProgram: "Program Removed",
  CovidTest: "Covid Test",
  ExternalTest: "Covid Test",
};
export const HISTORY_TEST = [
  {
    date: "2022-09-12",
    id: "3031b1ba-7101-4ff7-b5e1-e2c01768426d",
    isDone: false,
    label: "PCR",
    location: "On Location",
    lucira: false,
    result: "Positive",
    scheduleCategory: "Testing",
    value: "PCR",
  },
  {
    date: "2022-09-06",
    id: "31ebc832-6f1a-4cd3-aa94-4fbf9d87c41f",
    isDone: false,
    label: "Rapid PCR",
    location: "On Location",
    lucira: false,
    result: "Negative",
    scheduleCategory: "Testing",
    value: "Other",
  },
  {
    date: "2022-09-12",
    id: "c4cbb706-8a15-4996-9790-91fb9cd48cb1",
    isDone: false,
    label: "Cue",
    location: "On Location",
    lucira: false,
    result: "Invlaid",
    scheduleCategory: "Testing",
    value: "Molecular",
  },
];

export const DAYS_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const STATUS = {
  assignProgram: 2,
  startProgram: 1,
  zoneAssign: 3,
  removeProgram: 0,
};

export const countryListAllIsoData = [
  { code: "AF", code3: "AFG", name: "Afghanistan", number: "004" },
  { code: "AL", code3: "ALB", name: "Albania", number: "008" },
  { code: "DZ", code3: "DZA", name: "Algeria", number: "012" },
  { code: "AS", code3: "ASM", name: "American Samoa", number: "016" },
  { code: "AD", code3: "AND", name: "Andorra", number: "020" },
  { code: "AO", code3: "AGO", name: "Angola", number: "024" },
  { code: "AI", code3: "AIA", name: "Anguilla", number: "660" },
  { code: "AQ", code3: "ATA", name: "Antarctica", number: "010" },
  { code: "AG", code3: "ATG", name: "Antigua and Barbuda", number: "028" },
  { code: "AR", code3: "ARG", name: "Argentina", number: "032" },
  { code: "AM", code3: "ARM", name: "Armenia", number: "051" },
  { code: "AW", code3: "ABW", name: "Aruba", number: "533" },
  { code: "AU", code3: "AUS", name: "Australia", number: "036" },
  { code: "AT", code3: "AUT", name: "Austria", number: "040" },
  { code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031" },
  { code: "BS", code3: "BHS", name: "Bahamas", number: "044" },
  { code: "BH", code3: "BHR", name: "Bahrain", number: "048" },
  { code: "BD", code3: "BGD", name: "Bangladesh", number: "050" },
  { code: "BB", code3: "BRB", name: "Barbados", number: "052" },
  { code: "BY", code3: "BLR", name: "Belarus", number: "112" },
  { code: "BE", code3: "BEL", name: "Belgium", number: "056" },
  { code: "BZ", code3: "BLZ", name: "Belize", number: "084" },
  { code: "BJ", code3: "BEN", name: "Benin", number: "204" },
  { code: "BM", code3: "BMU", name: "Bermuda", number: "060" },
  { code: "BT", code3: "BTN", name: "Bhutan", number: "064" },
  { code: "BO", code3: "BOL", name: "Bolivia", number: "068" },
  { code: "BQ", code3: "BES", name: "Bonaire, Sint Eustatius and Saba", number: "535" },
  { code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", number: "070" },
  { code: "BW", code3: "BWA", name: "Botswana", number: "072" },
  { code: "BV", code3: "BVT", name: "Bouvet Island", number: "074" },
  { code: "BR", code3: "BRA", name: "Brazil", number: "076" },
  { code: "IO", code3: "IOT", name: "British Indian Ocean Territory", number: "086" },
  { code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096" },
  { code: "BG", code3: "BGR", name: "Bulgaria", number: "100" },
  { code: "BF", code3: "BFA", name: "Burkina Faso", number: "854" },
  { code: "BI", code3: "BDI", name: "Burundi", number: "108" },
  { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132" },
  { code: "KH", code3: "KHM", name: "Cambodia", number: "116" },
  { code: "CM", code3: "CMR", name: "Cameroon", number: "120" },
  { code: "CA", code3: "CAN", name: "Canada", number: "124" },
  { code: "KY", code3: "CYM", name: "Cayman Islands", number: "136" },
  { code: "CF", code3: "CAF", name: "Central African Republic", number: "140" },
  { code: "TD", code3: "TCD", name: "Chad", number: "148" },
  { code: "CL", code3: "CHL", name: "Chile", number: "152" },
  { code: "CN", code3: "CHN", name: "China", number: "156" },
  { code: "CX", code3: "CXR", name: "Christmas Island", number: "162" },
  { code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands", number: "166" },
  { code: "CO", code3: "COL", name: "Colombia", number: "170" },
  { code: "KM", code3: "COM", name: "Comoros", number: "174" },
  { code: "CD", code3: "COD", name: "Congo, The Democratic Republic of the", number: "180" },
  { code: "CG", code3: "COG", name: "Congo", number: "178" },
  { code: "CK", code3: "COK", name: "Cook Islands", number: "184" },
  { code: "CR", code3: "CRI", name: "Costa Rica", number: "188" },
  { code: "HR", code3: "HRV", name: "Croatia", number: "191" },
  { code: "CU", code3: "CUB", name: "Cuba", number: "192" },
  { code: "CW", code3: "CUW", name: "Curaçao", number: "531" },
  { code: "CY", code3: "CYP", name: "Cyprus", number: "196" },
  { code: "CZ", code3: "CZE", name: "Czechia", number: "203" },
  { code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384" },
  { code: "DK", code3: "DNK", name: "Denmark", number: "208" },
  { code: "DJ", code3: "DJI", name: "Djibouti", number: "262" },
  { code: "DM", code3: "DMA", name: "Dominica", number: "212" },
  { code: "DO", code3: "DOM", name: "Dominican Republic", number: "214" },
  { code: "EC", code3: "ECU", name: "Ecuador", number: "218" },
  { code: "EG", code3: "EGY", name: "Egypt", number: "818" },
  { code: "SV", code3: "SLV", name: "El Salvador", number: "222" },
  { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226" },
  { code: "ER", code3: "ERI", name: "Eritrea", number: "232" },
  { code: "EE", code3: "EST", name: "Estonia", number: "233" },
  { code: "SZ", code3: "SWZ", name: "Eswatini", number: "748" },
  { code: "ET", code3: "ETH", name: "Ethiopia", number: "231" },
  { code: "FK", code3: "FLK", name: "Falkland Islands (Malvinas)", number: "238" },
  { code: "FO", code3: "FRO", name: "Faroe Islands", number: "234" },
  { code: "FJ", code3: "FJI", name: "Fiji", number: "242" },
  { code: "FI", code3: "FIN", name: "Finland", number: "246" },
  { code: "FR", code3: "FRA", name: "France", number: "250" },
  { code: "GF", code3: "GUF", name: "French Guiana", number: "254" },
  { code: "PF", code3: "PYF", name: "French Polynesia", number: "258" },
  { code: "TF", code3: "ATF", name: "French Southern Territories", number: "260" },
  { code: "GA", code3: "GAB", name: "Gabon", number: "266" },
  { code: "GM", code3: "GMB", name: "Gambia", number: "270" },
  { code: "GE", code3: "GEO", name: "Georgia", number: "268" },
  { code: "DE", code3: "DEU", name: "Germany", number: "276" },
  { code: "GH", code3: "GHA", name: "Ghana", number: "288" },
  { code: "GI", code3: "GIB", name: "Gibraltar", number: "292" },
  { code: "GR", code3: "GRC", name: "Greece", number: "300" },
  { code: "GL", code3: "GRL", name: "Greenland", number: "304" },
  { code: "GD", code3: "GRD", name: "Grenada", number: "308" },
  { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312" },
  { code: "GU", code3: "GUM", name: "Guam", number: "316" },
  { code: "GT", code3: "GTM", name: "Guatemala", number: "320" },
  { code: "GG", code3: "GGY", name: "Guernsey", number: "831" },
  { code: "GN", code3: "GIN", name: "Guinea", number: "324" },
  { code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624" },
  { code: "GY", code3: "GUY", name: "Guyana", number: "328" },
  { code: "HT", code3: "HTI", name: "Haiti", number: "332" },
  { code: "HM", code3: "HMD", name: "Heard Island and McDonald Islands", number: "334" },
  { code: "VA", code3: "VAT", name: "Holy See (Vatican City State)", number: "336" },
  { code: "HN", code3: "HND", name: "Honduras", number: "340" },
  { code: "HK", code3: "HKG", name: "Hong Kong", number: "344" },
  { code: "HU", code3: "HUN", name: "Hungary", number: "348" },
  { code: "IS", code3: "ISL", name: "Iceland", number: "352" },
  { code: "IN", code3: "IND", name: "India", number: "356" },
  { code: "ID", code3: "IDN", name: "Indonesia", number: "360" },
  { code: "IR", code3: "IRN", name: "Iran, Islamic Republic Of", number: "364" },
  { code: "IQ", code3: "IRQ", name: "Iraq", number: "368" },
  { code: "IE", code3: "IRL", name: "Ireland", number: "372" },
  { code: "IM", code3: "IMN", name: "Isle of Man", number: "833" },
  { code: "IL", code3: "ISR", name: "Israel", number: "376" },
  { code: "IT", code3: "ITA", name: "Italy", number: "380" },
  { code: "JM", code3: "JAM", name: "Jamaica", number: "388" },
  { code: "JP", code3: "JPN", name: "Japan", number: "392" },
  { code: "JE", code3: "JEY", name: "Jersey", number: "832" },
  { code: "JO", code3: "JOR", name: "Jordan", number: "400" },
  { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398" },
  { code: "KE", code3: "KEN", name: "Kenya", number: "404" },
  { code: "KI", code3: "KIR", name: "Kiribati", number: "296" },
  { code: "KP", code3: "PRK", name: "Korea, Democratic People's Republic of", number: "408" },
  { code: "KR", code3: "KOR", name: "Korea, Republic of", number: "410" },
  { code: "KW", code3: "KWT", name: "Kuwait", number: "414" },
  { code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417" },
  { code: "LA", code3: "LAO", name: "Lao People's Democratic Republic", number: "418" },
  { code: "LV", code3: "LVA", name: "Latvia", number: "428" },
  { code: "LB", code3: "LBN", name: "Lebanon", number: "422" },
  { code: "LS", code3: "LSO", name: "Lesotho", number: "426" },
  { code: "LR", code3: "LBR", name: "Liberia", number: "430" },
  { code: "LY", code3: "LBY", name: "Libya", number: "434" },
  { code: "LI", code3: "LIE", name: "Liechtenstein", number: "438" },
  { code: "LT", code3: "LTU", name: "Lithuania", number: "440" },
  { code: "LU", code3: "LUX", name: "Luxembourg", number: "442" },
  { code: "MO", code3: "MAC", name: "Macao", number: "446" },
  { code: "MG", code3: "MDG", name: "Madagascar", number: "450" },
  { code: "MW", code3: "MWI", name: "Malawi", number: "454" },
  { code: "MY", code3: "MYS", name: "Malaysia", number: "458" },
  { code: "MV", code3: "MDV", name: "Maldives", number: "462" },
  { code: "ML", code3: "MLI", name: "Mali", number: "466" },
  { code: "MT", code3: "MLT", name: "Malta", number: "470" },
  { code: "MH", code3: "MHL", name: "Marshall Islands", number: "584" },
  { code: "MQ", code3: "MTQ", name: "Martinique", number: "474" },
  { code: "MR", code3: "MRT", name: "Mauritania", number: "478" },
  { code: "MU", code3: "MUS", name: "Mauritius", number: "480" },
  { code: "YT", code3: "MYT", name: "Mayotte", number: "175" },
  { code: "MX", code3: "MEX", name: "Mexico", number: "484" },
  { code: "FM", code3: "FSM", name: "Micronesia, Federated States of", number: "583" },
  { code: "MD", code3: "MDA", name: "Moldova, Republic of", number: "498" },
  { code: "MC", code3: "MCO", name: "Monaco", number: "492" },
  { code: "MN", code3: "MNG", name: "Mongolia", number: "496" },
  { code: "ME", code3: "MNE", name: "Montenegro", number: "499" },
  { code: "MS", code3: "MSR", name: "Montserrat", number: "500" },
  { code: "MA", code3: "MAR", name: "Morocco", number: "504" },
  { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508" },
  { code: "MM", code3: "MMR", name: "Myanmar", number: "104" },
  { code: "NA", code3: "NAM", name: "Namibia", number: "516" },
  { code: "NR", code3: "NRU", name: "Nauru", number: "520" },
  { code: "NP", code3: "NPL", name: "Nepal", number: "524" },
  { code: "NL", code3: "NLD", name: "Netherlands", number: "528" },
  { code: "NC", code3: "NCL", name: "New Caledonia", number: "540" },
  { code: "NZ", code3: "NZL", name: "New Zealand", number: "554" },
  { code: "NI", code3: "NIC", name: "Nicaragua", number: "558" },
  { code: "NE", code3: "NER", name: "Niger", number: "562" },
  { code: "NG", code3: "NGA", name: "Nigeria", number: "566" },
  { code: "NU", code3: "NIU", name: "Niue", number: "570" },
  { code: "NF", code3: "NFK", name: "Norfolk Island", number: "574" },
  { code: "MP", code3: "MNP", name: "Northern Mariana Islands", number: "580" },
  { code: "NO", code3: "NOR", name: "Norway", number: "578" },
  { code: "OM", code3: "OMN", name: "Oman", number: "512" },
  { code: "PK", code3: "PAK", name: "Pakistan", number: "586" },
  { code: "PW", code3: "PLW", name: "Palau", number: "585" },
  { code: "PS", code3: "PSE", name: "Palestinian Territory, Occupied", number: "275" },
  { code: "PA", code3: "PAN", name: "Panama", number: "591" },
  { code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598" },
  { code: "PY", code3: "PRY", name: "Paraguay", number: "600" },
  { code: "PE", code3: "PER", name: "Peru", number: "604" },
  { code: "PH", code3: "PHL", name: "Philippines", number: "608" },
  { code: "PN", code3: "PCN", name: "Pitcairn", number: "612" },
  { code: "PL", code3: "POL", name: "Poland", number: "616" },
  { code: "PT", code3: "PRT", name: "Portugal", number: "620" },
  { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630" },
  { code: "QA", code3: "QAT", name: "Qatar", number: "634" },
  { code: "MK", code3: "MKD", name: "Macedonia, The Former Yugoslav Republic of", number: "807" },
  { code: "RO", code3: "ROU", name: "Romania", number: "642" },
  { code: "RU", code3: "RUS", name: "Russian Federation", number: "643" },
  { code: "RW", code3: "RWA", name: "Rwanda", number: "646" },
  { code: "RE", code3: "REU", name: "Reunion", number: "638" },
  { code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652" },
  { code: "SH", code3: "SHN", name: "Saint Helena", number: "654" },
  { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659" },
  { code: "LC", code3: "LCA", name: "Saint Lucia", number: "662" },
  { code: "MF", code3: "MAF", name: "Saint Martin (French part)", number: "663" },
  { code: "PM", code3: "SPM", name: "Saint Pierre and Miquelon", number: "666" },
  { code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", number: "670" },
  { code: "WS", code3: "WSM", name: "Samoa", number: "882" },
  { code: "SM", code3: "SMR", name: "San Marino", number: "674" },
  { code: "ST", code3: "STP", name: "Sao Tome and Principe", number: "678" },
  { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682" },
  { code: "SN", code3: "SEN", name: "Senegal", number: "686" },
  { code: "RS", code3: "SRB", name: "Serbia", number: "688" },
  { code: "SC", code3: "SYC", name: "Seychelles", number: "690" },
  { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694" },
  { code: "SG", code3: "SGP", name: "Singapore", number: "702" },
  { code: "SX", code3: "SXM", name: "Sint Maarten (Dutch part)", number: "534" },
  { code: "SK", code3: "SVK", name: "Slovakia", number: "703" },
  { code: "SI", code3: "SVN", name: "Slovenia", number: "705" },
  { code: "SB", code3: "SLB", name: "Solomon Islands", number: "090" },
  { code: "SO", code3: "SOM", name: "Somalia", number: "706" },
  { code: "ZA", code3: "ZAF", name: "South Africa", number: "710" },
  { code: "GS", code3: "SGS", name: "South Georgia and the South Sandwich Islands", number: "239" },
  { code: "SS", code3: "SSD", name: "South Sudan", number: "728" },
  { code: "ES", code3: "ESP", name: "Spain", number: "724" },
  { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144" },
  { code: "SD", code3: "SDN", name: "Sudan", number: "729" },
  { code: "SR", code3: "SUR", name: "Suriname", number: "740" },
  { code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", number: "744" },
  { code: "SE", code3: "SWE", name: "Sweden", number: "752" },
  { code: "CH", code3: "CHE", name: "Switzerland", number: "756" },
  { code: "SY", code3: "SYR", name: "Syrian Arab Republic", number: "760" },
  { code: "TW", code3: "TWN", name: "Taiwan", number: "158" },
  { code: "TJ", code3: "TJK", name: "Tajikistan", number: "762" },
  { code: "TZ", code3: "TZA", name: "Tanzania, United Republic of", number: "834" },
  { code: "TH", code3: "THA", name: "Thailand", number: "764" },
  { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626" },
  { code: "TG", code3: "TGO", name: "Togo", number: "768" },
  { code: "TK", code3: "TKL", name: "Tokelau", number: "772" },
  { code: "TO", code3: "TON", name: "Tonga", number: "776" },
  { code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780" },
  { code: "TN", code3: "TUN", name: "Tunisia", number: "788" },
  { code: "TR", code3: "TUR", name: "Turkey", number: "792" },
  { code: "TM", code3: "TKM", name: "Turkmenistan", number: "795" },
  { code: "TC", code3: "TCA", name: "Turks and Caicos Islands", number: "796" },
  { code: "TV", code3: "TUV", name: "Tuvalu", number: "798" },
  { code: "UG", code3: "UGA", name: "Uganda", number: "800" },
  { code: "UA", code3: "UKR", name: "Ukraine", number: "804" },
  { code: "AE", code3: "ARE", name: "United Arab Emirates", number: "784" },
  { code: "GB", code3: "GBR", name: "United Kingdom", number: "826" },
  { code: "UM", code3: "UMI", name: "United States Minor Outlying Islands", number: "581" },
  { code: "US", code3: "USA", name: "United States", number: "840" },
  { code: "UY", code3: "URY", name: "Uruguay", number: "858" },
  { code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860" },
  { code: "VU", code3: "VUT", name: "Vanuatu", number: "548" },
  { code: "VE", code3: "VEN", name: "Venezuela", number: "862" },
  { code: "VN", code3: "VNM", name: "Vietnam", number: "704" },
  { code: "VG", code3: "VGB", name: "Virgin Islands, British", number: "092" },
  { code: "VI", code3: "VIR", name: "Virgin Islands, U.S.", number: "850" },
  { code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876" },
  { code: "EH", code3: "ESH", name: "Western Sahara", number: "732" },
  { code: "YE", code3: "YEM", name: "Yemen", number: "887" },
  { code: "ZM", code3: "ZMB", name: "Zambia", number: "894" },
  { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716" },
  { code: "AX", code3: "ALA", name: "Åland Islands", number: "248" },
];

export const newRegisterObj = {
  companyID: "",
  firstName: "",
  lastName: "",
  dob: null,
  idNumber: "",
  street: "",
  city: "",
  state: "",
  phoneNumber: "",
  sex: "",
  email: "",
  isVaccinated: null,
  country: "United States",
  street2: "",
  countryCode: "",
  zip: "",
  programName: "",
  programID: "",
};
